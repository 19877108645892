import React from 'react';

interface Props {
  cardType: string;
  setCardType: (cardType: string) => void;
}

export default function CardTypeFilter(props: Props) {
  return (
    <div>
      <label className="text-sm">Type de la carte</label>
      <select
        name="cardType"
        className="w-full border"
        value={props.cardType}
        onChange={(e) => {
          props.setCardType(e.target.value);
        }}
      >
        <option value="">---</option>
        <option value="UFE">UFE</option>
        <option value="Ami de l'UFE">Amis de l’UFE</option>
        <option value="UFE Avenir">UFE Avenir</option>
        <option value="Carte d'Honneur">Carte d’honneur</option>
      </select>
    </div>
  );
}
