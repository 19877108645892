import Modal from '../../../components/modals/Modal';
import useModal from '../../../components/modals/useModal';
import MembershipPutForm from '../forms/MembershipPutForm';
import { Membership } from '../types/Membership';

interface Props {
  membership: Membership;
  closeParent: () => void;
}

export default function MembershipPutButton(props: Props) {
  const { isShowing, toggle } = useModal();

  return (
    <>
      <button onClick={toggle} className="bg-white border px-2 py-1">
        Modifier
      </button>
      <Modal
        isShowing={isShowing}
        hide={toggle}
        title={'Modifier une adhésion'}
      >
        <MembershipPutForm
          hide={toggle}
          membership={props.membership}
          closeParent={props.closeParent}
        />
      </Modal>
    </>
  );
}
