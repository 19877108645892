import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { Subsidiary } from '../types/Subsidiary';

type Props = {
  setChosenSubsidiary: React.Dispatch<React.SetStateAction<Subsidiary | null>>;
};

const SubsidiarySelectHome = (props: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const [subsidiaries, setSubsidiaries] = useState<Subsidiary[]>([]);

  useEffect(() => {
    axiosPrivate.get('/subsidiaries?pagination=false').then(
      (response) => {
        setSubsidiaries(response?.data['hydra:member']);
      },
      (error) => {
        console.log(error);
      },
    );
  }, [axiosPrivate]);

  const handleSubsidiarySelect = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    if (event.target.value === 'all') {
      props.setChosenSubsidiary(null);
    } else {
      subsidiaries
        .filter((subsidiary) => subsidiary['@id'] === event.target.value)
        .map((subsidiary) => props.setChosenSubsidiary(subsidiary));
    }
  };

  return (
    <select
      className="w-fit h-10 border border-gray-300 rounded-sm"
      onChange={handleSubsidiarySelect}
      defaultValue={'all'}
    >
      <option value="all">TOUTES LES UFE</option>
      {subsidiaries &&
        subsidiaries.map((element, index: number) => {
          return (
            <option key={index} value={element['@id']}>
              {element.name}
            </option>
          );
        })}
    </select>
  );
};

export default SubsidiarySelectHome;
