import React from 'react';
import { Adherent } from '../types/Adherent';
import useModal from '../../../components/modals/useModal';
import Modal from '../../../components/modals/Modal';
import { toast, ToastContentProps } from 'react-toastify';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { AxiosError } from 'axios';
import { ErrorResponse } from '../../../types/ErrorResponse';

interface Props {
  adherent: Adherent;
  closeParent: () => void;
}

export default function ResendCertificateButton(props: Props) {
  const { isShowing, toggle } = useModal();
  const axiosPrivate = useAxiosPrivate();
  return (
    <>
      <button onClick={toggle} className="bg-white border px-2 py-1">
        Envoyer la dernière attestation valide
      </button>
      <Modal
        title={"Envoi de l'attestation"}
        isShowing={isShowing}
        hide={toggle}
      >
        <div className="flex flex-col justify-center">
          <p className="text-center my-2">
            Vous allez envoyer une attestation pour {props.adherent.firstname}{' '}
            {props.adherent.lastname} à l'adresse : {props.adherent.email}
          </p>
          <button
            className="bg-green-ufe w-60 py-1 text-white mx-auto"
            onClick={() =>
              toast
                .promise(
                  axiosPrivate.get(
                    '/send/adherents/certificate?adherentId=' +
                      props.adherent.id,
                  ),
                  {
                    pending: 'Envoi en cours...',
                    success: {
                      render(response: ToastContentProps<any>) {
                        return response.data.data.message
                          ? response.data.data.message
                          : 'Envoyé avec succès.';
                      },
                    },
                    error: {
                      render(data: ToastContentProps<AxiosError>) {
                        const value = data.data?.response
                          ?.data as ErrorResponse;
                        return value.message
                          ? value.message
                          : 'Une erreur est survenue.';
                      },
                    },
                  },
                  { position: toast.POSITION.BOTTOM_CENTER },
                )
                .then(
                  () => {
                    props.closeParent();
                  },
                  (error) => {
                    console.error(error);
                  },
                )
            }
          >
            Envoyer
          </button>
        </div>
      </Modal>
    </>
  );
}
