import React, { useEffect, useState } from 'react';
import DeadlineSiegeForm from '../../Subsidiary/forms/DeadlineSiegeForm';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';
import { DeadlineData } from '../types/DeadlineData';

interface Props {
  chosenSubsidiary: Subsidiary | null;
  onStayAmountUpdateSiege: (newStayAmountSiege: number) => void;
}

export default function SiegeFinanciaryStatusChart(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState<DeadlineData>();

  const handleStayAmountUpdateSiege = (newStayAmountSiege: number) => {
    props.onStayAmountUpdateSiege(newStayAmountSiege);
  };

  useEffect(() => {
    axiosPrivate
      .get(
        '/financiary/currentDeadlines' +
          (props.chosenSubsidiary && props.chosenSubsidiary['@id']
            ? '?subsidiary=' + props.chosenSubsidiary['@id']
            : ''),
      )
      .then(
        (response) => {
          setData(response?.data);
        },
        (error) => {
          console.log(error);
        },
      );
  }, [axiosPrivate, props.chosenSubsidiary]);

  return (
    <div className="p-6 bg-white w-full">
      <h3>Statut financier {new Date().getFullYear()} du siège</h3>
      <div className="flex flex-col">
        {data && (
          <DeadlineSiegeForm
            data={data}
            setData={setData}
            chosenSubsidiary={props.chosenSubsidiary}
            onStayAmountUpdateSiege={handleStayAmountUpdateSiege}
          />
        )}
      </div>
    </div>
  );
}
