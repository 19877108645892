import React, { createContext, useCallback } from 'react';
import { Employee } from '../types/Employee';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { useSubsidiary } from '../../Subsidiary/contexts/SubsidiaryContext';

type Action =
  | { type: 'loaded'; employees: Employee[] }
  | { type: 'added'; employee: Employee }
  | { type: 'changed'; employee: Employee }
  | { type: 'deleted'; employee: Employee };

type Dispatch = (action: Action) => void;

type EmployeesStateProps = {
  employees: Employee[];
};

type EmployeesDisptachContentProps = {
  dispatch: Dispatch;
};

const EmployeesStateContext = createContext<EmployeesStateProps | undefined>(
  undefined,
);

const EmployeesDisptachContext = createContext<
  EmployeesDisptachContentProps | undefined
>(undefined);

const employeesReducer = (employees: Employee[], action: Action) => {
  switch (action.type) {
    case 'loaded':
      return action.employees;
    case 'added':
      return [...employees, action.employee];
    case 'changed':
      return employees.map((employee) => {
        if (employee['@id'] === action.employee['@id']) {
          return action.employee;
        }
        return employee;
      });
    case 'deleted':
      return employees.filter(
        (employee) => employee['@id'] !== action.employee['@id'],
      );
    default:
      throw new Error(`Unhandled action type`);
  }
};

interface Props {
  children: React.ReactNode;
}

export default function EmployeeCollectionContext(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const [employees, dispatch] = React.useReducer(employeesReducer, []);
  const subsidiary = useSubsidiary();

  const fetchEmployees = useCallback(async () => {
    if (subsidiary['@id']) {
      try {
        const response = await axiosPrivate.get(
          `/employees?subsidiary=${subsidiary['@id']}`,
        );
        dispatch({ type: 'loaded', employees: response.data['hydra:member'] });
      } catch (error) {
        console.error(error);
      }
    }
  }, [subsidiary]);

  React.useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  return (
    <EmployeesStateContext.Provider value={{ employees }}>
      <EmployeesDisptachContext.Provider value={{ dispatch }}>
        {props.children}
      </EmployeesDisptachContext.Provider>
    </EmployeesStateContext.Provider>
  );
}

export function useEmployees() {
  const context = React.useContext(EmployeesStateContext);
  if (context === undefined) {
    throw new Error('useEmployees must be used within a EmployeesProvider');
  }
  return context.employees;
}

export function useEmployeesDispatch() {
  const context = React.useContext(EmployeesDisptachContext);
  if (context === undefined) {
    throw new Error(
      'useEmployeesDispatch must be used within a EmployeesProvider',
    );
  }
  return context.dispatch;
}
