import { SyntheticEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import chevron from '../../assets/images/chevron-left.svg';
import emailImg from '../../assets/images/email.svg';
import phoneImg from '../../assets/images/phone.svg';
import SubsidiaryAddressPutForm from '../../modules/SubsidiaryAddress/forms/SubsidiaryAddressPutForm';
import SubsidiaryPutButton from '../../modules/Subsidiary/buttons/SubsidiaryPutButton';
import AdherentCollectionContainer from '../../modules/Adherent/containers/AdherentCollectionContainer';
import AdherentCollectionContext from '../../modules/Adherent/contexts/AdherentCollectionContext';
import CurrentPriceContainer from '../../modules/Price/containers/CurrentPriceContainer';
import EmployeeCollectionContainer from '../../modules/Employee/containers/EmployeeCollectionContainer';
import EmployeeCollectionContext from '../../modules/Employee/contexts/EmployeeCollectionContext';
import SubsidiaryNoteCollectionContainer from '../../modules/SubsidiaryNote/containers/SubsidiaryNoteCollectionContainer';
import SubsidiaryNoteCollectionContext from '../../modules/SubsidiaryNote/contexts/SubsidiaryNoteCollectionContext';
import { useSubsidiary } from '../../modules/Subsidiary/contexts/SubsidiaryContext';
import { useAuth } from '../../modules/Auth/contexts/AuthContextProvider';
import PriceContainer from '../../modules/Price/containers/PriceContainer';

export default function SubsidiaryPage() {
  const subsidiary = useSubsidiary();
  const [tab, setTab] = useState(1);
  const { auth } = useAuth();
  const role = auth?.payload?.roles[0];

  const handleTabSelect = (e: SyntheticEvent): void => {
    setTab(parseInt((e.target as HTMLInputElement).value));
  };

  return (
    <div className="p-5">
      <div className="w-full">
        <div className="h-20 flex items-center justify-between bg-white px-5">
          <div className="flex gap-x-5 items-center">
            <Link
              to={'/representations'}
              className="w-8 h-8 bg-green-ufeLight flex justify-center items-center pointer"
            >
              <img src={chevron} alt="back" />
            </Link>
            <h2 className="font-bold">{subsidiary?.name}</h2>
          </div>
          <div className="flex items-center gap-x-5">
            <div className="flex gap-x-2">
              <img src={emailImg} alt="email" className="w-5" />
              <h4>{subsidiary?.email}</h4>
            </div>
            <div className="flex gap-x-2">
              <img src={phoneImg} alt="phone" className="w-5" />
              <h4>{subsidiary?.phone}</h4>
            </div>
            <SubsidiaryPutButton />
          </div>
        </div>

        <div className="my-5">
          <button
            value="1"
            onClick={handleTabSelect}
            className={
              tab === 1 ? 'bg-green-ufe p-4 text-white' : 'bg-white p-4'
            }
          >
            GENERAL
          </button>

          <button
            value="2"
            onClick={handleTabSelect}
            className={
              tab === 2
                ? 'bg-green-ufe p-4 ml-5 text-white'
                : 'bg-white p-4 ml-5'
            }
          >
            ADHÉRENTS
          </button>
          <button
            value="3"
            onClick={handleTabSelect}
            className={
              tab === 3
                ? 'bg-green-ufe p-4 ml-5 text-white'
                : 'bg-white p-4 ml-5'
            }
          >
            BARÈMES
          </button>
          {role !== 'ROLE_SUBSIDIARY' && (
            <>
              <button
                value="4"
                onClick={handleTabSelect}
                className={
                  tab === 4
                    ? 'bg-green-ufe p-4 ml-5 text-white'
                    : 'bg-white p-4 ml-5'
                }
              >
                NOTES
              </button>
            </>
          )}
        </div>

        {tab === 1 && (
          <div className="flex space-x-5">
            <div className="bg-white p-5 h-min w-1/3">
              <h3 className="font-bold mb-5">Adresse</h3>
              {subsidiary.address && (
                <SubsidiaryAddressPutForm subsidiary={subsidiary} />
              )}
            </div>
            <EmployeeCollectionContext>
              <EmployeeCollectionContainer />
            </EmployeeCollectionContext>
            <CurrentPriceContainer subsidiary={subsidiary} />
          </div>
        )}
        {tab === 2 && subsidiary && (
          <AdherentCollectionContext subsidiary={subsidiary}>
            <AdherentCollectionContainer />
          </AdherentCollectionContext>
        )}
        {tab === 3 && subsidiary && <PriceContainer subsidiary={subsidiary} />}
        {tab === 4 && subsidiary && (
          <SubsidiaryNoteCollectionContext>
            <SubsidiaryNoteCollectionContainer />
          </SubsidiaryNoteCollectionContext>
        )}
      </div>
    </div>
  );
}
