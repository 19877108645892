import React from 'react';
import useModal from '../../../components/modals/useModal';
import Modal from '../../../components/modals/Modal';
import { User } from '../types/User';
import UserPutForm from '../forms/UserPutForm';

interface Props {
  user: User;
  closeParentModal: () => void;
}

export default function UserPutButton(props: Props) {
  const { isShowing, toggle } = useModal();

  return (
    <>
      <button onClick={toggle} className="bg-white border px-2 py-1 mt-5">
        Modifier
      </button>
      <Modal
        title={"Modifier l'utilisateur"}
        isShowing={isShowing}
        hide={toggle}
      >
        <UserPutForm user={props.user} closeParent={props.closeParentModal} />
      </Modal>
    </>
  );
}
