import { useState } from 'react';
import AdherentChartsContainer from '../../modules/Chart/containers/AdherentChartsContainer';
import { Subsidiary } from '../../modules/Subsidiary/types/Subsidiary';
import SubsidiarySelectHome from '../../modules/Subsidiary/selects/SubsidiarySelectHome';
import { useAuth } from '../../modules/Auth/contexts/AuthContextProvider';
import { useUser } from '../../modules/User/contexts/UserContextProvider';
import NormalMembershipChart from '../../modules/Chart/financiary/NormalMembershipChart';
import JuniorMembershipChart from '../../modules/Chart/financiary/JuniorMembershipChart';
import SeniorMembershipChart from '../../modules/Chart/financiary/SeniorMembershipChart';
import CustomMembershipChart from '../../modules/Chart/financiary/CustomMembershipChart';
import SubsidiaryAmountByOriginChart from '../../modules/Chart/financiary/SubsidiaryAmountByOriginChart';
import SiegeAmountByOriginChart from '../../modules/Chart/financiary/SiegeAmountByOriginChart';
import ContributionCountChart from '../../modules/Chart/financiary/ContributionCountChart';
import CurrentFinancialBalanceChart from '../../modules/Chart/financiary/CurrentFinancialBalanceChart';
import SubsidiaryFinanciaryStatusChart from '../../modules/Chart/financiary/SubsidiaryFinanciaryStatusChart';
import SiegeFinanciaryStatusChart from '../../modules/Chart/financiary/SiegeFinanciaryStatusChart';

export default function HomePage() {
  const { auth } = useAuth();
  const role = auth?.payload.roles[0];
  const user = useUser();
  const [tab, setTab] = useState(1);
  const [chosenSubsidiary, setChosenSubsidiary] = useState<Subsidiary | null>(
    role === 'ROLE_SUBSIDIARY' && user?.subsidiary ? user.subsidiary : null,
  );
  const [parentParentStayAmount, setParentParentStayAmount] = useState<
    number | undefined
  >(undefined);
  const [parentParentStayAmountSiege, setParentParentStayAmountSiege] =
    useState<number | undefined>(undefined);

  const handleParentParentStayAmountUpdate = (newStayAmount: number) => {
    setParentParentStayAmount(newStayAmount);
  };
  const handleParentParentStayAmountUpdateSiege = (
    newStayAmountSiege: number,
  ) => {
    setParentParentStayAmountSiege(newStayAmountSiege);
  };

  return (
    <div className="m-5">
      <div className="bg-white p-5 flex justify-between items-center">
        <div className="flex items-center">
          {role !== 'ROLE_SUBSIDIARY' && (
            <SubsidiarySelectHome setChosenSubsidiary={setChosenSubsidiary} />
          )}
          {role === 'ROLE_SUBSIDIARY' && (
            <h1 className="font-bold">{user?.subsidiary?.name}</h1>
          )}
        </div>
        <div className="flex">
          <button
            onClick={() => setTab(1)}
            className={tab === 1 ? 'bg-green-ufeBack py-2 px-3' : ''}
          >
            ADHÉRENTS
          </button>
          <button
            onClick={() => setTab(2)}
            className={tab === 2 ? 'bg-green-ufeBack py-2 px-3 ml-4' : 'ml-4'}
          >
            FINANCIER
          </button>
        </div>
      </div>
      {tab === 1 && role && (
        <AdherentChartsContainer chosenSubsidiary={chosenSubsidiary} />
      )}
      {tab === 2 && (
        <div className="flex flex-col space-y-5">
          <div className="flex space-x-5 justify-between mt-5">
            <NormalMembershipChart chosenSubsidiary={chosenSubsidiary} />
            <JuniorMembershipChart chosenSubsidiary={chosenSubsidiary} />
            <SeniorMembershipChart chosenSubsidiary={chosenSubsidiary} />
            <CustomMembershipChart chosenSubsidiary={chosenSubsidiary} />
          </div>
          <div className="flex space-x-5">
            <ContributionCountChart chosenSubsidiary={chosenSubsidiary} />
            <CurrentFinancialBalanceChart
              chosenSubsidiary={chosenSubsidiary}
              parentParentStayAmount={parentParentStayAmount}
              parentParentStayAmountSiege={parentParentStayAmountSiege}
            />
          </div>
          <div className="flex space-x-5">
            <SubsidiaryAmountByOriginChart
              chosenSubsidiary={chosenSubsidiary}
            />
            <SiegeAmountByOriginChart chosenSubsidiary={chosenSubsidiary} />
          </div>
          <div className="flex space-x-5">
            <SubsidiaryFinanciaryStatusChart
              chosenSubsidiary={chosenSubsidiary}
              onStayAmountUpdate={handleParentParentStayAmountUpdate}
            />
            <SiegeFinanciaryStatusChart
              chosenSubsidiary={chosenSubsidiary}
              onStayAmountUpdateSiege={handleParentParentStayAmountUpdateSiege}
            />
          </div>
        </div>
      )}
    </div>
  );
}
