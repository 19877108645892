import {
  useAdherents,
  useAdherentsIsLoading,
  useAdherentsLinks,
  useAdherentsOrderId,
  useAdherentsOrderLastName,
  useAdherentsSelectedItems,
  useAdherentsSetItemsPerPage,
  useAdherentsSetOrderId,
  useAdherentsSetOrderLastName,
  useAdherentsSetSelectedItems,
  useAdherentsTotalItems,
  useFetchAdherents,
} from '../../modules/Adherent/contexts/AdherentCollectionContext';
import Loader from '../../components/Loader';
import multipleOptions from '../../assets/images/multipleOptions.svg';
import { Adherent } from '../../modules/Adherent/types/Adherent';
import AdherentItem from '../../modules/Adherent/items/AdherentItem';
import Pagination from '../../components/Pagination';
import RowSelector from '../../components/RowSelector';
import AdherentPostButton from '../../modules/Adherent/buttons/AdherentPostButton';
import AdherentExportButton from '../../modules/Adherent/buttons/AdherentExportButton';
import AdherentImportButton from '../../modules/Adherent/buttons/AdherentImportButton';
import React from 'react';
import AdherentFilterForm from '../../modules/Adherent/forms/AdherentFilterForm';
import { useAuth } from '../../modules/Auth/contexts/AuthContextProvider';
import OrderFilter from '../../components/filters/OrderFilter';

export default function AdherentCollectionPage() {
  const adherents = useAdherents();
  const isLoading = useAdherentsIsLoading();
  const links = useAdherentsLinks();
  const fetchAdherents = useFetchAdherents();
  const totalItems = useAdherentsTotalItems();
  const setItemsPerPage = useAdherentsSetItemsPerPage();
  const selectedItems = useAdherentsSelectedItems();
  const setSelectedItems = useAdherentsSetSelectedItems();
  const { auth } = useAuth();
  const role = auth?.payload.roles[0];

  const orderId = useAdherentsOrderId();
  const setOrderId = useAdherentsSetOrderId();
  const orderLastname = useAdherentsOrderLastName();
  const setOrderLastName = useAdherentsSetOrderLastName();

  const selectAll = () => {
    if (selectedItems.length === adherents.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(adherents);
    }
  };

  return (
    <div className="flex gap-x-5 p-5">
      <div className="w-1/6 h-min p-5 bg-white flex flex-col gap-y-5">
        <AdherentFilterForm />
      </div>
      <div className="bg-white p-5 flex flex-col h-min w-5/6">
        <div className="flex justify-between mb-5">
          <div className="flex gap-x-1">
            <h3 className="font-bold">Adhérents</h3>
            {isLoading ? <Loader /> : null}
          </div>
          <div className="flex gap-x-4">
            <AdherentPostButton />
            <AdherentExportButton />
            <AdherentImportButton />
          </div>
        </div>
        <table className="border text-xs lg:text-base">
          <thead>
            <tr className="bg-green-ufeLight">
              <td className="py-2">
                <button
                  onClick={selectAll}
                  className={
                    selectedItems.length === adherents.length &&
                    adherents.length !== 0
                      ? 'ml-2 w-4 h-4 bg-green-ufe border pointer'
                      : 'ml-2 w-4 h-4 bg-white border pointer'
                  }
                />
              </td>
              <td className="p-2 text-left" colSpan={1}>
                <div className="flex items-center space-x-1">
                  <p>N°</p>
                  <OrderFilter order={orderId} setOrder={setOrderId} />
                </div>
              </td>
              <td className="p-2 text-left" colSpan={1}>
                <div className="flex items-center space-x-1">
                  <p>Nom</p>
                  <OrderFilter
                    order={orderLastname}
                    setOrder={setOrderLastName}
                  />
                </div>
              </td>
              <td className="p-2 text-left">Prénom</td>
              <td className="p-2 text-left">Email</td>
              <td className="p-2 text-left">Type de barème</td>
              <td className="p-2 text-left">Origine cotisation</td>
              {role !== 'ROLE_SUBSIDIARY' && (
                <td className="p-2 text-left">Représentation</td>
              )}
              <td className="p-2 text-left">Status</td>
              {role !== 'ROLE_SUBSIDIARY' && (
                <td className="p-2 text-left">
                  <img alt="option-icon" src={multipleOptions} />
                </td>
              )}
            </tr>
          </thead>
          <tbody>
            {adherents &&
              adherents.map((adherent: Adherent, index: number) => {
                return <AdherentItem key={index} adherent={adherent} />;
              })}
          </tbody>
        </table>
        <div className="mt-5 flex justify-between">
          <Pagination links={links} fetchData={fetchAdherents} />
          <RowSelector
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          />
        </div>
      </div>
    </div>
  );
}
