import { Subsidiary } from '../types/Subsidiary';
import SubsidiaryActionButton from '../buttons/SubsidiaryActionButton';
import {
  useSubsidiariesSelectedItems,
  useSubsidiariesSetSelectedItems,
} from '../contexts/SubsidiaryCollectionContext';
import { useNavigate } from 'react-router-dom';

interface Props {
  subsidiary: Subsidiary;
  subsidiaryTotalAdherents: number;
}

export default function SubsidiaryItem(props: Props) {
  const selectedItems = useSubsidiariesSelectedItems();
  const setSelectedItems = useSubsidiariesSetSelectedItems();
  const navigate = useNavigate();
  const id = props.subsidiary['@id'].split('/')[2];

  const openSubsidiary = () => {
    navigate('/representation/' + id);
  };

  const manageSelect = () => {
    if (!selectedItems.includes(props.subsidiary)) {
      setSelectedItems([...selectedItems, props.subsidiary]);
    } else {
      setSelectedItems(
        selectedItems.filter((item) => item['@id'] !== props.subsidiary['@id']),
      );
    }
  };

  return (
    <tr className="cursor-pointer hover:bg-gray-100">
      <td className="py-2">
        <button
          onClick={manageSelect}
          className={
            selectedItems.includes(props.subsidiary)
              ? 'ml-2 w-4 h-4 bg-green-ufe border pointer'
              : 'ml-2 w-4 h-4 bg-white border pointer'
          }
        />
      </td>
      <td className="p-2" onClick={openSubsidiary}>
        {props.subsidiary?.name}
      </td>
      <td className="p-2" onClick={openSubsidiary}>
        {props.subsidiary?.email}
      </td>
      <td className="p-2" onClick={openSubsidiary}>
        {props.subsidiary?.phone}
      </td>
      <td className="p-2" onClick={openSubsidiary}>
        {props.subsidiary.employees
          .filter((employee) => employee.title === 'Président')
          .map((employee) => (
            <div key={employee['@id']}>
              {employee.adherent.lastname + ' ' + employee.adherent.firstname}
            </div>
          ))}
      </td>
      <td className="p-2" onClick={openSubsidiary}>
        {props.subsidiary.employees
          .filter((employee) => employee.title === 'Président')
          .map((employee) => (
            <div key={employee['@id']}>{employee.adherent.email}</div>
          ))}
      </td>
      <td className="p-2" onClick={openSubsidiary}>
        {props.subsidiaryTotalAdherents}
      </td>
      <td className="p-2">
        <SubsidiaryActionButton subsidiary={props.subsidiary} />
      </td>
    </tr>
  );
}
