import { Field, Form, Formik, FormikHelpers } from 'formik';
import { toast, ToastContentProps } from 'react-toastify';
import axios from '../../Axios/axios';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { ErrorResponse } from '../../../types/ErrorResponse';
import { useAuth } from '../contexts/AuthContextProvider';

interface Values {
  email: string;
  password: string;
}

const ValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Champ requis'),
  password: Yup.string().min(6, 'Too short.').required('Champ requis'),
});

export default function LoginForm() {
  const { setAuth } = useAuth();

  const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    return JSON.parse(jsonPayload);
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={ValidationSchema}
      onSubmit={async (
        values: Values,
        { setSubmitting }: FormikHelpers<Values>,
      ) => {
        toast
          .promise(
            axios.post('/auth', JSON.stringify(values, null, 2)),
            {
              pending: 'Chargement...',
              success: 'Succès.',
              error: {
                render(data: ToastContentProps<AxiosError>) {
                  const value = data.data?.response?.data as ErrorResponse;
                  return value.message
                    ? value.message
                    : 'Une erreur est survenue.';
                },
              },
            },
            {
              position: toast.POSITION.BOTTOM_CENTER,
            },
          )
          .then(
            (response) => {
              const token = response?.data.token;
              const payload = parseJwt(token);

              setAuth({ token, payload });

              // Set logout to expiresAt
              const expiresAtTimestamp = payload.exp;
              const timeToDisconnect = expiresAtTimestamp * 1000;
              const currentTime = new Date().getTime();
              const subtractMilliSecondsValue = timeToDisconnect - currentTime;
              setTimeout(() => {
                setAuth(null);
              }, subtractMilliSecondsValue);
            },
            (error) => {
              console.log(error);
            },
          )
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched }) => (
        <Form className="flex flex-col w-60">
          <Field
            id="email"
            name="email"
            placeholder="Email"
            type="email"
            className="py-1 pl-2 mb-6 border"
          />
          {errors.email && touched.email ? (
            <div className="text-red-600 mb-6">{errors.email}</div>
          ) : null}
          <Field
            id="password"
            name="password"
            placeholder="Mot de passe"
            type="password"
            className="py-1 pl-2 mb-6 border"
          />
          {errors.password && touched.password ? (
            <div className="text-red-600 mb-6">{errors.password}</div>
          ) : null}
          <button type="submit" className="w-full bg-green-ufe text-white py-1">
            Connexion
          </button>
        </Form>
      )}
    </Formik>
  );
}
