import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from '../../components/NavBar';

export default function PrivateLayout() {
  return (
    <div className="min-h-screen bg-green-ufeLight">
      <NavBar />
      <Outlet />
      <ToastContainer />
    </div>
  );
}
