import { toast } from 'react-toastify';
import { Adherent } from '../types/Adherent';
import { utils, writeFile } from 'xlsx';
import { useAdherentsSelectedItems } from '../contexts/AdherentCollectionContext';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';

interface Props {
  hide: () => void;
}

export default function AdherentExportSelectionButton(props: Props) {
  const adherents = useAdherentsSelectedItems();
  const { auth } = useAuth();
  const role = auth?.payload?.roles[0];

  const exportSelectedAdherents = () => {
    if (adherents.length !== 0) {
      exportXLSX(adherents);
    } else {
      toast.warning('Vous devez sélectionner au moins un adhérent.', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    props.hide();
  };

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${day}-${month}-${year}`;
  }

  const exportXLSX = (adherents: Adherent[]) => {
    const exportFileData: (string | number | undefined)[][] = [];

    const headerXLSX = [
      'Numéro',
      'Prénom',
      'Nom',
      'Téléphone',
      'Email',
      'Représentation',
      'Date de création',
      "Numéro d'adresse",
      'Rue',
      "Complément d'adresse",
      'Ville',
      'État',
      'Code postal',
      'Pays',
      'Début du dernier abonnement',
      'Montant locale',
      'Montant Siège',
      'Montant total',
      'Origine cotisation',
      'Type de prix',
      'Type de carte',
      'Statut de la carte',
    ];
    if (role !== 'ROLE_SUBSIDIARY') {
      headerXLSX.push("Date de fin d'abonnement");
    } else {
      headerXLSX.push('Statut');
    }
    exportFileData.unshift(headerXLSX);

    adherents.forEach((adherent) => {
      const createdAt = new Date(adherent.createdAt);
      const beginAt =
        adherent.lastMembership && adherent.lastMembership.beginAt
          ? new Date(adherent.lastMembership.beginAt)
          : '';
      // const endAt =
      //   adherent.lastMembership && adherent.lastMembership.endAt
      //     ? new Date(adherent.lastMembership?.endAt)
      //     : '';

      // TODO: Trouver un meilleur système.
      let endDateToString = adherent.lastMembership?.endAt.toString();
      if (endDateToString !== undefined) {
        const dateComponents = endDateToString.split(/[T\-:]/).map(parseFloat);
        const year = dateComponents[0];
        const month = dateComponents[1];
        const day = dateComponents[2];

        endDateToString = `${day}-${month}-${year}`;
      } else {
        endDateToString = '';
      }

      const targetAdherent = [
        adherent.id,
        adherent.firstname,
        adherent.lastname,
        adherent.phone,
        adherent.email,
        adherent.lastMembership?.subsidiary.name,
        formatDate(createdAt),
        adherent.address?.number,
        adherent.address?.street,
        adherent.address?.complement,
        adherent.address?.city,
        adherent.address?.state,
        adherent.address?.zipCode,
        adherent.address?.country,
        beginAt !== '' ? formatDate(beginAt) : '',
        adherent.lastMembership?.localAmount,
        adherent.lastMembership?.siegeAmount,
        (adherent.lastMembership?.localAmount
          ? adherent.lastMembership?.localAmount
          : 0) +
          (adherent.lastMembership?.siegeAmount
            ? adherent.lastMembership?.siegeAmount
            : 0),
        adherent.lastMembership?.origin,
        adherent.lastMembership?.priceType,
        adherent.lastMembership?.card?.type,
        adherent.lastMembership?.card?.status,
      ];
      if (role !== 'ROLE_SUBSIDIARY') {
        // targetAdherent.push(endAt !== '' ? formatDate(endAt) : '');
        targetAdherent.push(endDateToString !== '' ? endDateToString : '');
      } else {
        targetAdherent.push(
          adherent.lastMembership?.endAt &&
            new Date() < adherent.lastMembership?.endAt
            ? 'À jour'
            : 'À renouveler',
        );
      }
      exportFileData.push(targetAdherent);
    });

    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet(exportFileData);
    utils.book_append_sheet(wb, ws, 'Adhérents');
    writeFile(wb, 'adherents-' + formatDate(new Date()) + '.xlsx', {
      bookType: 'xlsx',
      type: 'binary',
    });
  };

  return (
    <button
      className="bg-white border p-2 text-sm"
      onClick={exportSelectedAdherents}
    >
      Exporter les adhérents individuels selectionnés
    </button>
  );
}
