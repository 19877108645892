import React, { useState } from 'react';
import useModal from '../../../components/modals/useModal';
import option from '../../../assets/images/options.svg';
import OnclickPosModal from '../../../components/modals/OnclickPosModal';
import { Donation } from '../types/Donation';
import DonationDeleteButton from './DonationDeleteButton';
import DonationPutButton from './DonationPutButton';

interface Props {
  donation: Donation;
}

export default function DonationActionButton(props: Props) {
  const { isShowing, toggle } = useModal();
  const [style, setStyle] = useState({});

  const openModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setStyle({ top: e.clientY, left: e.clientX - 167 });
    toggle();
  };

  return (
    <>
      <button
        className="p-2"
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          openModal(e)
        }
      >
        <img alt="option-icon" src={option} className="w-1" />
      </button>
      <OnclickPosModal
        isShowing={isShowing}
        hide={toggle}
        style={style}
        title={'Actions'}
      >
        <div className="flex flex-col">
          <DonationPutButton donation={props.donation} closeParent={toggle} />
          <DonationDeleteButton
            donation={props.donation}
            closeParent={toggle}
          />
        </div>
      </OnclickPosModal>
    </>
  );
}
