import { SubsidiaryNote } from '../types/SubsidiaryNote';
import {
  useSelectedSubsidiaryNote,
  useSetSelectedSubsidiaryNote,
} from '../contexts/SubsidiaryNoteCollectionContext';
import { useEffect, useState } from 'react';

interface Props {
  subsidiaryNote: SubsidiaryNote;
}

export default function SubsidiaryNoteItem(props: Props) {
  const selectedSubsidiaryNote = useSelectedSubsidiaryNote();
  const setSelectedSubsidiaryNote = useSetSelectedSubsidiaryNote();
  const [style, setStyle] = useState('');

  useEffect(() => {
    if (selectedSubsidiaryNote) {
      setStyle(
        selectedSubsidiaryNote['@id'] === props.subsidiaryNote['@id']
          ? 'bg-gray-100'
          : '',
      );
    }
  }, [selectedSubsidiaryNote]);

  function handleClick() {
    setSelectedSubsidiaryNote(props.subsidiaryNote);
  }

  function createDate(dateToTransform: Date) {
    const date = new Date(dateToTransform);
    const [month, day, year, hour, minute] = [
      date.getMonth() > 9 ? date.getMonth() : '0' + date.getMonth(),
      date.getDate() > 9 ? date.getDate() : '0' + date.getDate(),
      date.getFullYear(),
      date.getHours(),
      date.getMinutes(),
    ];
    return day + '-' + month + '-' + year + ' ' + hour + ':' + minute;
  }

  return (
    <tr onClick={handleClick} className={`${style} cursor-pointer`}>
      <td className="p-2">
        {props.subsidiaryNote?.author
          ? props.subsidiaryNote?.author?.firstname +
            ' ' +
            props.subsidiaryNote?.author?.lastname
          : 'Administrateur'}
      </td>
      <td className="p-2">{props.subsidiaryNote?.message}</td>
      <td className="p-2">{createDate(props.subsidiaryNote?.createdAt)}</td>
      <td className="p-2">{createDate(props.subsidiaryNote?.updatedAt)}</td>
    </tr>
  );
}
