import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import React, { useState } from 'react';
import { Adherent } from '../types/Adherent';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';
import { toast } from 'react-toastify';
import { Partner } from '../types/Partner';
import { AdherentSearch } from '../types/AdherentSearch';

interface Props {
  partner: Partner | null;
  setPartner: (partner: Partner) => void;
  hide: () => void;
  subsidiary?: Subsidiary;
  adherent: Adherent;
}

export default function PartnerPutForm(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const [keyword, setKeyword] = useState('');
  const [adherent, setAdherent] = useState<AdherentSearch>();
  const [adherents, setAdherents] = useState<AdherentSearch[]>([]);

  const searchAdherent = () => {
    if (props.subsidiary) {
      axiosPrivate
        .get(
          '/search/adherents?partnerSearch=true' +
            (props.subsidiary['@id']
              ? '&subsidiary=' + props.subsidiary['@id']
              : '') +
            (keyword ? '&lastname=' + keyword : ''),
        )
        .then(
          (response) => {
            const adherentsList: AdherentSearch[] = response.data;
            const adherentsListFilter = adherentsList.filter(
              (adherent) =>
                '/adherents/' + adherent.id !== props.adherent['@id'] &&
                adherent.id !== props.partner?.number,
            );
            setAdherents(adherentsListFilter);
          },
          (error) => {
            console.log(error);
          },
        );
    }
  };

  const submitPartner = () => {
    if (adherent) {
      toast
        .promise(
          axiosPrivate.put(
            '/partner/updatePartner?adherent=' +
              props.adherent['@id'] +
              '&partner=/adherents/' +
              adherent.id,
          ),
          {
            pending: 'Ajout...',
            success: 'Succès.',
            error: "Cet adhérent n'a pas pu être ajouté comme conjoint.",
          },
          { position: toast.POSITION.BOTTOM_CENTER },
        )
        .then(
          (response) => {
            props.setPartner({
              number: response.data.number,
              firstname: response.data.firstname,
              lastname: response.data.lastname,
            });
            props.hide();
          },
          (error) => {
            console.log(error);
          },
        );
    }
  };

  const selectedAdherent = (adherent: AdherentSearch) => {
    setAdherent(adherent);
  };

  const searchPressKeyboard = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      searchAdherent();
    }
  };

  return (
    <div>
      {adherent ? (
        <>
          <div className="flex flex-col">
            <p>
              {adherent.lastname} {adherent.firstname} va être ajouté comme
              conjoint à cet adhérent.
            </p>
          </div>
          <button
            className="px-2 py-1 mt-4 text-white bg-green-ufe w-full"
            type="button"
            onClick={() => {
              submitPartner();
              props.hide();
            }}
          >
            Ajouter
          </button>
        </>
      ) : (
        <div className="flex flex-col">
          <input
            type="text"
            placeholder="Recherchez un adhérent"
            className="border px-2 flex items-center h-8"
            value={keyword}
            onKeyUp={(event) => {
              searchPressKeyboard(event);
            }}
            onChange={(event) => {
              setKeyword(event.target.value);
            }}
          />
          <button
            type="button"
            className="px-2 py-1 text-white bg-green-ufe mt-4 mb-4"
            onClick={searchAdherent}
          >
            Recherche
          </button>
        </div>
      )}

      {adherents.length !== 0 && !adherent ? (
        <div className="w-100 h-80 overflow-y-auto">
          <h4>Veuillez sélectionner un adhérent</h4>
          {adherents &&
            adherents.map((adherent, key) => {
              return (
                <div
                  className="flex justify-between items-center mt-3 px-2"
                  key={key}
                >
                  <p className="mr-5">
                    {adherent.lastname} {adherent.firstname}
                  </p>
                  <button
                    type="button"
                    className="px-2 py-1 text-white bg-green-ufe"
                    onClick={() => selectedAdherent(adherent)}
                  >
                    Sélectionner
                  </button>
                </div>
              );
            })}
        </div>
      ) : null}
    </div>
  );
}
