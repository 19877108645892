import pic from '../../assets/images/logo.svg';

export default function MaintenancePage() {
  return (
    <div className="flex flex-col items-center">
      <img src={pic} alt="logo" />
      <h1 className="text-3xl py-10 text-blue-ufe font-bold">UFE INTRANET</h1>
      <h2 className="text-2xl py-10 text-blue-ufe font-bold">
        Merci de contacter le siège ou d'envoyer un mail à support@ufe.org
      </h2>
    </div>
  );
}
