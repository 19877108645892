import Modal from '../../../components/modals/Modal';
import useModal from '../../../components/modals/useModal';
import plusIcon from '../../../assets/images/plusIcon.svg';
import EmployeePostForm from '../forms/EmployeePostForm';

export default function EmployeePostButton() {
  const { isShowing, toggle } = useModal();

  return (
    <>
      <div
        className="w-8 h-8 bg-green-ufeLight flex justify-center items-center pointer"
        onClick={toggle}
      >
        <img src={plusIcon} alt="plus icon" className="w-4" />
      </div>
      <Modal
        isShowing={isShowing}
        hide={toggle}
        title={"Nouveau membre de l'équipe"}
      >
        <EmployeePostForm hide={toggle} />
      </Modal>
    </>
  );
}
