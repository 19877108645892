import React, { ChangeEvent, useEffect, useState } from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Adherent } from '../../Adherent/types/Adherent';
import { Price } from '../../Price/types/Price';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import {
  useMemberships,
  useMembershipsDispatch,
  useMembershipsFetch,
  useMembershipsItemsPerPage,
  useMembershipsLinks,
} from '../contexts/MembershipCollectionContext';
import { useAdherentDispatch } from '../../Adherent/contexts/AdherentContext';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';
import { useUser } from '../../User/contexts/UserContextProvider';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';

interface Values {
  siegeAmount: number;
  localAmount: number;
  priceType: string;
  origin: string;
  adherent: string;
  subsidiary: string;
  beginAt: string;
  endAt: string;
}

interface Props {
  hide: () => void;
  adherent: Adherent;
}

export default function MembershipPostForm(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const role = auth?.payload?.roles[0];
  const user = useUser();
  const [subsidiaryIri, setSubsidiaryIri] = useState(
    user?.subsidiary['@id'] ? user?.subsidiary['@id'] : '',
  );
  const [selectedPrice, setSelectedPrice] = useState<Price>();
  const memberships = useMemberships();
  const links = useMembershipsLinks();
  const fetchMemberships = useMembershipsFetch();
  const membershipDispatch = useMembershipsDispatch();
  const adherentDispatch = useAdherentDispatch();
  const itemsPerPage = useMembershipsItemsPerPage();
  const [subsidiaries, setSubsidiaries] = useState<Subsidiary[]>([]);
  const [year, setYear] = useState('');
  const [prices, setPrices] = useState<Price[]>([]);

  useEffect(() => {
    axiosPrivate
      .get('/subsidiaries?pagination=false')
      .then((response) => {
        setSubsidiaries(response?.data['hydra:member']);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [axiosPrivate]);

  useEffect(() => {
    if (year !== '' && subsidiaryIri !== '') {
      axiosPrivate
        .get('/prices?subsidiary=' + subsidiaryIri + '&year=' + year)
        .then((response) => {
          setPrices(response?.data['hydra:member']);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [axiosPrivate, year, subsidiaryIri]);

  const handleSubsidiarySelect = (
    event: ChangeEvent<HTMLSelectElement>,
  ): void => {
    setSubsidiaryIri(event.target.value);
    setSelectedPrice(undefined);
  };

  const handleYearSelect = (event: ChangeEvent<HTMLSelectElement>): void => {
    setYear(event.target.value);
    setSelectedPrice(undefined);
  };

  const handlePriceSelect = (event: ChangeEvent<HTMLSelectElement>): void => {
    prices.forEach((price: Price) => {
      if (price.type === event.target.value) {
        setSelectedPrice(price);
      }
    });
  };

  return (
    <Formik
      initialValues={{
        adherent: props.adherent['@id'],
        subsidiary: '',
        siegeAmount: 0,
        localAmount: 0,
        priceType: '',
        origin: '',
        beginAt: '',
        endAt: '',
      }}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        if (selectedPrice) {
          values.siegeAmount = selectedPrice.siegeAmount;
          values.localAmount = selectedPrice.localAmount;
          values.priceType = selectedPrice.type;
        } else {
          toast.error('Veuillez sélectionner un barème.', {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          return Promise.reject(new Error('Veuillez sélectionner un barème.'));
        }
        if (role === 'ROLE_SUBSIDIARY') {
          values.origin = 'REPRESENTATION';
        }
        values.beginAt = year + '-01-01 00:00:00';
        values.endAt = year + '-12-31 23:59:59';
        values.subsidiary = subsidiaryIri;

        toast
          .promise(
            axiosPrivate.post('/memberships', values),
            {
              pending: 'Création en cours...',
              success: 'Cotisation créée.',
              error: 'Une erreur est survenue.',
            },
            { position: toast.POSITION.BOTTOM_CENTER },
          )
          .then(
            (response) => {
              if (memberships.length < itemsPerPage) {
                membershipDispatch({
                  type: 'added',
                  membership: response.data,
                });

                // Update lastMembership of adherent if more recent
                if (
                  (props.adherent.lastMembership?.endAt &&
                    response.data.endAt >=
                      props.adherent.lastMembership.endAt) ||
                  !props.adherent.lastMembership
                ) {
                  const tempAdherent = props.adherent;
                  tempAdherent.lastMembership = response.data;
                  adherentDispatch({
                    type: 'changed',
                    adherent: tempAdherent,
                  });
                }
              } else {
                fetchMemberships(links?.['@id']);
              }
              props.hide();
            },
            (error) => {
              console.log(error);
            },
          )
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      <Form>
        {role !== 'ROLE_SUBSIDIARY' && (
          <div className="flex flex-col mb-4">
            <label>Représentation</label>
            <select
              className="h-8 px-2 border border-gray-300 rounded-sm"
              onChange={(e) => handleSubsidiarySelect(e)}
              value={subsidiaryIri}
            >
              <option value="">---</option>
              {subsidiaries &&
                subsidiaries.map((element, index: number) => {
                  return (
                    <option key={index} value={element['@id']}>
                      {element.name}
                    </option>
                  );
                })}
            </select>
          </div>
        )}
        <div className="flex flex-col mb-4">
          <label>Année</label>
          <select
            value={year}
            className="border px-2 flex items-center h-8 w-full"
            onChange={(e) => handleYearSelect(e)}
          >
            <option value="">---</option>
            <option value="2017">2017</option>
            <option value="2018">2018</option>
            <option value="2019">2019</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label>Barème</label>
          <select
            className="h-8 px-2 border border-gray-300 rounded-sm"
            onChange={(e) => handlePriceSelect(e)}
            value={selectedPrice?.type ? selectedPrice.type : ''}
          >
            <option value="">---</option>
            {prices &&
              prices.map((price: Price, key) => {
                return (
                  <option key={key} value={price.type}>
                    {price.type} - Siège: {price.siegeAmount} - Local:{' '}
                    {price.localAmount}
                  </option>
                );
              })}
          </select>
        </div>
        {role !== 'ROLE_SUBSIDIARY' && (
          <div className="mt-4">
            <label>Origine du paiement :</label>
            <Field
              as="select"
              id="origin"
              name="origin"
              className="border px-2 flex items-center h-8 w-full"
            >
              <option value="">---</option>
              <option value="SIEGE">Siège</option>
              <option value="REPRESENTATION">UFE locale</option>
              <option value="SITE INTERNET">Site Internet</option>
            </Field>
          </div>
        )}
        <button
          className="px-2 py-1 text-white bg-green-ufe w-full mt-4"
          type="submit"
        >
          Ajouter
        </button>
      </Form>
    </Formik>
  );
}
