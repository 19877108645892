import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { DeadlineData } from '../../Chart/types/DeadlineData';
import { Subsidiary } from '../types/Subsidiary';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

interface Values {
  alreadyPaidAmountSiege: number;
  previousYearAmountSiege: number;
}

const ValidationSchema = Yup.object().shape({
  alreadyPaidAmountSiege: Yup.number().required(
    'Le champ du montant déjà payé est obligatoire',
  ),
  previousYearAmountSiege: Yup.number().required(
    "Le champ du montant de l'année précédente est obligatoire",
  ),
});

interface Props {
  data: DeadlineData;
  setData: (data: DeadlineData) => void;
  chosenSubsidiary: Subsidiary | null;
  onStayAmountUpdateSiege: (newStayAmountSiege: number) => void;
}

const DeadlineSiegeForm = (props: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const role = auth?.payload.roles[0];
  const isDisabled = role === 'ROLE_SUBSIDIARY' || !props.chosenSubsidiary;
  const handleStayAmountUpdateSiege = (newStayAmountSiege: number) => {
    props.onStayAmountUpdateSiege(newStayAmountSiege);
  };

  useEffect(() => {
    handleStayAmountUpdateSiege(props.data?.stayAmount);
  }, [props.data?.stayAmount]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        alreadyPaidAmountSiege: props.data?.alreadyPaidAmountSiege || 0,
        previousYearAmountSiege: props.data?.previousYearAmountSiege || 0,
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        if (props.chosenSubsidiary && props.chosenSubsidiary.financialBalance) {
          toast
            .promise(
              axiosPrivate.put(props.chosenSubsidiary.financialBalance, values),
              {
                pending: 'Modification en cours...',
                success: 'Modification réussie.',
                error: 'Une erreur est survenue.',
              },
              { position: toast.POSITION.BOTTOM_CENTER },
            )
            .then((response) => {
              const newData = { ...props.data };
              newData.alreadyPaidAmountSiege =
                response.data.alreadyPaidAmountSiege;
              newData.previousYearAmountSiege =
                response.data.previousYearAmountSiege;
              newData.stayAmount =
                props.data?.dueAmount - response.data.alreadyPaidAmountSiege;
              props.setData(newData);
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setSubmitting(false);
            });
        } else {
          toast.error(
            'Error 500 : Aucune balance financière trouvée pour cette représentation.',
            { position: toast.POSITION.BOTTOM_CENTER },
          );
        }
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="flex justify-end mt-2 items-center">
            <label>Montant dû par la représentation au siège</label>
            <span className="flex items-center ml-2">
              <input
                type="number"
                value={props.data?.dueAmount}
                readOnly={true}
                disabled={true}
                className="border px-2 h-8 mr-1.5"
              />
              €
            </span>
          </div>
          <div className="flex justify-end mt-5 items-center">
            <label>Montant déjà payé par la représentation</label>
            <span className="flex items-center ml-2">
              <Field
                type="number"
                name="alreadyPaidAmountSiege"
                readOnly={isDisabled}
                disabled={isDisabled}
                className="border px-2 flex items-center h-8 mr-1.5"
              />
              €
            </span>
            <div>
              {errors.alreadyPaidAmountSiege && touched.alreadyPaidAmountSiege
                ? errors.alreadyPaidAmountSiege
                : null}
            </div>
          </div>
          <div className="flex justify-end mt-5 items-center">
            <label>Montant restant à payer</label>
            <span className="flex items-center ml-2">
              <input
                type="number"
                value={props.data?.stayAmount}
                readOnly={true}
                disabled={true}
                className="border px-2 flex items-center h-8 mr-1.5"
              />
              €
            </span>
          </div>
          <div className="flex justify-end mt-5 items-center">
            <label>Solde années précédentes</label>
            <span className="flex items-center ml-2">
              <Field
                type="number"
                name="previousYearAmountSiege"
                readOnly={isDisabled}
                disabled={isDisabled}
                className="border px-2 flex items-center h-8 mr-1.5"
              />
              €
            </span>
            <div>
              {errors.previousYearAmountSiege && touched.previousYearAmountSiege
                ? errors.previousYearAmountSiege
                : null}
            </div>
          </div>
          {!isDisabled && (
            <div className="flex justify-end mt-5">
              <button
                className="text-sm text-white bg-green-ufe px-5 py-1 mr-4"
                type="submit"
              >
                Mettre à jour
              </button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default DeadlineSiegeForm;
