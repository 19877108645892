import multipleOptions from '../../../assets/images/multipleOptions.svg';
import { useMemberships } from '../contexts/MembershipCollectionContext';
import { Adherent } from '../../Adherent/types/Adherent';
import MembershipItem from '../items/MembershipItem';
import MembershipPostButton from '../buttons/MembershipPostButton';
import ResendCertificateButton from '../../Adherent/buttons/ResendCertificateButton';
import React from 'react';
import useModal from '../../../components/modals/useModal';

interface Props {
  adherent: Adherent;
}

export default function MembershipsContainer(props: Props) {
  const memberships = useMemberships();
  const { toggle } = useModal();

  return (
    <div className="h-min p-5 bg-white flex flex-col w-full">
      <div className="flex justify-between items-center mb-5">
        <h3 className="font-bold">Adhésions</h3>
        <div className="space-x-4">
          <ResendCertificateButton
            adherent={props.adherent}
            closeParent={toggle}
          />
          <MembershipPostButton adherent={props.adherent} />
        </div>
      </div>
      <table className="border">
        <thead>
          <tr className="bg-green-ufeLight">
            <td className="p-2 text-left">Type d'abonnement</td>
            <td className="p-2 text-left">Montant local</td>
            <td className="p-2 text-left">Montant siège</td>
            <td className="p-2 text-left">Montant total</td>
            <td className="p-2 text-left">Origine</td>
            <td className="p-2 text-left">Représentation</td>
            <td className="p-2 text-left">Année</td>
            <td className="p-2 text-left">Status</td>
            <td className="p-2 text-left">
              <img alt="option-icon" src={multipleOptions} />
            </td>
          </tr>
        </thead>
        <tbody>
          {memberships &&
            memberships.map((element, id) => {
              return (
                <MembershipItem
                  adherent={props.adherent}
                  membership={element}
                  key={id}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
