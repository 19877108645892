import * as React from 'react';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { User } from '../types/User';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';
import { useNavigate } from 'react-router-dom';

type UserContextInterface = {
  user: User | undefined;
};

const UserContext = createContext({} as UserContextInterface);

interface Props {
  children: ReactNode;
}

export const UserContextProvider = (props: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const [user, setUser] = useState<User>();
  const { auth } = useAuth();
  const role = auth?.payload.roles[0];
  const navigate = useNavigate();

  useEffect(() => {
    if (role === 'ROLE_SIEGE' || role === 'ROLE_SUBSIDIARY') {
      axiosPrivate.get('/users/' + auth?.payload.id).then(
        (response) => {
          setUser(response.data);
          navigate('/', { replace: true });
        },
        (error) => {
          console.log(error);
        },
      );
    } else {
      navigate('/', { replace: true });
    }
  }, [axiosPrivate, setUser, role, auth]);

  return (
    <UserContext.Provider value={{ user }}>
      {props.children}
    </UserContext.Provider>
  );
};

export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserContextProvider');
  }
  return context.user;
}
