import { useEmployees } from '../contexts/EmployeeCollectionContext';
import EmployeeItem from '../items/EmployeeItem';
import EmployeePostButton from '../buttons/EmployeePostButton';

export default function EmployeeCollectionContainer() {
  const employees = useEmployees();
  return (
    <div className="bg-white p-5 w-1/3">
      <div className="flex items-center gap-x-5">
        <h3 className="font-bold">Membres de l'équipe</h3>
        <EmployeePostButton />
      </div>
      <div className="mt-5 flex flex-col w-full space-y-4">
        {employees &&
          employees.map((employee, key) => {
            return <EmployeeItem employee={employee} key={key} />;
          })}
      </div>
    </div>
  );
}
