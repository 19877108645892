import { SubsidiaryNote } from '../types/SubsidiaryNote';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';
import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { useSubsidiary } from '../../Subsidiary/contexts/SubsidiaryContext';

type Action =
  | { type: 'loaded'; subsidiaryNotes: SubsidiaryNote[] }
  | { type: 'added'; subsidiaryNote: SubsidiaryNote }
  | { type: 'changed'; subsidiaryNote: SubsidiaryNote }
  | { type: 'deleted'; subsidiaryNote: SubsidiaryNote };

type Dispatch = (action: Action) => void;

type SubsidiaryNotesStateProps = {
  subsidiaryNotes: SubsidiaryNote[];
  subsidiary: Subsidiary | undefined;
  selectedSubsidiaryNote: SubsidiaryNote | null;
};

type SubsidiaryNotesDisptachContentProps = {
  dispatch: Dispatch;
  fetchSubsidiaryNotes: (url?: string) => void;
  setSelectedSubsidiaryNote: (subsidiaryNote: SubsidiaryNote | null) => void;
};

const SubsidiaryNotesStateContext = createContext<
  SubsidiaryNotesStateProps | undefined
>(undefined);

const SubsidiaryNoteCollectionDisptachContext = createContext<
  SubsidiaryNotesDisptachContentProps | undefined
>(undefined);

const subsidiaryNotesReducer = (
  subsidiaryNotes: SubsidiaryNote[],
  action: Action,
) => {
  switch (action.type) {
    case 'loaded':
      return action.subsidiaryNotes;
    case 'added':
      return [...subsidiaryNotes, action.subsidiaryNote];
    case 'changed':
      return subsidiaryNotes.map((subsidiaryNote) => {
        if (subsidiaryNote['@id'] === action.subsidiaryNote['@id']) {
          return action.subsidiaryNote;
        }
        return subsidiaryNote;
      });
    case 'deleted':
      return subsidiaryNotes.filter(
        (subsidiaryNote) =>
          subsidiaryNote['@id'] !== action.subsidiaryNote['@id'],
      );
    default:
      throw new Error(`Unhandled action type`);
  }
};

interface Props {
  children: React.ReactNode;
}

export default function SubsidiaryNoteCollectionContext(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const [subsidiaryNotes, dispatch] = useReducer(subsidiaryNotesReducer, []);
  const [selectedSubsidiaryNote, setSelectedSubsidiaryNote] =
    useState<SubsidiaryNote | null>(null);
  const subsidiary = useSubsidiary();

  const fetchSubsidiaryNotes = useCallback(() => {
    if (subsidiary['@id']) {
      axiosPrivate
        .get(`/subsidiary_notes?subsidiary=${subsidiary['@id']}`)
        .then((response) => {
          dispatch({
            type: 'loaded',
            subsidiaryNotes: response.data['hydra:member'],
          });
        });
    }
  }, [axiosPrivate, subsidiary]);

  useEffect(() => {
    if (subsidiary) {
      fetchSubsidiaryNotes();
    }
  }, [fetchSubsidiaryNotes, subsidiary]);

  return (
    <SubsidiaryNotesStateContext.Provider
      value={{
        subsidiaryNotes,
        subsidiary: subsidiary,
        selectedSubsidiaryNote,
      }}
    >
      <SubsidiaryNoteCollectionDisptachContext.Provider
        value={{ dispatch, fetchSubsidiaryNotes, setSelectedSubsidiaryNote }}
      >
        {props.children}
      </SubsidiaryNoteCollectionDisptachContext.Provider>
    </SubsidiaryNotesStateContext.Provider>
  );
}

export function useSubsidiaryNotes() {
  const context = React.useContext(SubsidiaryNotesStateContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiaryNotesState must be used within a SubsidiaryNoteCollectionContext',
    );
  }
  return context.subsidiaryNotes;
}

export function useSelectedSubsidiaryNote() {
  const context = React.useContext(SubsidiaryNotesStateContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiaryNotesState must be used within a SubsidiaryNoteCollectionContext',
    );
  }
  return context.selectedSubsidiaryNote;
}

export function useSubsidiaryNotesDispatch() {
  const context = React.useContext(SubsidiaryNoteCollectionDisptachContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiaryNotesDispatch must be used within a SubsidiaryNoteCollectionContext',
    );
  }
  return context.dispatch;
}

export function useSetSelectedSubsidiaryNote() {
  const context = React.useContext(SubsidiaryNoteCollectionDisptachContext);
  if (context === undefined) {
    throw new Error(
      'useSetSelectedSubsidiaryNote must be used within a SubsidiaryNoteCollectionContext',
    );
  }
  return context.setSelectedSubsidiaryNote;
}
