import pic from '../assets/images/logo.svg';
import Navigation from './Navigation';
import LogoutButton from '../modules/Auth/buttons/LogoutButton';
import { useAuth } from '../modules/Auth/contexts/AuthContextProvider';
import { useUser } from '../modules/User/contexts/UserContextProvider';

export default function NavBar() {
  const { auth } = useAuth();
  const user = useUser();
  const role = auth?.payload.roles[0];
  const email = auth?.payload.username;

  return (
    <div
      className={
        role === 'ROLE_SUBSIDIARY'
          ? 'flex h-14 justify-between items-center bg-green-ufe'
          : 'flex h-14 justify-between items-center bg-green-ufe'
      }
    >
      <div className="flex items-center ml-6">
        <img src={pic} alt="logo" className="h-6" />
        <Navigation />
      </div>
      <div className="text-white flex items-center py-1 px-4 mr-6 bg-black-ufe rounded-2xl">
        <div className="text-sm pr-2 border-r mr-2">
          {role === 'ROLE_ADMIN'
            ? email
            : user?.firstname + ' ' + user?.lastname}
        </div>
        <LogoutButton />
      </div>
    </div>
  );
}
