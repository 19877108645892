interface Props {
  totalItems: number;
  setItemsPerPage: (itemsPerPage: number) => void;
}

export default function RowSelector(props: Props) {
  const handleChange = (e: { target: { value: string } }) => {
    props.setItemsPerPage(parseInt(e.target.value));
  };

  return (
    <div className="flex">
      <p className="mr-5">Nombre de résultats total: {props.totalItems}</p>
      <label>Nombre de résultats par page:</label>
      <select className="ml-2 border py-1 px-1" onChange={handleChange}>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
      </select>
    </div>
  );
}
