import React, { useState } from 'react';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';
import { toast } from 'react-toastify';
import {
  useFetchUsers,
  useUsers,
  useUsersDispatch,
  useUsersItemsPerPage,
  useUsersLinks,
} from '../contexts/UserCollectionContext';

interface Values {
  subsidiary?: string;
  firstname: string;
  lastname: string;
  email: string;
  plainPassword: string;
  roles: any;
}

interface Props {
  hide: () => void;
}

export default function UserPostForm(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const [subsidiaries, setSubsidiaries] = useState<Subsidiary[]>();
  const [subsidiary, setSubsidiary] = useState<Subsidiary | null>();
  const [role, setRole] = useState('');
  const [keyword, setKeyword] = useState('');
  const subsidiaryRoles = ['ROLE_SUBSIDIARY', 'ROLE_ADHERENT'];
  const mainRoles = ['ROLE_ADMIN', 'ROLE_SIEGE'];
  const users = useUsers();
  const links = useUsersLinks();
  const fetchUsers = useFetchUsers();
  const dispatch = useUsersDispatch();
  const itemsPerPage = useUsersItemsPerPage();

  const searchSubsidiary = () => {
    axiosPrivate.get('/subsidiaries?name=' + keyword).then(
      (response) => {
        setSubsidiaries(response?.data['hydra:member']);
      },
      (error) => {
        console.log(error);
      },
    );
  };

  const getWorldUFESubsidiary = () => {
    axiosPrivate.get('/subsidiaries?name=UFE MONDE').then(
      (response) => {
        setSubsidiary(response?.data['hydra:member'][0]);
      },
      (error) => {
        console.log(error);
      },
    );
  };

  const chooseSubsidiary = (subsidiary: Subsidiary) => {
    setSubsidiaries([]);
    setSubsidiary(subsidiary);
  };

  const UserSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Veuillez remplir le champ'),
    lastname: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Veuillez remplir le champ'),
    email: Yup.string()
      .email('Email invalide')
      .required('Veuillez remplir le champ'),
    plainPassword: Yup.string()
      .required('Veuillez remplir le champ')
      .min(6, 'Votre mots de passe doit contenir 6 caractère minimum'),
  });

  return (
    <Formik
      initialValues={{
        firstname: '',
        lastname: '',
        email: '',
        plainPassword: '',
        roles: [],
      }}
      validationSchema={UserSchema}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        if (subsidiary) {
          values.subsidiary = subsidiary['@id'];
        }
        values.roles = [role];
        toast
          .promise(
            axiosPrivate.post('/users', values),
            {
              pending: 'Création...',
              success: 'Succès.',
              error: 'Une erreur est survenue.',
            },
            { position: toast.POSITION.BOTTOM_CENTER },
          )
          .then(
            (response) => {
              if (users.length < itemsPerPage) {
                dispatch({ type: 'added', user: response.data });
              } else {
                fetchUsers(links?.['@id']);
              }
              props.hide();
            },
            (error) => {
              console.log(error);
            },
          )
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched }) => (
        <Form className="modal-form">
          <div className="flex flex-col w-80">
            <label>Rôle</label>
            <Field
              as="select"
              id="role"
              name="role"
              className="border px-2 flex items-center h-8 flex-col mb-4"
              placeholder="Role"
              value={role}
              onChange={(e: { target: { value: string } }) => {
                setRole(e.target.value);
                if (e.target.value === 'ROLE_SIEGE') {
                  getWorldUFESubsidiary();
                }
              }}
            >
              <option value="" disabled={true}>
                Choisissez un rôle
              </option>
              <option value="ROLE_SIEGE">Siège</option>
              <option value="ROLE_SUBSIDIARY">Représentation</option>
            </Field>
            {subsidiaryRoles.includes(role) && !subsidiary ? (
              <div className="flex flex-col">
                <input
                  type="text"
                  className="border px-2 flex items-center h-8"
                  placeholder="Recherchez une représentation"
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                />
                <button
                  type="button"
                  className="px-2 py-1 text-white bg-green-ufe mt-4"
                  onClick={searchSubsidiary}
                >
                  Recherche
                </button>
              </div>
            ) : null}
            {role &&
            !mainRoles.includes(role) &&
            !subsidiary &&
            subsidiaries &&
            subsidiaries.length !== 0 ? (
              <div className="w-60 overflow-y-auto">
                {subsidiaries &&
                  subsidiaries.map((subsidiary, key) => {
                    return (
                      <div
                        className="flex justify-between items-center mt-3 px-2"
                        key={key}
                      >
                        <p>{subsidiary.name}</p>
                        <button
                          type="button"
                          className="px-2 py-1 text-white bg-green-ufe mr-2"
                          onClick={() => chooseSubsidiary(subsidiary)}
                        >
                          Sélectionner
                        </button>
                      </div>
                    );
                  })}
              </div>
            ) : null}
            {subsidiary && role !== 'ROLE_ADMIN' ? (
              <div className="flex flex-col mb-4">
                <label>Représentation</label>
                <div className="border px-2 flex items-center h-8 bg-gray-300">
                  {subsidiary.name}
                </div>
                {role !== 'ROLE_SIEGE' ? (
                  <button
                    type="button"
                    className="px-2 py-1 text-white bg-green-ufe mt-4"
                    onClick={() => setSubsidiary(null)}
                  >
                    Modifier
                  </button>
                ) : null}
              </div>
            ) : null}
            {mainRoles.includes(role) || subsidiary ? (
              <div className="flex flex-col">
                <div className="flex flex-col mb-4">
                  <label>Nom</label>
                  <Field
                    id="lastname"
                    name="lastname"
                    placeholder="Nom"
                    type="text"
                    className="border px-2 flex items-center h-8"
                  />
                  {errors.lastname && touched.lastname ? (
                    <p className="text-sm text-red-700 mt-1">
                      {errors.lastname}
                    </p>
                  ) : null}
                </div>
                <div className="flex flex-col mb-4">
                  <label>Prénom</label>
                  <Field
                    id="firstname"
                    name="firstname"
                    placeholder="Prénom"
                    type="text"
                    className="border px-2 flex items-center h-8"
                  />
                  {errors.firstname && touched.firstname ? (
                    <p className="text-sm text-red-700 mt-1">
                      {errors.firstname}
                    </p>
                  ) : null}
                </div>
                <div className="flex flex-col mb-4">
                  <label>Email</label>
                  <Field
                    id="email"
                    name="email"
                    placeholder="Email"
                    type="email"
                    className="border px-2 flex items-center h-8"
                  />
                  {errors.email && touched.email ? (
                    <p className="text-sm text-red-700 mt-1">{errors.email}</p>
                  ) : null}
                </div>
                <div className="flex flex-col mb-4">
                  <label>Mot de passe</label>
                  <Field
                    id="plainPassword"
                    name="plainPassword"
                    placeholder="Password"
                    type="password"
                    className="border px-2 flex items-center h-8"
                  />
                  {errors.plainPassword && touched.plainPassword ? (
                    <p className="text-sm text-red-700 mt-1">
                      {errors.plainPassword}
                    </p>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
          <button
            type="submit"
            className="px-2 py-1 text-white bg-green-ufe w-full"
          >
            Créer
          </button>
        </Form>
      )}
    </Formik>
  );
}
