import AdherentByMonthChart from '../adherent/AdherentByMonthChart';
import AdherentByYearChart from '../adherent/AdherentByYearChart';
import AdherentBySubsidiaryChart from '../adherent/AdherentBySubsidiaryChart';
import ActualStateChart from '../adherent/ActualStateChart';
import CurrentAdherentStat from '../adherent/CurrentAdherentStat';
import RenewAdherentStat from '../adherent/RenewAdherentStat';
import TotalSubsidiaryStat from '../adherent/TotalSubsidiaryStat';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';

interface Props {
  chosenSubsidiary: Subsidiary | null;
}

const AdherentChartsContainer = (props: Props) => {
  const { auth } = useAuth();
  const role = auth?.payload.roles[0];

  return (
    <>
      <div className="my-5 flex justify-between gap-x-5 w-full">
        <CurrentAdherentStat chosenSubsidiary={props.chosenSubsidiary} />
        <RenewAdherentStat chosenSubsidiary={props.chosenSubsidiary} />
        {!props.chosenSubsidiary && role !== 'ROLE_SUBSIDIARY' && (
          <TotalSubsidiaryStat />
        )}
      </div>
      <div className="flex gap-x-5 w-full pb-5">
        <div className="flex flex-col gap-y-5 w-1/2">
          <AdherentByMonthChart chosenSubsidiary={props.chosenSubsidiary} />
          <ActualStateChart chosenSubsidiary={props.chosenSubsidiary} />
        </div>
        <div className="flex gap-x-5 w-1/2">
          <AdherentByYearChart chosenSubsidiary={props.chosenSubsidiary} />
          {!props.chosenSubsidiary && role !== 'ROLE_SUBSIDIARY' && (
            <AdherentBySubsidiaryChart />
          )}
        </div>
      </div>
    </>
  );
};

export default AdherentChartsContainer;
