import * as Yup from 'yup';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import {
  useSubsidiary,
  useSubsidiaryDispatch,
} from '../contexts/SubsidiaryContext';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';

interface Values {
  name: string;
  email: string;
  phone: string;
}

interface Props {
  hide: () => void;
}

export default function SubsidiaryPutForm(props: Props) {
  const subsidiary = useSubsidiary();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useSubsidiaryDispatch();
  const { auth } = useAuth();
  const role = auth?.payload?.roles[0];

  const SubsidiarySchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Nom trop court')
      .max(50, 'Nom trop long')
      .required('Veuillez remplir le champ'),
    email: Yup.string()
      .email('Email invalide')
      .required('Veuillez remplir le champ'),
    phone: Yup.string()
      .min(2, 'Votre téléphone doit avoir un minimum de 2 caractères.')
      .max(50, 'Votre téléphone doit avoir un maximum de 50 caractères.')
      .required('Le champ téléphone est obligatoire.'),
  });

  return (
    <Formik
      initialValues={{
        name: subsidiary.name,
        email: subsidiary.email,
        phone: subsidiary.phone,
      }}
      validationSchema={SubsidiarySchema}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        toast
          .promise(
            axiosPrivate.put(subsidiary['@id'], values),
            {
              pending: 'Modification en cours...',
              success: 'Modification réussie.',
              error: 'Une erreur est survenue.',
            },
            { position: toast.POSITION.BOTTOM_CENTER },
          )
          .then(
            (response) => {
              dispatch({ type: 'changed', subsidiary: response.data });
              props.hide();
            },
            (error) => {
              console.log(error);
            },
          )
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched }) => (
        <Form className="flex flex-col gap-y-5">
          {role !== 'ROLE_SUBSIDIARY' && (
            <div>
              <label>Nom</label>
              <Field
                name="name"
                placeholder="Nom"
                className="border px-2 flex items-center h-8"
              />
              {errors.email && touched.email ? (
                <p className="text-sm text-red-700 mt-1">{errors.name}</p>
              ) : null}
            </div>
          )}
          <div>
            <label>Email</label>
            <Field
              name="email"
              placeholder="Email"
              className="border px-2 flex items-center h-8"
            />
            {errors.email && touched.email ? (
              <p className="text-sm text-red-700 mt-1">{errors.email}</p>
            ) : null}
          </div>
          <div>
            <label>Téléphone</label>
            <Field
              name="phone"
              placeholder="Téléphone"
              className="border px-2 flex items-center h-8"
            />
            {errors.phone && touched.phone ? (
              <p className="text-sm text-red-700 mt-1">{errors.phone}</p>
            ) : null}
          </div>
          <button
            type="submit"
            className="px-2 py-1 text-white bg-green-ufe w-full"
          >
            Modifier
          </button>
        </Form>
      )}
    </Formik>
  );
}
