import React from 'react';
import useModal from '../../../components/modals/useModal';
import Modal from '../../../components/modals/Modal';
import AdherentPostForm from '../forms/AdherentPostForm';

export default function AdherentPostButton() {
  const { isShowing, toggle } = useModal();

  return (
    <>
      <button onClick={toggle} className="bg-white border p-2 text-sm">
        Créer un adhérent
      </button>
      <Modal isShowing={isShowing} hide={toggle} title={'Ajouter un adhérent'}>
        <AdherentPostForm hide={toggle} />
      </Modal>
    </>
  );
}
