import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import Loader from '../../../components/Loader';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';

interface Props {
  chosenSubsidiary: Subsidiary | null;
}

const AdherentByMonthChart = (props: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get(
        '/charts/monthlyAdherents' +
          (props.chosenSubsidiary && props.chosenSubsidiary['@id'] !== ''
            ? '?subsidiary=' + props.chosenSubsidiary['@id']
            : ''),
      )
      .then(
        (response) => {
          setData(response.data);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [axiosPrivate, props.chosenSubsidiary]);

  return (
    <div className="p-6 bg-white w-full">
      <h3 className="mb-5">
        Nombre d'adhésions {new Date().getFullYear()} par mois{' '}
        {isLoading && <Loader />}
      </h3>
      <ResponsiveContainer width="100%" height={175}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 30,
            right: 15,
            bottom: -7,
          }}
        >
          <XAxis dataKey="name" tickLine={false} axisLine={false} />
          <YAxis tickLine={false} axisLine={false} allowDecimals={false} />
          <Tooltip />
          <Bar
            isAnimationActive={false}
            name="adhérents"
            dataKey="adherents"
            fill="#539CAD"
            barSize={30}
          >
            <LabelList
              name="adhérents"
              dataKey="adherents"
              fill="#808080"
              position={'top'}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AdherentByMonthChart;
