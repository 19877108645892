import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import {
  useDonationDispatch,
  useDonationItemsPerPage,
  useDonationLinks,
  useDonations,
  useDonationsFetch,
} from '../contexts/DonationProvider';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Donation } from '../types/Donation';
import { toast } from 'react-toastify';

interface Values {
  amount: number;
}

interface Props {
  donation: Donation;
  hide: () => void;
}

export default function DonationPutForm(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDonationDispatch();
  const itemsPerPage = useDonationItemsPerPage();
  const fetchDonations = useDonationsFetch();
  const links = useDonationLinks();
  const donations = useDonations();

  return (
    <Formik
      initialValues={{
        amount: props.donation.amount,
      }}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        toast
          .promise(
            axiosPrivate.put(props.donation['@id'], values),
            {
              pending: 'Création en cours...',
              success: 'Ajoutée avec succès.',
              error: 'Une erreur est survenue.',
            },
            { position: toast.POSITION.BOTTOM_CENTER },
          )
          .then(
            (response) => {
              if (donations.length < itemsPerPage) {
                dispatch({
                  type: 'changed',
                  donation: response.data,
                });
              } else {
                fetchDonations(links?.['@id']);
              }
              props.hide();
            },
            (error) => {
              console.log(error);
            },
          )
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      <Form>
        <div>
          <label>Montant</label>
          <Field
            type="number"
            id="amount"
            name="amount"
            className="border px-2 flex items-center h-8 w-full"
          />
        </div>
        <button
          className="px-2 py-1 text-white bg-green-ufe w-full mt-4"
          type="submit"
        >
          Ajouter
        </button>
      </Form>
    </Formik>
  );
}
