import { Donation } from '../types/Donation';
import Modal from '../../../components/modals/Modal';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import useModal from '../../../components/modals/useModal';
import {
  useDonationDispatch,
  useDonationLinks,
  useDonations,
  useDonationsFetch,
} from '../contexts/DonationProvider';

interface Props {
  donation: Donation;
  closeParent: () => void;
}

export default function DonationDeleteButton(props: Props) {
  const { isShowing, toggle } = useModal();
  const axiosPrivate = useAxiosPrivate();
  const links = useDonationLinks();
  const donations = useDonations();
  const dispatch = useDonationDispatch();
  const fetchDonations = useDonationsFetch();

  return (
    <>
      <button onClick={toggle} className="bg-white border px-2 py-1 mt-5">
        Supprimer
      </button>
      <Modal title={'Supprimer'} isShowing={isShowing} hide={toggle}>
        <div className="flex justify-center">
          <button
            className="bg-red-700 w-60 py-1 text-white"
            onClick={() =>
              toast
                .promise(
                  axiosPrivate.delete(props.donation['@id']),
                  {
                    pending: 'Suppression en cours...',
                    success: 'Supprimé avec succès.',
                    error: 'Une erreur est survenue.',
                  },
                  { position: toast.POSITION.BOTTOM_CENTER },
                )
                .then(
                  () => {
                    if (
                      links?.['@id'] === links?.['hydra:last'] &&
                      donations.length > 1
                    ) {
                      dispatch({
                        type: 'deleted',
                        donation: props.donation,
                      });
                    } else if (
                      links?.['@id'] === links?.['hydra:last'] &&
                      donations.length === 1
                    ) {
                      fetchDonations(links?.['hydra:previous']);
                    } else {
                      fetchDonations(links?.['@id']);
                    }
                    props.closeParent();
                  },
                  (error) => {
                    console.log(error);
                  },
                )
            }
          >
            Supprimer
          </button>
        </div>
      </Modal>
    </>
  );
}
