import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { Adherent } from '../types/Adherent';
import { useAdherentDispatch } from '../contexts/AdherentContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useModal from '../../../components/modals/useModal';
import Modal from '../../../components/modals/Modal';
import PartnerPutForm from './PartnerPutForm';
import { Partner } from '../types/Partner';
import openIcon from '../../../assets/images/openIcon.svg';

interface Values {
  gender: string;
  birthdate: Date | null;
  nationality: string;
}

type Props = {
  adherent: Adherent;
  isEditable: boolean;
  partner: Partner | null;
  setPartner: (partner: Partner | null) => void;
};

const AdherentDetailsPutForm = (props: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const adherentDispatch = useAdherentDispatch();
  const { isShowing, toggle } = useModal();
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  registerLocale('fr', fr);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.adherent?.birthdate) {
      setBirthDate(new Date(props.adherent?.birthdate));
    } else {
      setBirthDate(null);
    }
  }, [props.adherent]);

  const createDate = (dateToTransform: Date) => {
    const date = new Date(dateToTransform);
    return date.toLocaleDateString('fr', { timeZone: 'UTC' });
  };

  const ValidationSchema = Yup.object().shape({
    nationality: Yup.string()
      .min(2, 'Votre nationalité doit avoir un minimum de 2 caractère.')
      .max(50, 'Votre nationalité doit avoir un maximum de 50 caractère.')
      .required('Le champ nationalité est obligatoire.'),
  });

  const deletePartner = () => {
    toast
      .promise(
        axiosPrivate.delete(
          '/partner/deletePartner?adherent=' + props.adherent['@id'],
        ),
        {
          pending: 'Suppression...',
          success: 'Le conjoint a bien été supprimé.',
          error: {
            render(data: any) {
              const message = data.data?.response?.data;
              return message
                ? message
                : "Ce conjoint n'a pas pu être supprimé.";
            },
          },
        },
        { position: toast.POSITION.BOTTOM_CENTER },
      )
      .then(
        () => {
          props.setPartner(null);
        },
        (error) => {
          console.log(error);
        },
      );
  };

  return (
    <>
      <Formik
        initialValues={{
          gender: props.adherent.gender,
          birthdate: birthDate,
          nationality: props.adherent.nationality,
        }}
        validationSchema={ValidationSchema}
        enableReinitialize={true}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>,
        ) => {
          if (birthDate) {
            values.birthdate = birthDate;
          }
          toast
            .promise(
              axiosPrivate.put(props.adherent['@id'], values),
              {
                pending: 'Modification...',
                success: 'Succès.',
                error: 'Une erreur est survenue.',
              },
              { position: toast.POSITION.BOTTOM_CENTER },
            )
            .then((response) => {
              adherentDispatch({
                type: 'changed',
                adherent: response.data,
              });
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ errors, touched }) => (
          <Form className="flex flex-col gap-y-5">
            <div className="flex flex-col">
              <label>Civilité</label>
              <Field
                as="select"
                name="gender"
                placeholder="Civilité"
                disabled={!props.isEditable}
                className="border px-1 flex items-center h-8"
              >
                <option value="Monsieur">Monsieur</option>
                <option value="Madame">Madame</option>
              </Field>
            </div>
            <div className="flex flex-col">
              <label>Date de naissance</label>
              {birthDate ? (
                <DatePicker
                  disabled={!props.isEditable}
                  selected={birthDate}
                  onChange={(date: Date) => setBirthDate(date)}
                  locale="fr"
                  dateFormat="dd-MM-yyyy"
                  className={
                    !props.isEditable
                      ? 'border px-2 flex items-center h-8 w-full bg-white text-neutral-500'
                      : 'border px-2 flex items-center h-8 w-full'
                  }
                />
              ) : !props.isEditable ? (
                <div className="border px-2 flex items-center h-8">
                  Non renseignée
                </div>
              ) : (
                <DatePicker
                  disabled={!props.isEditable}
                  selected={birthDate}
                  onChange={(date: Date) => setBirthDate(date)}
                  locale="fr"
                  dateFormat="dd-MM-yyyy"
                  className={
                    !props.isEditable
                      ? 'border px-2 flex items-center h-8 w-full bg-white text-neutral-500'
                      : 'border px-2 flex items-center h-8 w-full'
                  }
                />
              )}
            </div>
            <div className="flex flex-col">
              <label>Nationalité</label>
              <Field
                as="select"
                id="nationality"
                name="nationality"
                disabled={!props.isEditable}
                placeholder="Nationalité"
                className="border px-1 flex items-center h-8 w-full"
              >
                <option value="FRANCAISE">Française</option>
                <option value="AUTRE">Autre</option>
              </Field>
              {errors.nationality && touched.nationality ? (
                <p className="text-sm text-red-700 mt-1">
                  {errors.nationality}
                </p>
              ) : null}
            </div>
            <div className="flex flex-col">
              <label>Date de création du contact</label>
              <div className="border px-2 flex items-center h-8 bg-gray-200">
                {createDate(props.adherent?.createdAt)}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center mb-2">
                <label>Conjoint</label>
                {props.partner && (
                  <img
                    src={openIcon}
                    alt="open-icon"
                    className="w-4 ml-2"
                    onClick={() => {
                      props.setPartner({
                        number: props.adherent?.id,
                        firstname: props.adherent?.firstname,
                        lastname: props.adherent?.lastname,
                      });
                      navigate('/adherent/' + props.partner?.number);
                    }}
                  />
                )}
                {props.isEditable && (
                  <>
                    <button
                      className="outline outline-2 rounded px-2 ml-auto text-xs cursor-pointer"
                      onClick={toggle}
                      disabled={!props.isEditable}
                      type="button"
                    >
                      Modifier
                    </button>
                    <button
                      className="outline outline-2 rounded px-2 ml-2 text-xs cursor-pointer"
                      onClick={deletePartner}
                      disabled={!props.isEditable}
                      type="button"
                    >
                      Supprimer
                    </button>
                  </>
                )}
              </div>
              <div className="border px-2 flex items-center h-8 bg-gray-200">
                {props.partner
                  ? props.partner.lastname + ' ' + props.partner.firstname
                  : 'Aucun conjoint enregistré'}
              </div>
            </div>
            <div className="flex flex-col">
              <label>Numéro Conjoint</label>
              <div className="border px-2 flex items-center h-8 bg-gray-200">
                {props.partner && props.partner.number}
              </div>
            </div>
            {props.isEditable && (
              <button
                className="px-2 py-1 text-white bg-green-ufe"
                type="submit"
              >
                Mettre à jour
              </button>
            )}
          </Form>
        )}
      </Formik>
      <Modal isShowing={isShowing} hide={toggle} title={'Ajouter un conjoint'}>
        <PartnerPutForm
          hide={toggle}
          partner={props.partner}
          setPartner={props.setPartner}
          subsidiary={props.adherent.lastMembership?.subsidiary}
          adherent={props.adherent}
        />
      </Modal>
    </>
  );
};

export default AdherentDetailsPutForm;
