import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useSubsidiary } from '../../Subsidiary/contexts/SubsidiaryContext';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import Button from '../../../components/Button';
import { usePriceStore } from '../usePriceStore';
import { toast, ToastContentProps } from 'react-toastify';
import { AxiosError } from 'axios';
import { ErrorHydra } from '../../../types/ErrorHydra';

interface Values {
  year: number;
  type: string;
  siegeAmount: number;
  localAmount: number;
  subsidiary: string;
}

interface Props {
  hide: () => void;
}

export default function PricePostForm(props: Props) {
  const subsidiary = useSubsidiary();
  const axiosPrivate = useAxiosPrivate();
  const addPrice = usePriceStore((state) => state.addPrice);

  const PriceSchema = Yup.object().shape({
    siegeAmount: Yup.number().required('Veuillez remplir le champ'),
    localAmount: Yup.number().required('Veuillez remplir le champ'),
  });

  return (
    <Formik
      initialValues={{
        year: 0,
        type: '',
        siegeAmount: 0,
        localAmount: 0,
        subsidiary: subsidiary ? subsidiary['@id'] : '',
      }}
      validationSchema={PriceSchema}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        values.year = parseInt(values.year.toString());
        toast
          .promise(
            axiosPrivate.post('/prices', values),
            {
              pending: 'Création en cours...',
              success: 'Barème ajouté.',
              error: {
                render(data: ToastContentProps<AxiosError>) {
                  const value = data.data?.response?.data as ErrorHydra;
                  return value['hydra:description']
                    ? value['hydra:description']
                    : 'Une erreur est survenue.';
                },
              },
            },
            { position: toast.POSITION.BOTTOM_CENTER },
          )
          .then(
            (response) => {
              addPrice(response.data);
              props.hide();
            },
            (error) => {
              console.log(error);
            },
          )
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched }) => (
        <Form className="flex flex-col text-sm space-y-4">
          <div className="flex flex-col">
            <label>Année</label>
            <Field
              as="select"
              id="year"
              name="year"
              placeholder="Année"
              className="border px-2 flex items-center h-8 w-full"
            >
              <option value="0">---</option>
              <option value="2017">2017</option>
              <option value="2018">2018</option>
              <option value="2019">2019</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </Field>
          </div>
          <div className="flex flex-col">
            <label>Type de barème</label>
            <Field
              as="select"
              id="type"
              name="type"
              placeholder="Type de barème"
              className="border px-2 flex items-center h-8 w-full"
            >
              <option value="">---</option>
              <option value="NORMAL CONJOINT">NORMAL CONJOINT</option>
              <option value="NORMAL PARTICULIER">NORMAL PARTICULIER</option>
              <option value="JUNIOR CONJOINT">JUNIOR CONJOINT</option>
              <option value="JUNIOR PARTICULIER">JUNIOR PARTICULIER</option>
              <option value="SENIOR PARTICULIER">SENIOR PARTICULIER</option>
              <option value="SENIOR CONJOINT">SENIOR CONJOINT</option>
              <option value="AUTRE PARTICULIER">AUTRE PARTICULIER</option>
              <option value="AUTRE CONJOINT">AUTRE CONJOINT</option>
              <option value="AXA PARTICULIER">AXA PARTICULIER</option>
              <option value="AXA CONJOINT">AXA CONJOINT</option>
            </Field>
          </div>
          <div className="flex flex-col">
            <label>Part siège</label>
            <Field
              id="siegeAmount"
              name="siegeAmount"
              placeholder="0"
              type="number"
              className="border px-2 flex items-center h-8"
            />
            {errors.siegeAmount && touched.siegeAmount ? (
              <p className="modal-error">{errors.siegeAmount}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label>Part locale</label>
            <Field
              id="localAmount"
              name="localAmount"
              placeholder="0"
              type="number"
              className="border px-2 flex items-center h-8"
            />
            {errors.localAmount && touched.localAmount ? (
              <p className="modal-error">{errors.localAmount}</p>
            ) : null}
          </div>
          <Button text={'Créer'} />
        </Form>
      )}
    </Formik>
  );
}
