import Modal from '../../../components/modals/Modal';
import useModal from '../../../components/modals/useModal';
import { Adherent } from '../../Adherent/types/Adherent';
import MembershipPostForm from '../forms/MembershipPostForm';

interface Props {
  adherent: Adherent;
}

export default function MembershipPostButton(props: Props) {
  const { isShowing, toggle } = useModal();

  return (
    <>
      <button onClick={toggle} className="bg-white border px-2 py-1">
        Ajouter une adhésion
      </button>
      <Modal isShowing={isShowing} hide={toggle} title={'Ajouter une adhésion'}>
        <MembershipPostForm hide={toggle} adherent={props.adherent} />
      </Modal>
    </>
  );
}
