import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

export default function PublicLayout() {
  return (
    <div className="min-h-screen flex justify-center items-center">
      <Outlet />
      <ToastContainer />
    </div>
  );
}
