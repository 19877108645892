import { Adherent } from '../../Adherent/types/Adherent';
import { Donation } from '../types/Donation';
import DonationActionButton from '../buttons/DonationActionButton';

interface Props {
  adherent: Adherent;
  donation: Donation;
}

export default function DonationItem(props: Props) {
  const createDate = (dateToTransform: string | number | Date) => {
    const date = new Date(dateToTransform);
    const [month, day, year] = [
      date.getMonth() > 9 ? date.getMonth() : '0' + date.getMonth(),
      date.getDate() > 9 ? date.getDate() : '0' + date.getDate(),
      date.getFullYear(),
    ];
    return day + '/' + month + '/' + year;
  };

  return (
    <tr>
      <td className="p-2">{props.donation.amount}</td>
      <td className="p-2">{createDate(props.donation.date)}</td>
      <td className="p-2">
        <DonationActionButton donation={props.donation} />
      </td>
    </tr>
  );
}
