import { ChangeEvent, useState } from 'react';
import useModal from '../../../components/modals/useModal';
import importIcon from '../../../assets/images/import.svg';
import xlsxModelFileSubsidiary from '../../../assets/files/xlsx/model_import_adherents_1.xlsx';
import xlsxModelFileSiege from '../../../assets/files/xlsx/model_import_adherents_0.xlsx';
import xlsxModelFileCards from '../../../assets/files/xlsx/model_import_cards.xlsx';
import Modal from '../../../components/modals/Modal';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { useFetchAdherents } from '../contexts/AdherentCollectionContext';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';
import { useUser } from '../../User/contexts/UserContextProvider';

export default function AdherentImportButton() {
  const axiosPrivate = useAxiosPrivate();
  const { isShowing, toggle } = useModal();
  const [importTarget, setImportTarget] = useState<string>('adherents');
  const { auth } = useAuth();
  const role = auth?.payload?.roles[0];
  const fetchAdherents = useFetchAdherents();
  const user = useUser();
  const selectedSubsidiaryName =
    role === 'ROLE_SUBSIDIARY' && user?.subsidiary && user?.subsidiary.name
      ? user.subsidiary.name
      : '';

  const uploadFile = () => {
    const fileInput = document.getElementById('file-input');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      // Vérification de l'extension du fichier.
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop()?.toLowerCase();
      if (fileExtension !== 'xlsx') {
        toast.error(
          'Veuillez télécharger et utiliser le modèle XLSX au bon format.',
          {
            position: toast.POSITION.BOTTOM_CENTER,
          },
        );
        return;
      }

      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        const result = readerEvent.target && readerEvent.target.result;
        if (result && typeof result !== 'string') {
          const data = new Uint8Array(result);
          const workbook = XLSX.read(data, { type: 'array' });

          // 1er page du fichier Excel ; Adherent.
          const worksheetModel = workbook.Sheets[workbook.SheetNames[0]];
          // TODO : Modifier les entiers du tableau, pour les dates, s'il y a un changement dans les colonnes du model Excel adherents.
          const dateIndexSheet: number[] = [5, 16, 17];
          const modelJsonData = getConvertedJsonData(
            worksheetModel,
            dateIndexSheet,
          );

          if (!modelJsonData || modelJsonData.length === 0) {
            toast.error('Aucune donnée à importer.', {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            return;
          }

          // Filtrage des lignes vides.
          const filteredModelJsonData = modelJsonData.filter((row) => {
            if (row.length > 0) {
              return row;
            }
          });

          // Importer les données.
          importData(filteredModelJsonData);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  function getConvertedJsonData(
    worksheet: XLSX.WorkSheet,
    dateTimeIndexSheet: number[],
  ) {
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    if (jsonData.length === 0) {
      toast.error('Aucune donnée à importer.', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    // Convertir les valeurs de numéro de série généré par SheetJS pour les dates en Date/string.
    return jsonData.map((row: any) => {
      return row.map((value: string | number, columnIndex: number) => {
        // Conversion si type number et si l'index de la colonne correspond à une date.
        if (
          typeof value === 'number' &&
          dateTimeIndexSheet.includes(columnIndex)
        ) {
          return convertSerialNumberToDate(value);
        }
        return value;
      });
    });
  }

  function convertSerialNumberToDate(serialNumber: number) {
    const excelDate = Math.floor((serialNumber - 25569) * 86400 * 1000);
    const date = new Date(excelDate);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  const importData = (jsonData: any) => {
    toast
      .promise(
        axiosPrivate.post(
          '/import?target=' +
            importTarget +
            '&subsidiary=' +
            selectedSubsidiaryName,
          jsonData,
        ),
        {
          pending: 'Import en cours...',
          success: 'Import réussi.',
          error: {
            render(data: any) {
              const message = data.data?.response?.data;
              return message
                ? message
                : "Une erreur est survenue lors de l'import. Veuillez vérifier les champs de votre fichier.";
            },
          },
        },
        { position: toast.POSITION.BOTTOM_CENTER },
      )
      .then(
        () => {
          fetchAdherents();
          toggle();
        },
        (error) => {
          console.log(error);
        },
      );
  };

  const downloadModelFile = () => {
    const link = document.createElement('a');
    if (importTarget === 'adherents') {
      link.href =
        role === 'ROLE_SUBSIDIARY'
          ? xlsxModelFileSubsidiary
          : xlsxModelFileSiege;
      link.download =
        role === 'ROLE_SUBSIDIARY'
          ? 'model_import_adherents_1.xlsx'
          : 'model_import_adherents_0.xlsx';
    } else {
      link.href = xlsxModelFileCards;
      link.download = 'model_import_cards.xlsx';
    }
    link.click();
  };

  const importTypeSelection = (element: ChangeEvent<HTMLInputElement>) => {
    setImportTarget(element.target.id);
  };

  return (
    <>
      <button
        className="bg-white border p-2 flex items-center text-sm"
        onClick={toggle}
      >
        <img alt="import-button-icon" src={importIcon} className="mr-2" />
        Importer
      </button>

      <Modal isShowing={isShowing} hide={toggle} title={'Importer des données'}>
        <div className="flex flex-col gap-y-4 max-w-md">
          {role !== 'ROLE_SUBSIDIARY' && (
            <div>
              <p className="text-justify text-sm mb-2">
                Veuillez sélectionner le type de données à importer :
              </p>
              <input
                type="radio"
                id="adherents"
                name="import-type"
                onChange={importTypeSelection}
              />
              <label htmlFor="adherents" className="mr-2 ml-1">
                Adhérents
              </label>
              <input
                type="radio"
                id="cards"
                name="import-type"
                onChange={importTypeSelection}
              />
              <label htmlFor="cards" className="ml-1">
                Cartes
              </label>
            </div>
          )}
          <p className="text-justify text-sm">
            Nous mettons à votre disposition un modèle de fichier afin de
            faciliter l'importation des données de manière optimale.
          </p>
          <p className="text-justify text-sm">
            Veuillez remplir ce modèle avec les informations requises, puis
            sélectionnez-le sur votre ordinateur pour l'importer.
          </p>
          <p className="text-justify text-sm">
            Par mesure de sécurité et de confidentialité, veuillez supprimer le
            modèle rempli une fois que vous avez terminé.
          </p>
          <input
            type="file"
            id="file-input"
            style={{ display: 'none' }}
            onChange={handleFileUpload}
          />
          <button
            className="bg-white border p-2 text-sm w-full"
            onClick={downloadModelFile}
          >
            Télécharger le modèle
          </button>
          <button
            className="bg-white border p-2 text-sm w-full"
            onClick={uploadFile}
          >
            Sélectionner le fichier à importer
          </button>
        </div>
      </Modal>
    </>
  );
}
