import SubsidiaryNotePutForm from '../forms/SubsidiaryNotePutForm';
import { useSelectedSubsidiaryNote } from '../contexts/SubsidiaryNoteCollectionContext';
import SubsidiaryNoteDeleteButton from '../buttons/SubsidiaryNoteDeleteButton';

export default function SelectedSubsidiaryNoteItem() {
  const selectedSubsidiaryNote = useSelectedSubsidiaryNote();

  return (
    <>
      <div className="flex justify-between mb-5">
        <h3 className="font-bold">
          {selectedSubsidiaryNote?.author
            ? selectedSubsidiaryNote?.author?.firstname +
              ' ' +
              selectedSubsidiaryNote?.author?.lastname
            : 'Administrateur'}
        </h3>
        {selectedSubsidiaryNote && (
          <SubsidiaryNoteDeleteButton subsidiaryNote={selectedSubsidiaryNote} />
        )}
      </div>
      {selectedSubsidiaryNote && (
        <SubsidiaryNotePutForm subsidiaryNote={selectedSubsidiaryNote} />
      )}
    </>
  );
}
