import Modal from '../../../components/modals/Modal';
import SubsidiaryNotePostForm from '../forms/SubsidiaryNotePostForm';
import useModal from '../../../components/modals/useModal';

export default function SubsidiaryNotePostButton() {
  const { isShowing, toggle } = useModal();
  return (
    <>
      <button
        type="button"
        className="px-2 py-1 text-white bg-green-ufe"
        onClick={toggle}
      >
        Nouveau
      </button>
      <Modal
        isShowing={isShowing}
        hide={toggle}
        title={'Ajouter une nouvelle note'}
      >
        <SubsidiaryNotePostForm hide={toggle} />
      </Modal>
    </>
  );
}
