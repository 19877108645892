import { Subsidiary } from '../types/Subsidiary';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { useParams } from 'react-router-dom';

export type Action =
  | { type: 'loaded'; subsidiary: Subsidiary }
  | { type: 'changed'; subsidiary: Subsidiary };

export type SubsidiaryDispatch = (action: Action) => void;

type SubsidiaryStateProps = {
  subsidiary: Subsidiary;
};

type SubsidiaryDisptachContentProps = {
  dispatch: SubsidiaryDispatch;
};

const SubsidiaryStateContext = createContext<SubsidiaryStateProps | undefined>(
  undefined,
);

const SubsidiaryDisptachContext = createContext<
  SubsidiaryDisptachContentProps | undefined
>(undefined);

const subsidiaryReducer = (subsidiary: Subsidiary, action: Action) => {
  switch (action.type) {
    case 'loaded':
      return action.subsidiary;
    case 'changed':
      return action.subsidiary;
    default:
      throw new Error(`Unhandled action type`);
  }
};

interface Props {
  children: ReactNode;
}

export default function SubsidiaryContext(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const { subsidiaryId } = useParams();
  const [subsidiary, dispatch] = useReducer(
    subsidiaryReducer,
    {} as Subsidiary,
  );

  const fetchSubsidiary = useCallback(async () => {
    try {
      const response = await axiosPrivate.get(`/subsidiaries/${subsidiaryId}`);
      dispatch({ type: 'loaded', subsidiary: response.data });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchSubsidiary();
  }, [fetchSubsidiary]);

  return (
    <SubsidiaryStateContext.Provider value={{ subsidiary }}>
      <SubsidiaryDisptachContext.Provider value={{ dispatch }}>
        {props.children}
      </SubsidiaryDisptachContext.Provider>
    </SubsidiaryStateContext.Provider>
  );
}

export function useSubsidiary() {
  const context = useContext(SubsidiaryStateContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiary must be used within a SubsidiaryStateContext',
    );
  }
  return context.subsidiary;
}

export function useSubsidiaryDispatch() {
  const context = useContext(SubsidiaryDisptachContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiaryDispatch must be used within a SubsidiaryDisptachContext',
    );
  }
  return context.dispatch;
}
