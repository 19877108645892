import { Field, Form, Formik, FormikHelpers } from 'formik';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import React, { useState } from 'react';
import {
  useEmployees,
  useEmployeesDispatch,
} from '../contexts/EmployeeCollectionContext';
import { PostEmployeeData } from '../types/PostEmployeeData';
import { useSubsidiary } from '../../Subsidiary/contexts/SubsidiaryContext';
import { toast } from 'react-toastify';
import { AdherentSearch } from '../../Adherent/types/AdherentSearch';
import { Employee } from '../types/Employee';

interface Props {
  hide: () => void;
}

export default function EmployeePostForm(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const [keyword, setKeyword] = useState('');
  const [adherent, setAdherent] = useState<AdherentSearch>();
  const [adherents, setAdherents] = useState<AdherentSearch[]>([]);
  const employees = useEmployees();
  const subsidiary = useSubsidiary();
  const dispatch = useEmployeesDispatch();

  const searchAdherent = () => {
    if (subsidiary) {
      axiosPrivate
        .get(
          '/search/adherents?partnerSearch=false' +
            (subsidiary['@id'] ? '&subsidiary=' + subsidiary['@id'] : '') +
            (keyword ? '&lastname=' + keyword : ''),
        )
        .then(
          (response) => {
            let adherentsList: AdherentSearch[] = response.data;
            employees.map((employee: Employee) => {
              return (adherentsList = adherentsList.filter(
                (adherent) =>
                  '/adherents/' + adherent.id !== employee.adherent['@id'],
              ));
            });
            setAdherents(adherentsList);
          },
          (error) => {
            console.log(error);
          },
        );
    }
  };

  const selectedAdherent = (adherent: AdherentSearch) => {
    setAdherent(adherent);
  };

  const searchPressKeyboard = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      searchAdherent();
    }
  };

  return (
    <Formik
      initialValues={{
        title: 'Président',
        subsidiary: subsidiary ? subsidiary['@id'] : '',
        adherent: '',
      }}
      onSubmit={(
        values: PostEmployeeData,
        { setSubmitting }: FormikHelpers<PostEmployeeData>,
      ) => {
        if (adherent) {
          values.adherent = '/adherents/' + adherent.id;
          toast
            .promise(
              axiosPrivate.post('/employees', values),
              {
                pending: 'Création...',
                success: 'Succès.',
                error: 'Ce rôle est déjà rempli par un adhérent.',
              },
              { position: toast.POSITION.BOTTOM_CENTER },
            )
            .then(
              (response) => {
                dispatch({ type: 'added', employee: response.data });
                props.hide();
              },
              (error) => {
                console.log(error);
              },
            )
            .finally(() => {
              setSubmitting(false);
            });
        }
      }}
    >
      <Form className="modal-form">
        {adherent ? (
          <>
            <div className="flex flex-col">
              <Field
                as="select"
                id="title"
                name="title"
                placeholder="Role"
                className="border px-2 flex items-center h-8 w-full"
              >
                <option value="Président">Président</option>
                <option value="1er Vice-Président">1er Vice-Président</option>
                <option value="2ème Vice-Président">2ème Vice-Président</option>
                <option value="3ème Vice-Président">3ème Vice-Président</option>
                <option value="Trésorier">Trésorier</option>
                <option value="Trésorier adjoint">Trésorier adjoint</option>
                <option value="Secrétaire général">Secrétaire général</option>
                <option value="Secrétaire adjoint">Secrétaire adjoint</option>
                <option value="Chargé de mission">Chargé de mission</option>
                <option value="Correspondant">Correspondant</option>
              </Field>
            </div>
            <button
              className="px-2 py-1 mt-4 text-white bg-green-ufe w-full"
              type="submit"
            >
              Créer
            </button>
          </>
        ) : (
          <div className="flex flex-col">
            <input
              type="text"
              placeholder="Recherchez un adhérent"
              className="border px-2 flex items-center h-8"
              value={keyword}
              onKeyUp={(event) => {
                searchPressKeyboard(event);
              }}
              onChange={(event) => {
                setKeyword(event.target.value);
              }}
            />
            <button
              type="button"
              className="px-2 py-1 text-white bg-green-ufe mt-4 mb-4"
              onClick={searchAdherent}
            >
              Recherche
            </button>
          </div>
        )}

        {adherents && adherents.length !== 0 && !adherent ? (
          <div className="w-100 h-80 overflow-y-auto">
            <h4>Veuillez sélectionner un adhérent</h4>
            {adherents &&
              adherents.map((adherent, key) => {
                return (
                  <div
                    className="flex justify-between items-center mt-3 px-2"
                    key={key}
                  >
                    <p className="mr-5">
                      {adherent.lastname} {adherent.firstname}
                    </p>
                    <button
                      type="button"
                      className="px-2 py-1 text-white bg-green-ufe"
                      onClick={() => selectedAdherent(adherent)}
                    >
                      Sélectionner
                    </button>
                  </div>
                );
              })}
          </div>
        ) : null}
      </Form>
    </Formik>
  );
}
