import { Adherent } from '../../Adherent/types/Adherent';
import { Membership } from '../types/Membership';
import MembershipActionButton from '../buttons/MembershipActionButton';

type Props = {
  adherent: Adherent;
  membership: Membership;
};

export default function MembershipItem(props: Props) {
  const currentDate = new Date();
  const endDate = new Date(props.membership.endAt);
  const year = new Intl.DateTimeFormat('fr', {
    year: 'numeric',
    timeZone: 'UTC',
  }).format(endDate);
  const isActive = currentDate < endDate;

  return (
    <tr>
      <td className="pointer p-2">{props.membership.priceType}</td>
      <td className="pointer p-2">{props.membership.localAmount}</td>
      <td className="pointer p-2">{props.membership.siegeAmount}</td>
      <td className="pointer p-2">
        {props.membership.localAmount + props.membership.siegeAmount}
      </td>
      <td className="pointer p-2">{props.membership.origin}</td>
      <td className="pointer p-2">{props.membership.subsidiary?.name}</td>
      <td className="pointer p-2">{endDate && year}</td>
      <td className="pointer p-2">
        <div
          className={
            isActive
              ? 'px-2 text-center bg-green-400'
              : 'px-2 text-center bg-red-400'
          }
        >
          {isActive ? 'Active' : 'Inactive'}
        </div>
      </td>
      <td className="p-2">
        <MembershipActionButton membership={props.membership} />
      </td>
    </tr>
  );
}
