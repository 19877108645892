import { Field, Form, Formik, FormikHelpers } from 'formik';
import { User } from '../types/User';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { useUsersDispatch } from '../contexts/UserCollectionContext';

interface Values {
  firstname: string;
  lastname: string;
  email: string;
  plainPassword: string;
}

interface Props {
  user: User;
  closeParent: () => void;
}

export default function UserPutForm(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useUsersDispatch();

  const UserSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Veuillez remplir le champ'),
    lastname: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Veuillez remplir le champ'),
    email: Yup.string()
      .email('Email invalide')
      .required('Veuillez remplir le champ'),
    plainPassword: Yup.string()
      .min(6, 'Le mot de passe doit contenir au moins 6 caractères')
      .max(12, 'Le mot de passe doit contenir au plus 12 caractères')
      .required('Veuillez remplir le champ'),
  });

  return (
    <Formik
      initialValues={{
        firstname: props.user.firstname,
        lastname: props.user.lastname,
        email: props.user.email,
        plainPassword: '',
      }}
      validationSchema={UserSchema}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        setSubmitting(true);
        toast
          .promise(
            axiosPrivate.put(props.user['@id'], values),
            {
              pending: 'Création...',
              success: 'Succès.',
              error: 'Une erreur est survenue.',
            },
            { position: toast.POSITION.BOTTOM_CENTER },
          )
          .then(
            (response) => {
              dispatch({ type: 'changed', user: response.data });
              props.closeParent();
            },
            (error) => {
              console.log(error);
            },
          )
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched }) => (
        <Form className="modal-form">
          <div className="flex flex-col w-80">
            <div className="flex flex-col mb-4">
              <label>Nom</label>
              <Field
                id="lastname"
                name="lastname"
                placeholder="Nom"
                type="text"
                className="border px-2 flex items-center h-8"
              />
              {errors.lastname && touched.lastname ? (
                <p className="text-sm text-red-700 mt-1">{errors.lastname}</p>
              ) : null}
            </div>
            <div className="flex flex-col mb-4">
              <label>Prénom</label>
              <Field
                id="firstname"
                name="firstname"
                placeholder="Prénom"
                type="text"
                className="border px-2 flex items-center h-8"
              />
              {errors.firstname && touched.firstname ? (
                <p className="text-sm text-red-700 mt-1">{errors.firstname}</p>
              ) : null}
            </div>
            <div className="flex flex-col mb-4">
              <label>Email</label>
              <Field
                id="email"
                name="email"
                placeholder="Email"
                type="email"
                className="border px-2 flex items-center h-8"
              />
              {errors.email && touched.email ? (
                <p className="text-sm text-red-700 mt-1">{errors.email}</p>
              ) : null}
            </div>
            <div className="flex flex-col mb-4">
              <label>Nouveau mot de passe</label>
              <Field
                id="plainPassword"
                name="plainPassword"
                type="text"
                className="border px-2 flex items-center h-8"
              />
              {errors.plainPassword && touched.plainPassword ? (
                <p className="text-sm text-red-700 mt-1">
                  {errors.plainPassword}
                </p>
              ) : null}
            </div>
          </div>
          <button
            type="submit"
            className="px-2 py-1 text-white bg-green-ufe w-full"
          >
            Modifier
          </button>
        </Form>
      )}
    </Formik>
  );
}
