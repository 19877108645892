import React, { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import Button from '../../../components/Button';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import EmailFilter from '../../../components/filters/EmailFilter';
import { useSubsidiaryFilterStore } from '../useSubsidiaryFilterStore';
import NameFilter from '../../../components/filters/NameFilter';

registerLocale('fr', fr);

interface Values {
  name: string;
  email: string;
}

const ValidationSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Vous devez fournir au minimum 2 caractère.'),
  email: Yup.string().min(2, 'Vous devez fournir au minimum 2 caractère.'),
});

export default function SubsidiaryFilterForm() {
  const name = useSubsidiaryFilterStore((state) => state.name);
  const setName = useSubsidiaryFilterStore((state) => state.setName);
  const email = useSubsidiaryFilterStore((state) => state.email);
  const setEmail = useSubsidiaryFilterStore((state) => state.setEmail);

  const [isRotating, setIsRotating] = useState(false);
  const resetFilters = useSubsidiaryFilterStore((state) => state.reset);

  const handleResetClick = () => {
    setIsRotating(true);
    resetFilters();
    setTimeout(() => {
      setIsRotating(false);
    }, 1000);
  };

  return (
    <Formik
      initialValues={{
        name: name,
        email: email,
      }}
      validationSchema={ValidationSchema}
      enableReinitialize={true}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        setName(values.name);
        setEmail(values.email);
        setSubmitting(false);
      }}
    >
      {({ errors, touched }) => (
        <Form className="flex flex-col space-y-2">
          <div className="flex justify-between">
            <h3 className="font-bold">Filtres</h3>
            <ArrowPathIcon
              className={`h-6 w-6 fill-green-ufe cursor-pointer ${
                isRotating ? 'animate-spin' : ''
              }`}
              onClick={handleResetClick}
            />
          </div>
          <Button text={'Lancer la recherche'} />
          <NameFilter errors={errors.name} touched={touched.name} />
          <EmailFilter errors={errors.email} touched={touched.email} />
        </Form>
      )}
    </Formik>
  );
}
