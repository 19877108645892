import * as Yup from 'yup';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import {
  useGetSubsidiaryCollection,
  useSubsidiaries,
  useSubsidiariesDispatch,
  useSubsidiariesItemsPerPage,
  useSubsidiariesLinks,
} from '../contexts/SubsidiaryCollectionContext';

interface Values {
  name: string;
  email: string;
  phone: string;
  address: {
    country: string;
  };
}

interface Props {
  hide: () => void;
}

export default function SubsidiaryPostForm(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const links = useSubsidiariesLinks();
  const subsidiaries = useSubsidiaries();
  const dispatch = useSubsidiariesDispatch();
  const getSubsidiaryCollection = useGetSubsidiaryCollection();
  const itemsPerPage = useSubsidiariesItemsPerPage();

  const SubsidiarySchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Nom trop court')
      .max(50, 'Nom trop long')
      .required('Veuillez remplir le champ'),
    email: Yup.string().email('Email invalide'),
    phone: Yup.string()
      .min(2, 'Votre téléphone doit avoir un minimum de 2 caractères.')
      .max(50, 'Votre téléphone doit avoir un maximum de 50 caractères.'),
    address: Yup.object().shape({
      country: Yup.string()
        .min(2, 'Pays doit avoir un minimum de 2 caractère.')
        .max(50, 'Pays doit avoir un maximum de 50 caractère.'),
    }),
  });

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        phone: '',
        address: {
          country: '',
        },
      }}
      validationSchema={SubsidiarySchema}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        toast
          .promise(
            axiosPrivate.post('/subsidiaries', values),
            {
              pending: 'Création en cours...',
              success: 'Représentation créée.',
              error: 'Une erreur est survenue.',
            },
            { position: toast.POSITION.BOTTOM_CENTER },
          )
          .then(
            (response) => {
              if (subsidiaries.length < itemsPerPage) {
                dispatch({ type: 'added', subsidiary: response.data });
              } else {
                getSubsidiaryCollection(links?.['@id']);
              }
              props.hide();
            },
            (error) => {
              console.log(error);
            },
          )
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched }) => (
        <Form className="modal-form">
          <div className="flex flex-col">
            <div className="flex flex-col mb-4">
              <label>Nom</label>
              <Field
                id="name"
                name="name"
                placeholder="Nom"
                type="text"
                className="border px-2 flex items-center h-8"
              />
              {errors.name && touched.name ? (
                <p className="text-sm text-red-700 mt-1">{errors.name}</p>
              ) : null}
            </div>
            <div className="flex flex-col mb-4">
              <label>Email</label>
              <Field
                id="email"
                name="email"
                placeholder="Email"
                className="border px-2 flex items-center h-8"
              />
              {errors.email && touched.email ? (
                <p className="text-sm text-red-700 mt-1">{errors.email}</p>
              ) : null}
            </div>
            <div className="flex flex-col mb-4">
              <label>Téléphone</label>
              <Field
                id="phone"
                name="phone"
                placeholder="Téléphone"
                className="border px-2 flex items-center h-8"
              />
              {errors.phone && touched.phone ? (
                <p className="text-sm text-red-700 mt-1">{errors.phone}</p>
              ) : null}
            </div>
            <div className="flex flex-col mb-4">
              <label>Pays</label>
              <Field
                name="address.country"
                placeholder="Pays"
                type="text"
                className="border px-2 flex items-center h-8"
              />
              {errors.address?.country && touched.address?.country ? (
                <p className="text-sm text-red-700 mt-1">
                  {errors.address?.country}{' '}
                </p>
              ) : null}
            </div>
          </div>
          <button
            type="submit"
            className="px-2 py-1 text-white bg-green-ufe w-full"
          >
            Créer
          </button>
        </Form>
      )}
    </Formik>
  );
}
