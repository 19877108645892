import { User } from '../types/User';
import UserActionButton from '../buttons/UserActionButton';

type Props = {
  user: User;
};

export default function UserItem(props: Props) {
  return (
    <tr>
      <td className="pointer p-2">{props.user.lastname}</td>
      <td className="pointer p-2">{props.user.firstname}</td>
      <td className="pointer p-2">{props.user.email}</td>
      <td className="pointer p-2">
        {props.user.subsidiary ? props.user.subsidiary.name : 'Administrateur'}
      </td>
      <td className="pointer p-2">
        {props.user.roles.includes('ROLE_ADMIN') ? 'Administrateur' : null}
        {props.user.roles.includes('ROLE_SIEGE') ? 'Siège' : null}
        {props.user.roles.includes('ROLE_SUBSIDIARY') ? 'Représentation' : null}
        {props.user.roles.includes('ROLE_ADHERENT') ? 'Adhérent' : null}
      </td>
      <td className="p-2 flex justify-end">
        <UserActionButton user={props.user} />
      </td>
    </tr>
  );
}
