import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';

interface Props {
  chosenSubsidiary: Subsidiary | null;
}

interface Data {
  juniorParticulierMemberships: number;
  juniorConjointMemberships: number;
}

const JuniorMembershipChart = (props: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState<Data>({
    juniorParticulierMemberships: 0,
    juniorConjointMemberships: 0,
  });

  useEffect(() => {
    axiosPrivate
      .get(
        '/financiary/juniorMemberships' +
          (props.chosenSubsidiary && props.chosenSubsidiary['@id'] !== ''
            ? '?subsidiary=' + props.chosenSubsidiary['@id']
            : ''),
      )
      .then(
        (response) => {
          setData(response?.data);
        },
        (error) => {
          console.log(error);
        },
      );
  }, [axiosPrivate, props.chosenSubsidiary]);

  return (
    <div className="p-6 bg-white w-full">
      <h3>Nombre de cotisation junior {new Date().getFullYear()}</h3>
      <div className="mt-5">
        {data?.juniorParticulierMemberships + data?.juniorConjointMemberships}{' '}
        dont {data?.juniorConjointMemberships} conjoints
      </div>
    </div>
  );
};

export default JuniorMembershipChart;
