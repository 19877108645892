import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';

registerLocale('fr', fr);

interface Props {
  membershipStatus: string;
  membershipsAfterYear: string;
  setMembershipsBeforeYear: (membershipsBeforeYear: string) => void;
  setMembershipsAfterYear: (membershipsAfterYear: string) => void;
  setLastMembershipBeforeYear: (lastMembershipBeforeYear: string) => void;
  setLastMembershipAfterYear: (lastMembershipAfterYear: string) => void;
}

export default function YearFilter(props: Props) {
  function handleDateChange(year: string) {
    const currentYearString = new Date().getFullYear().toString();
    if (year === '') {
      props.setMembershipsBeforeYear('');
      props.setMembershipsAfterYear('');
    } else if (
      year === currentYearString &&
      props.membershipStatus === 'expired'
    ) {
      const beforeYear = parseInt(year) + 1 + '-01-01';
      const afterYear = parseInt(year) + '-01-01';
      props.setMembershipsBeforeYear(beforeYear);
      props.setMembershipsAfterYear(afterYear);
      const beforeYear1 = parseInt(year) + 1 + '-01-01';
      const afterYear2 = parseInt(year) + '-01-01';
      props.setLastMembershipBeforeYear(beforeYear1);
      props.setLastMembershipAfterYear(afterYear2);
    } else {
      const beforeYear = parseInt(year) + 1 + '-01-01';
      const afterYear = parseInt(year) + '-01-01';
      props.setMembershipsBeforeYear(beforeYear);
      props.setMembershipsAfterYear(afterYear);
    }
  }

  return (
    <div>
      <label className="text-sm">Année d'adhésion</label>
      <select
        name="yearDate"
        className="w-full border"
        value={props.membershipsAfterYear.split('-')[0]}
        onChange={(e) => {
          handleDateChange(e.target.value);
        }}
      >
        <option value="">---</option>
        <option value="2017">2017</option>
        <option value="2018">2018</option>
        <option value="2019">2019</option>
        <option value="2020">2020</option>
        <option value="2021">2021</option>
        <option value="2022">2022</option>
        <option value="2023">2023</option>
        <option value="2024">2024</option>
      </select>
    </div>
  );
}
