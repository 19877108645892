import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { User } from '../types/User';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { Links } from '../../../types/Links';
import { useUserFilterStore } from '../useUserFilterStore';

type Action =
  | { type: 'loaded'; users: User[] }
  | { type: 'added'; user: User }
  | { type: 'changed'; user: User }
  | { type: 'deleted'; user: User };

type Dispatch = (action: Action) => void;

type UsersStateContentProps = {
  users: User[];
  links?: Links;
  totalItems: number;
  itemsPerPage: number;
  isLoading: boolean;
};

type UsersDispatchContentProps = {
  dispatch: Dispatch;
  setTotalItems: (totalItems: number) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
  fetchUsers: (url?: string) => void;
};

const UsersStateContext = createContext<UsersStateContentProps | undefined>(
  undefined,
);

const UsersDispatchContext = createContext<
  UsersDispatchContentProps | undefined
>(undefined);

const usersReducer = (users: User[], action: Action) => {
  switch (action.type) {
    case 'loaded':
      return action.users;
    case 'added':
      return [...users, action.user];
    case 'changed':
      return users.map((user) => {
        if (user['@id'] === action.user['@id']) {
          return action.user;
        }
        return user;
      });
    case 'deleted':
      return users.filter((user) => user['@id'] !== action.user['@id']);
    default:
      throw new Error('Unhandled action type');
  }
};

interface Props {
  children: ReactNode;
}

export default function UserCollectionContext(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const [users, dispatch] = useReducer(usersReducer, []);
  const [links, setLinks] = useState<Links>();
  const [totalItems, setTotalItems] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const lastname = useUserFilterStore((state) => state.lastname);
  const firstname = useUserFilterStore((state) => state.firstname);
  const email = useUserFilterStore((state) => state.email);
  const role = useUserFilterStore((state) => state.role);

  const fetchUsers = useCallback(
    (url?: string) => {
      // if (!url && role !== '') {
      //   url = `/search/users/byRole?role=${role}}`;
      //   if (lastname !== '') {
      //     url += `&lastname=${lastname}`;
      //   }
      //   if (firstname !== '') {
      //     url += `&firstname=${firstname}`;
      //   }
      //   if (email !== '') {
      //     url += `&email=${email}`;
      //   }
      // }
      if (!url) {
        url = `/users?itemsPerPage=${itemsPerPage}&page=1`;
        if (lastname !== '') {
          url += `&lastname=${lastname}`;
        }
        if (firstname !== '') {
          url += `&firstname=${firstname}`;
        }
        if (email !== '') {
          url += `&email=${email}`;
        }
      }

      setIsLoading(true);
      axiosPrivate
        .get(url)
        .then(
          (response) => {
            setTotalItems(response.data['hydra:totalItems']);
            setLinks(response?.data['hydra:view']);
            dispatch({ type: 'loaded', users: response?.data['hydra:member'] });
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => {
          setIsLoading(false);
        });
    },
    [axiosPrivate, totalItems, itemsPerPage, lastname, firstname, email, role],
  );

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <UsersStateContext.Provider
      value={{ users, links, totalItems, itemsPerPage, isLoading }}
    >
      <UsersDispatchContext.Provider
        value={{
          dispatch,
          setTotalItems,
          setItemsPerPage,
          fetchUsers,
        }}
      >
        {props.children}
      </UsersDispatchContext.Provider>
    </UsersStateContext.Provider>
  );
}

export function useUsers() {
  const context = useContext(UsersStateContext);
  if (context === undefined) {
    throw new Error('useUsers must be used within a UsersProvider');
  }
  return context.users;
}

export function useUsersLinks() {
  const context = useContext(UsersStateContext);
  if (context === undefined) {
    throw new Error('useLinks must be used within a UsersProvider');
  }
  return context.links;
}

export function useUsersTotalItems() {
  const context = useContext(UsersStateContext);
  if (context === undefined) {
    throw new Error(
      'useUsersTotalItems must be used within a SubsidiariesProvider',
    );
  }
  return context.totalItems;
}

export function useUsersItemsPerPage() {
  const context = useContext(UsersStateContext);
  if (context === undefined) {
    throw new Error('useUsersItemsPerPage must be used within a UsersProvider');
  }
  return context.itemsPerPage;
}

export function useUsersDispatch() {
  const context = useContext(UsersDispatchContext);
  if (context === undefined) {
    throw new Error('useUsersDispatch must be used within a UsersProvider');
  }
  return context.dispatch;
}

export function useIsLoadingUsers() {
  const context = useContext(UsersStateContext);
  if (context === undefined) {
    throw new Error('useIsLoadingUsers must be used within a UsersProvider');
  }
  return context.isLoading;
}

export function useUsersSetItemsPerPage() {
  const context = useContext(UsersDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useUsersSetItemsPerPage must be used within a UsersProvider',
    );
  }
  return context.setItemsPerPage;
}

export function useFetchUsers() {
  const context = useContext(UsersDispatchContext);
  if (context === undefined) {
    throw new Error('useFetchUsers must be used within a UsersProvider');
  }
  return context.fetchUsers;
}
