import { Route, Routes } from 'react-router-dom';
import AuthRouter from '../modules/Auth/contexts/AuthRouter';
import PrivateLayout from './layouts/PrivateLayout';
import LoginPage from './pages/LoginPage';
import { useAuth } from '../modules/Auth/contexts/AuthContextProvider';
import MaintenancePage from './pages/MaintenancePage';
import HomePage from './pages/HomePage';
import UserCollectionContext from '../modules/User/contexts/UserCollectionContext';
import UserCollectionPage from './pages/UserCollectionPage';
import { AdherentProvider } from '../modules/Adherent/contexts/AdherentContext';
import AdherentPage from './pages/AdherentPage';
import AdherentCollectionContext from '../modules/Adherent/contexts/AdherentCollectionContext';
import AdherentCollectionPage from './pages/AdherentCollectionPage';
import SubsidiaryContext from '../modules/Subsidiary/contexts/SubsidiaryContext';
import SubsidiaryPage from './pages/SubsidiaryPage';
import SubsidiaryCollectionPage from './pages/SubsidiaryCollectionPage';
import SubsidiaryCollectionContext from '../modules/Subsidiary/contexts/SubsidiaryCollectionContext';
import PublicLayout from './layouts/PublicLayout';

export default function App() {
  const { auth } = useAuth();
  const role = auth?.payload?.roles[0];

  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route path={'/login'} element={<LoginPage />} />
        <Route path={'/maintenance'} element={<MaintenancePage />} />
      </Route>
      <Route element={<AuthRouter />}>
        <Route element={<PrivateLayout />}>
          {role !== 'ROLE_SUBSIDIARY' && (
            <>
              <Route
                path={'/representations'}
                element={
                  <SubsidiaryCollectionContext>
                    <SubsidiaryCollectionPage />
                  </SubsidiaryCollectionContext>
                }
              />
              <Route
                path={'/representation/:subsidiaryId'}
                element={
                  <SubsidiaryContext>
                    <SubsidiaryPage />
                  </SubsidiaryContext>
                }
              />
            </>
          )}
          {role === 'ROLE_SUBSIDIARY' && (
            <Route
              path={`/representation/:subsidiaryId`}
              element={
                <SubsidiaryContext>
                  <SubsidiaryPage />
                </SubsidiaryContext>
              }
            />
          )}
          <Route
            path={'/adherents'}
            element={
              <AdherentCollectionContext>
                <AdherentCollectionPage />
              </AdherentCollectionContext>
            }
          />
          <Route
            path={'/adherent/:adherentId'}
            element={
              <AdherentProvider>
                <AdherentPage />
              </AdherentProvider>
            }
          />
          {role === 'ROLE_ADMIN' && (
            <Route
              path={'/users'}
              element={
                <UserCollectionContext>
                  <UserCollectionPage />
                </UserCollectionContext>
              }
            />
          )}
          <Route path={'/*'} element={<HomePage />} />
        </Route>
      </Route>
    </Routes>
  );
}
