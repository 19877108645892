import React, { useState } from 'react';
import useModal from '../../../components/modals/useModal';
import option from '../../../assets/images/options.svg';
import OnclickPosModal from '../../../components/modals/OnclickPosModal';
import { User } from '../types/User';
import UserDeleteButton from './UserDeleteButton';
import UserPutButton from './UserPutButton';

interface Props {
  user: User;
}

export default function UserActionButton(props: Props) {
  const { isShowing, toggle } = useModal();
  const [style, setStyle] = useState({});

  const openModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setStyle({ top: e.clientY, left: e.clientX - 167 });
    toggle();
  };

  return (
    <>
      <button
        className="p-2"
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          openModal(e)
        }
      >
        <img alt="option-icon" src={option} className="w-1" />
      </button>
      <OnclickPosModal
        isShowing={isShowing}
        hide={toggle}
        style={style}
        title={'Action'}
      >
        <div className="flex flex-col">
          <UserPutButton user={props.user} closeParentModal={toggle} />
          <UserDeleteButton user={props.user} closeParent={toggle} />
        </div>
      </OnclickPosModal>
    </>
  );
}
