import LoginForm from '../../modules/Auth/forms/LoginForm';
import pic from '../../assets/images/logo.svg';

export default function LoginPage() {
  return (
    <div className="flex flex-col items-center">
      <img src={pic} alt="logo" />
      <h1 className="text-3xl py-10 text-blue-ufe font-bold">UFE INTRANET</h1>
      <LoginForm />
    </div>
  );
}
