import { create } from 'zustand';

type State = {
  firstname: string;
  lastname: string;
  email: string;
  role: string;
};

type Action = {
  setFirstname: (firstName: State['firstname']) => void;
  setLastname: (lastName: State['lastname']) => void;
  setEmail: (email: State['email']) => void;
  setRole: (role: State['role']) => void;
  reset: () => void;
};

const initialState = {
  firstname: '',
  lastname: '',
  email: '',
  role: '',
};

export const useUserFilterStore = create<State & Action>((set) => ({
  ...initialState,
  reset: () => set(initialState),
  setFirstname: (firstname) => set({ firstname }),
  setLastname: (lastname) => set({ lastname }),
  setEmail: (email) => set({ email }),
  setRole: (role) => set({ role }),
}));
