import { create } from 'zustand';

type State = {
  subsidiaryName: string;
  adherentNumber: string;
  lastname: string;
  firstname: string;
  email: string;
  cardStatus: string;
  cardType: string;
  membershipsBeforeYear: string;
  membershipsAfterYear: string;
  lastMembershipBeforeYear: string;
  lastMembershipAfterYear: string;
  membershipStatus: string;
  membershipOrigin: string;
  priceType: string;
};

type Action = {
  setSubsidiaryName: (subsidiaryName: State['subsidiaryName']) => void;
  setAdherentNumber: (adherentNumber: State['adherentNumber']) => void;
  setLastname: (lastName: State['lastname']) => void;
  setFirstname: (firstName: State['firstname']) => void;
  setEmail: (email: State['email']) => void;
  setCardStatus: (cardStatus: State['cardStatus']) => void;
  setCardType: (cardType: State['cardType']) => void;
  setMembershipsBeforeYear: (
    membershipsBeforeYear: State['membershipsBeforeYear'],
  ) => void;
  setMembershipsAfterYear: (
    membershipsAfterYear: State['membershipsAfterYear'],
  ) => void;
  setLastMembershipBeforeYear: (
    lastMembershipBeforeYear: State['lastMembershipBeforeYear'],
  ) => void;
  setLastMembershipAfterYear: (
    lastMembershipAfterYear: State['lastMembershipAfterYear'],
  ) => void;
  setMembershipStatus: (membershipStatus: State['membershipStatus']) => void;
  setMembershipOrigin: (membershipOrigin: State['membershipOrigin']) => void;
  setPriceType: (priceType: State['priceType']) => void;
  reset: () => void;
};

const initialState = {
  subsidiaryName: '',
  adherentNumber: '',
  lastname: '',
  firstname: '',
  email: '',
  cardStatus: '',
  cardType: '',
  membershipsBeforeYear: '',
  membershipsAfterYear: '',
  lastMembershipBeforeYear: '',
  lastMembershipAfterYear: '',
  membershipStatus: '',
  membershipOrigin: '',
  priceType: '',
};

export const useAdherentFilterStore = create<State & Action>((set) => ({
  ...initialState,
  reset: () => set(initialState),
  setSubsidiaryName: (value: string) => set({ subsidiaryName: value }),
  setAdherentNumber: (value: string) => set({ adherentNumber: value }),
  setLastname: (value: string) => set({ lastname: value }),
  setFirstname: (value: string) => set({ firstname: value }),
  setEmail: (value: string) => set({ email: value }),
  setCardStatus: (value: string) => set({ cardStatus: value }),
  setCardType: (value: string) => set({ cardType: value }),
  setMembershipsBeforeYear: (value: string) =>
    set({ membershipsBeforeYear: value }),
  setMembershipsAfterYear: (value: string) =>
    set({ membershipsAfterYear: value }),
  setLastMembershipBeforeYear: (value: string) =>
    set({ lastMembershipBeforeYear: value }),
  setLastMembershipAfterYear: (value: string) =>
    set({ lastMembershipAfterYear: value }),
  setMembershipStatus: (value: string) => set({ membershipStatus: value }),
  setMembershipOrigin: (value: string) => set({ membershipOrigin: value }),
  setPriceType: (value: string) => set({ priceType: value }),
}));
