interface Props {
  membershipStatus: string;
  setMembershipStatus: (membershipStatus: string) => void;
  membershipsAfterYear: string;
  setLastMembershipBeforeYear: (lastMembershipBeforeYear: string) => void;
  setLastMembershipAfterYear: (lastMembershipAfterYear: string) => void;
}

export default function MembershipStatusFilter(props: Props) {
  function handleStatusChange(status: string) {
    const currentYearNumber = new Date().getFullYear();
    const targetMembershipsYear = parseInt(
      props.membershipsAfterYear.split('-')[0],
    );
    if (status === 'active') {
      const beforeYear = currentYearNumber + 2 + '-01-01';
      const afterYear = currentYearNumber + '-01-01';
      props.setLastMembershipBeforeYear(beforeYear);
      props.setLastMembershipAfterYear(afterYear);
    } else if (
      status === 'expired' &&
      targetMembershipsYear !== currentYearNumber
    ) {
      const beforeYear = currentYearNumber + '-01-01';
      props.setLastMembershipBeforeYear(beforeYear);
      props.setLastMembershipAfterYear('');
    } else if (
      status === 'expired' &&
      targetMembershipsYear === currentYearNumber
    ) {
      const beforeYear = currentYearNumber + 1 + '-01-01';
      const afterYear = currentYearNumber + '-01-01';
      props.setLastMembershipBeforeYear(beforeYear);
      props.setLastMembershipAfterYear(afterYear);
    } else {
      props.setLastMembershipBeforeYear('');
      props.setLastMembershipAfterYear('');
    }
    props.setMembershipStatus(status);
  }

  return (
    <div>
      <label className="text-sm">Statut adhérent</label>
      <select
        name="membershipStatus"
        className="w-full border"
        value={props.membershipStatus}
        onChange={(e) => {
          handleStatusChange(e.target.value);
        }}
      >
        <option value="">---</option>
        <option value="active">À jour</option>
        <option value="expired">À renouveler</option>
      </select>
    </div>
  );
}
