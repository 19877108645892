import { Adherent } from '../types/Adherent';
import AdherentActionButton from '../buttons/AdherentActionButton';
import {
  useAdherents,
  useAdherentsSelectedItems,
  useAdherentsSetSelectedItems,
} from '../contexts/AdherentCollectionContext';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';
import React from 'react';

interface Props {
  adherent: Adherent;
}

export default function AdherentItem(props: Props) {
  const currentDate = new Date();
  const endDate = new Date(
    props.adherent.lastMembership?.endAt
      ? props.adherent.lastMembership?.endAt
      : '',
  );
  const isActive = currentDate < endDate;
  const selectedItems = useAdherentsSelectedItems();
  const setSelectedItems = useAdherentsSetSelectedItems();
  const navigate = useNavigate();
  const id = props.adherent['@id'].split('/')[2];
  const { auth } = useAuth();
  const role = auth?.payload?.roles[0];
  const adherents = useAdherents();

  const openAdherent = () => {
    navigate('/adherent/' + id);
  };

  const manageSelect = () => {
    if (!selectedItems.includes(props.adherent)) {
      setSelectedItems([
        ...selectedItems.filter((item) => adherents.includes(item)),
        props.adherent,
      ]);
    } else {
      setSelectedItems(
        selectedItems.filter((item) => item['@id'] !== props.adherent['@id']),
      );
    }
  };

  return (
    <tr className="cursor-pointer hover:bg-gray-100">
      <td className="py-2">
        <button
          onClick={manageSelect}
          className={
            selectedItems.includes(props.adherent)
              ? 'ml-2 w-4 h-4 bg-green-ufe border'
              : 'ml-2 w-4 h-4 bg-white border'
          }
        />
      </td>
      <td className="p-2" onClick={openAdherent}>
        {props.adherent?.id}
      </td>
      <td className="p-2" onClick={openAdherent}>
        {props.adherent?.lastname}
      </td>
      <td className="p-2" onClick={openAdherent}>
        {props.adherent?.firstname}
      </td>
      <td className="p-2" onClick={openAdherent}>
        {props.adherent?.email}
      </td>
      <td className="p-2" onClick={openAdherent}>
        {props.adherent?.lastMembership?.priceType}
      </td>
      <td className="p-2" onClick={openAdherent}>
        {props.adherent?.lastMembership?.origin}
      </td>
      {role !== 'ROLE_SUBSIDIARY' && (
        <td className="p-2" onClick={openAdherent}>
          {props.adherent?.lastMembership?.subsidiary?.name}
        </td>
      )}
      <td className="p-2" onClick={openAdherent}>
        <div
          className={
            isActive
              ? 'px-2 text-center bg-green-activeBg text-green-activeText'
              : 'px-2 text-center bg-red-ufeLight text-red-ufeDark'
          }
        >
          {isActive ? 'À jour' : 'À renouveler'}
        </div>
      </td>
      {role !== 'ROLE_SUBSIDIARY' && (
        <td className="p-2">
          <AdherentActionButton adherent={props.adherent} />
        </td>
      )}
    </tr>
  );
}
