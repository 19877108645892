import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { usePriceStore } from '../usePriceStore';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';
import { useCallback, useEffect } from 'react';
import { Price } from '../types/Price';

interface Props {
  subsidiary: Subsidiary;
}

export default function CurrentPriceContainer(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const setCurrentPrices = usePriceStore((state) => state.setCurrentPrices);
  const prices = usePriceStore((state) => state.currentPrices);

  const getCurrentPrices = useCallback(() => {
    axiosPrivate
      .get(`/prices?subsidiary=${props.subsidiary['@id']}&year=2023`)
      .then(
        (response) => {
          setCurrentPrices(response?.data['hydra:member']);
        },
        (error) => {
          console.log(error);
        },
      );
  }, [axiosPrivate, props.subsidiary, setCurrentPrices]);

  useEffect(() => {
    if (props.subsidiary) {
      getCurrentPrices();
    }
  }, [getCurrentPrices]);

  return (
    <div className="bg-white p-5 w-1/3">
      <div className="flex items-center gap-x-5">
        <h3 className="font-bold">{`Barèmes de l'année ${new Date().getFullYear()}`}</h3>
      </div>
      <div className="mt-5 flex flex-col w-full space-y-4">
        {prices &&
          prices.map((price: Price, id) => {
            return (
              <div key={id} className="flex flex-col p-3 border text-sm">
                <div className="flex justify-between items-center font-bold">
                  <h4>{price.type}</h4>
                  <p>{price.siegeAmount + price.localAmount}€</p>
                </div>
                <div className="flex justify-between items-center">
                  <h5>Montant part siège</h5>
                  <p>{price.siegeAmount}€</p>
                </div>
                <div className="flex justify-between items-center">
                  <h5>Montant part locale</h5>
                  <p>{price.localAmount}€</p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
