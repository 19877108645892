import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';

interface Values {
  number: string;
  street: string;
  complement: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

interface Props {
  subsidiary: Subsidiary;
}

export default function SubsidiaryAddressPutForm(props: Props) {
  const axiosPrivate = useAxiosPrivate();

  const SubsidiarySchema = Yup.object().shape({
    city: Yup.string()
      .min(2, 'Votre ville doit avoir un minimum de 2 caractère.')
      .max(50, 'Votre ville doit avoir un maximum de 50 caractère.'),
    complement: Yup.string()
      .min(2, 'Le complément doit avoir un minimum de 2 caractère.')
      .max(50, 'Le complément doit avoir un maximum de 50 caractère.'),
    country: Yup.string()
      .min(2, 'Le pays doit avoir un minimum de 2 caractère.')
      .max(50, 'Le pays doit avoir un maximum de 50 caractère.'),
    number: Yup.string()
      .min(1, 'Le numéro doit avoir un minimum de 1 caractère.')
      .max(50, 'Le numéro doit avoir un maximum de 50 caractère.'),
    state: Yup.string()
      .min(2, "L'état doit avoir un minimum de 2 caractère.")
      .max(50, "L'état doit avoir un maximum de 50 caractère."),
    street: Yup.string()
      .min(2, 'La rue doit avoir un minimum de 2 caractère.')
      .max(50, 'La rue doit avoir un maximum de 50 caractère.'),
    zipCode: Yup.string()
      .min(2, 'Le code postale doit avoir un minimum de 2 caractère.')
      .max(50, 'Le code postale doit avoir un maximum de 50 caractère.'),
  });

  return (
    <Formik
      initialValues={{
        number: props.subsidiary.address?.number,
        street: props.subsidiary.address?.street,
        complement: props.subsidiary.address?.complement,
        city: props.subsidiary.address?.city,
        state: props.subsidiary.address?.state,
        zipCode: props.subsidiary.address?.zipCode,
        country: props.subsidiary.address?.country,
      }}
      validationSchema={SubsidiarySchema}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        toast
          .promise(
            axiosPrivate.put(props.subsidiary.address['@id'], values),
            {
              pending: 'Modification en cours...',
              success: "Modification de l'adresse réussie.",
              error: 'Une erreur est survenue.',
            },
            { position: toast.POSITION.BOTTOM_CENTER },
          )
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched }) => (
        <Form className="flex flex-col gap-y-5">
          <div className="flex flex-col">
            <label className="text-sm">Numéro</label>
            <Field name="number" type="text" className="w-full pl-1 border" />
            {errors.number && touched.number ? (
              <p className="text-sm text-red-700 mt-1">{errors.number}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label className="text-sm">Rue</label>
            <Field name="street" type="text" className="w-full pl-1 border" />
            {errors.street && touched.street ? (
              <p className="text-sm text-red-700 mt-1">{errors.street}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label className="text-sm">Complément</label>
            <Field
              name="complement"
              type="text"
              className="w-full pl-1 border"
            />
            {errors.complement && touched.complement ? (
              <p className="text-sm text-red-700 mt-1">{errors.complement}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label className="text-sm">Ville</label>
            <Field name="city" type="text" className="w-full pl-1 border" />
            {errors.city && touched.city ? (
              <p className="text-sm text-red-700 mt-1">{errors.city}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label className="text-sm">Région</label>
            <Field name="state" type="text" className="w-full pl-1 border" />
            {errors.state && touched.state ? (
              <p className="text-sm text-red-700 mt-1">{errors.state}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label className="text-sm">Code Postal</label>
            <Field name="zipCode" type="text" className="w-full pl-1 border" />
            {errors.zipCode && touched.zipCode ? (
              <p className="text-sm text-red-700 mt-1">{errors.zipCode}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label className="text-sm">Pays</label>
            <Field name="country" type="text" className="w-full pl-1 border" />
            {errors.country && touched.country ? (
              <p className="text-sm text-red-700 mt-1">{errors.country}</p>
            ) : null}
          </div>
          <button
            type="submit"
            className="px-2 py-1 text-white bg-green-ufe w-full"
          >
            Modifier
          </button>
        </Form>
      )}
    </Formik>
  );
}
