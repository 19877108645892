import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import Loader from '../../../components/Loader';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';

interface Props {
  chosenSubsidiary: Subsidiary | null;
}

const ActualStateChart = (props: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get(
        '/charts/currentMonth' +
          (props.chosenSubsidiary && props.chosenSubsidiary['@id'] !== ''
            ? '?subsidiary=' + props.chosenSubsidiary['@id']
            : ''),
      )
      .then(
        (r) => {
          setData(r.data);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [axiosPrivate, props.chosenSubsidiary]);

  return (
    <div className="p-6 bg-white w-full">
      <h3 className="mb-5">État actuel {isLoading && <Loader />}</h3>
      <ResponsiveContainer width={'100%'} height={175}>
        <BarChart
          width={500}
          height={300}
          data={data}
          layout="vertical"
          margin={{
            top: 5,
            right: 35,
            bottom: 5,
            left: 35,
          }}
        >
          <XAxis
            tickLine={false}
            axisLine={false}
            type="number"
            allowDecimals={false}
          />
          <YAxis
            width={200}
            tickLine={false}
            axisLine={false}
            dataKey="name"
            type="category"
            tickMargin={40}
          />
          <Tooltip />
          <Bar name="adhérents" dataKey="adherents" fill="#539CAD" barSize={30}>
            <LabelList
              name="adhérents"
              dataKey="adherents"
              fill="#808080"
              position={'insideLeft'}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ActualStateChart;
