import * as React from 'react';
import { createContext, ReactNode, useContext, useState } from 'react';

type Auth = {
  token?: string;
  payload: {
    iat: number;
    exp: number;
    username: string;
    roles: Array<string>;
    id: number;
  };
};

type AuthContextInterface = {
  auth: Auth | null;
  setAuth: React.Dispatch<React.SetStateAction<Auth | null>>;
};

const AuthContext = createContext({} as AuthContextInterface);

interface Props {
  children: ReactNode;
}

export const AuthContextProvider = (props: Props) => {
  const [auth, setAuth] = useState<Auth | null>(null);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
