import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../modules/Auth/contexts/AuthContextProvider';
import { useUser } from '../modules/User/contexts/UserContextProvider';

export default function Navigation() {
  const location = useLocation();
  const { auth } = useAuth();
  const user = useUser();
  const role = auth?.payload.roles[0];
  const subsidiaryUrl = `/representation/${user?.subsidiary?.id}`;

  return (
    <nav className="no-underline text-white">
      <Link
        className={
          location.pathname === '/'
            ? 'border-b pb-1 ml-6 no-underline text-sm'
            : 'ml-6 no-underline text-sm'
        }
        to="/"
      >
        Accueil
      </Link>
      {role === 'ROLE_SUBSIDIARY' && (
        <Link
          className={
            location.pathname === `/representation/${user?.subsidiary?.id}`
              ? 'border-b pb-1 ml-6 no-underline text-sm'
              : 'ml-6 no-underline text-sm'
          }
          to={subsidiaryUrl}
        >
          Représentation
        </Link>
      )}
      {role !== 'ROLE_SUBSIDIARY' && (
        <Link
          className={
            location.pathname === '/representations'
              ? 'border-b pb-1 ml-6 no-underline text-sm'
              : 'ml-6 no-underline text-sm'
          }
          to="/representations"
        >
          Représentations
        </Link>
      )}
      <Link
        className={
          location.pathname === '/adherents'
            ? 'border-b pb-1 ml-6 no-underline text-sm'
            : 'ml-6 no-underline text-sm'
        }
        to="/adherents"
      >
        Adhérents
      </Link>
      {role === 'ROLE_ADMIN' && (
        <Link
          className={
            location.pathname === '/users'
              ? 'border-b pb-1 ml-6 no-underline text-sm'
              : 'ml-6 no-underline text-sm'
          }
          to="/users"
        >
          Utilisateurs
        </Link>
      )}
      {role === 'ROLE_SUBSIDIARY' && (
        <a
          className="ml-6 no-underline text-sm"
          href="mailto:coralie.desbas@ufe.org"
        >
          Contact
        </a>
      )}
    </nav>
  );
}
