import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Adherent } from '../../Adherent/types/Adherent';
import { toast } from 'react-toastify';

interface Values {
  city: string;
  complement: string;
  country: string;
  number: string;
  state: string;
  street: string;
  zipCode: string;
}

interface Props {
  adherent: Adherent;
}

export default function AdherentAddressPutForm(props: Props) {
  const axiosPrivate = useAxiosPrivate();

  const ValidationSchema = Yup.object().shape({
    city: Yup.string()
      .min(2, 'Votre ville doit avoir un minimum de 2 caractère.')
      .max(50, 'Votre ville doit avoir un maximum de 50 caractère.'),
    complement: Yup.string()
      .min(2, 'Le complément doit avoir un minimum de 2 caractère.')
      .max(50, 'Le complément doit avoir un maximum de 50 caractère.'),
    country: Yup.string()
      .min(2, 'Le pays doit avoir un minimum de 2 caractère.')
      .max(50, 'Le pays doit avoir un maximum de 50 caractère.'),
    number: Yup.string()
      .min(1, 'Le numéro doit avoir un minimum de 1 caractère.')
      .max(5, 'Le numéro doit avoir un maximum de 5 caractère.'),
    state: Yup.string()
      .min(2, "L'état doit avoir un minimum de 2 caractère.")
      .max(50, "L'état doit avoir un maximum de 50 caractère."),
    street: Yup.string()
      .min(2, 'La rue doit avoir un minimum de 2 caractère.')
      .max(50, 'La rue doit avoir un maximum de 50 caractère.'),
    zipCode: Yup.string()
      .min(2, 'Le code postale doit avoir un minimum de 2 caractère.')
      .max(50, 'Le code postale doit avoir un maximum de 50 caractère.'),
  });

  return (
    <Formik
      initialValues={{
        city: props.adherent.address?.city ? props.adherent.address?.city : '',
        complement: props.adherent.address?.complement
          ? props.adherent.address?.complement
          : '',
        country: props.adherent.address?.country
          ? props.adherent.address?.country
          : '',
        number: props.adherent.address?.number
          ? props.adherent.address?.number
          : '',
        state: props.adherent.address?.state
          ? props.adherent.address?.state
          : '',
        street: props.adherent.address?.street
          ? props.adherent.address?.street
          : '',
        zipCode: props.adherent.address?.zipCode
          ? props.adherent.address?.zipCode
          : '',
      }}
      validationSchema={ValidationSchema}
      enableReinitialize={true}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        toast
          .promise(
            axiosPrivate.put(props.adherent.address['@id'], values),
            {
              pending: 'Modification en cours...',
              success: "Modification de l'adhérent réussie.",
              error: 'Une erreur est survenue.',
            },
            { position: toast.POSITION.BOTTOM_CENTER },
          )
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched }) => (
        <Form className="flex flex-col gap-y-5">
          <div className="flex flex-col">
            <label className="text-sm">Numéro</label>
            <Field name="number" type="text" className="w-full border px-1.5" />
            {errors.number && touched.number ? (
              <p className="text-sm text-red-700 mt-1">{errors.number}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label className="text-sm">Rue</label>
            <Field name="street" type="text" className="w-full border px-1.5" />
            {errors.street && touched.street ? (
              <p className="text-sm text-red-700 mt-1">{errors.street}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label className="text-sm">Complément</label>
            <Field
              name="complement"
              type="text"
              className="w-full border px-1.5"
            />
            {errors.complement && touched.complement ? (
              <p className="text-sm text-red-700 mt-1">{errors.complement}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label className="text-sm">Ville</label>
            <Field name="city" type="text" className="w-full border px-1.5" />
            {errors.city && touched.city ? (
              <p className="text-sm text-red-700 mt-1">{errors.city}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label className="text-sm">Région</label>
            <Field name="state" type="text" className="w-full border px-1.5" />
            {errors.state && touched.state ? (
              <p className="text-sm text-red-700 mt-1">{errors.state}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label className="text-sm">Code Postal</label>
            <Field
              name="zipCode"
              type="text"
              className="w-full border px-1.5"
            />
            {errors.zipCode && touched.zipCode ? (
              <p className="text-sm text-red-700 mt-1">{errors.zipCode}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label className="text-sm">Pays</label>
            <Field
              name="country"
              type="text"
              className="w-full border px-1.5"
            />
            {errors.country && touched.country ? (
              <p className="text-sm text-red-700 mt-1">{errors.country}</p>
            ) : null}
          </div>
          <button
            type="submit"
            className="text-sm text-white bg-green-ufe w-full py-1"
          >
            Modifier
          </button>
        </Form>
      )}
    </Formik>
  );
}
