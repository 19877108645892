import Loader from '../../components/Loader';
import options from '../../assets/images/multipleOptions.svg';
import { User } from '../../modules/User/types/User';
import {
  useFetchUsers,
  useIsLoadingUsers,
  useUsers,
  useUsersLinks,
  useUsersSetItemsPerPage,
  useUsersTotalItems,
} from '../../modules/User/contexts/UserCollectionContext';
import UserPostButton from '../../modules/User/buttons/UserPostButton';
import UserItem from '../../modules/User/items/UserItem';
import Pagination from '../../components/Pagination';
import RowSelector from '../../components/RowSelector';
import React from 'react';
import UserFilterForm from '../../modules/User/forms/UserFilterForm';

export default function UserCollectionPage() {
  const users = useUsers();
  const links = useUsersLinks();
  const isLoading = useIsLoadingUsers();
  const fetchUsers = useFetchUsers();
  const setItemsPerPage = useUsersSetItemsPerPage();
  const totalItems = useUsersTotalItems();

  return (
    <div className="flex gap-x-5 p-5">
      <div className="w-1/6 h-min p-5 bg-white flex flex-col gap-y-5">
        <UserFilterForm />
      </div>
      <div className="bg-white p-5 flex flex-col h-min w-5/6">
        <div className="flex justify-between mb-5">
          <div className="flex gap-x-1">
            <h3 className="font-bold">Utilisateurs</h3>
            {isLoading ? <Loader /> : null}
          </div>
          <UserPostButton />
        </div>
        <table className="border">
          <thead>
            <tr className="bg-green-ufeLight">
              <td className="p-2 text-left">Nom</td>
              <td className="p-2 text-left">Prénom</td>
              <td className="p-2 text-left">Email</td>
              <td className="p-2 text-left">Représentation</td>
              <td className="p-2 text-left">Rôle</td>
              <td className="p-2 flex items-center justify-end">
                <img alt="options-icon" src={options} className="w-6" />
              </td>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((element: User, index: number) => {
                return <UserItem key={index} user={element} />;
              })}
          </tbody>
        </table>
        <div className="mt-5 flex justify-between">
          <Pagination links={links} fetchData={fetchUsers} />
          <RowSelector
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          />
        </div>
      </div>
    </div>
  );
}
