import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Donation } from '../types/Donation';
import { Links } from '../../../types/Links';
import { Adherent } from '../../Adherent/types/Adherent';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';

type Action =
  | { type: 'loaded'; donations: Donation[] }
  | { type: 'added'; donation: Donation }
  | { type: 'changed'; donation: Donation }
  | { type: 'deleted'; donation: Donation };

type Dispatch = (action: Action) => void;

type DonationStateContentProps = {
  donations: Donation[];
  links?: Links;
  itemsPerPage: number;
  isLoading: boolean;
};

type DonationDisptachContentProps = {
  dispatch: Dispatch;
  setItemsPerPage: (itemsPerPage: number) => void;
  fetchDonations: (url?: string) => void;
};

const DonationStateContext = createContext<
  DonationStateContentProps | undefined
>(undefined);

const DonationDisptachContext = createContext<
  DonationDisptachContentProps | undefined
>(undefined);

const donationReducer = (donations: Donation[], action: Action) => {
  switch (action.type) {
    case 'loaded':
      return action.donations;
    case 'added':
      return [...donations, action.donation];
    case 'changed':
      return donations.map((donation) => {
        if (donation['@id'] === action.donation['@id']) {
          return action.donation;
        }
        return donation;
      });
    case 'deleted':
      return donations.filter(
        (donation) => donation['@id'] !== action.donation['@id'],
      );
    default:
      throw new Error('Unhandled action type');
  }
};

interface Props {
  children: ReactNode;
  adherent: Adherent;
}

export default function DonationProvider(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const [donations, dispatch] = useReducer(donationReducer, []);
  const [links, setLinks] = useState<Links>();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const fetchDonations = useCallback(
    async (url?: string) => {
      setIsLoading(true);
      try {
        const response = await axiosPrivate.get(
          url ||
            `/donations?itemsPerPage=${itemsPerPage}&page=1&adherent=${props.adherent['@id']}`,
        );
        dispatch({ type: 'loaded', donations: response.data['hydra:member'] });
        setLinks(response.data['hydra:view']);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [axiosPrivate, props.adherent],
  );

  useEffect(() => {
    fetchDonations();
  }, [fetchDonations]);

  return (
    <DonationStateContext.Provider
      value={{ donations, links, itemsPerPage, isLoading }}
    >
      <DonationDisptachContext.Provider
        value={{ dispatch, setItemsPerPage, fetchDonations }}
      >
        {props.children}
      </DonationDisptachContext.Provider>
    </DonationStateContext.Provider>
  );
}

export function useDonations() {
  const context = useContext(DonationStateContext);
  if (context === undefined) {
    throw new Error('useDonationState must be used within a DonationProvider');
  }
  return context.donations;
}

export function useDonationLinks() {
  const context = useContext(DonationStateContext);
  if (context === undefined) {
    throw new Error('useDonationState must be used within a DonationProvider');
  }
  return context.links;
}

export function useDonationItemsPerPage() {
  const context = useContext(DonationStateContext);
  if (context === undefined) {
    throw new Error('useDonationState must be used within a DonationProvider');
  }
  return context.itemsPerPage;
}

export function useDonationIsLoading() {
  const context = useContext(DonationStateContext);
  if (context === undefined) {
    throw new Error('useDonationState must be used within a DonationProvider');
  }
  return context.isLoading;
}

export function useDonationDispatch() {
  const context = useContext(DonationDisptachContext);
  if (context === undefined) {
    throw new Error(
      'useDonationDispatch must be used within a DonationProvider',
    );
  }
  return context.dispatch;
}

export function useDonationSetItemsPerPage() {
  const context = useContext(DonationDisptachContext);
  if (context === undefined) {
    throw new Error(
      'useDonationDispatch must be used within a DonationProvider',
    );
  }
  return context.setItemsPerPage;
}

export function useDonationsFetch() {
  const context = useContext(DonationDisptachContext);
  if (context === undefined) {
    throw new Error(
      'useDonationDispatch must be used within a DonationProvider',
    );
  }
  return context.fetchDonations;
}
