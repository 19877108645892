import {
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';

interface Props {
  order: string | undefined;
  setOrder: (order: string | undefined) => void;
}

export default function OrderFilter(props: Props) {
  const handleClick = () => {
    if (props.order === 'asc') {
      props.setOrder('desc');
    } else if (props.order === 'desc') {
      props.setOrder(undefined);
    } else {
      props.setOrder('asc');
    }
  };

  return (
    <>
      {props.order === 'asc' && (
        <ChevronUpIcon className="h-4 cursor-pointer" onClick={handleClick} />
      )}
      {props.order === 'desc' && (
        <ChevronDownIcon className="h-4 cursor-pointer" onClick={handleClick} />
      )}
      {props.order === undefined && (
        <ChevronUpDownIcon
          className="h-4 cursor-pointer"
          onClick={handleClick}
        />
      )}
    </>
  );
}
