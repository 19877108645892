import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Adherent } from '../types/Adherent';
import { useParams } from 'react-router-dom';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import Loader from '../../../components/Loader';

type Action =
  | { type: 'loaded'; adherent: Adherent }
  | { type: 'changed'; adherent: Adherent };

type Dispatch = (action: Action) => void;

type AdherentStateProps = {
  adherent: Adherent;
  isLoading: boolean;
};

type AdherentDispatchProps = {
  dispatch: Dispatch;
  setIsLoading: (isLoading: boolean) => void;
};

const AdherentStateContext = createContext<AdherentStateProps | undefined>(
  undefined,
);

const AdherentDispatchContext = createContext<
  AdherentDispatchProps | undefined
>(undefined);

const adherentReducer = (adherent: Adherent, action: Action) => {
  switch (action.type) {
    case 'loaded':
      return action.adherent;
    case 'changed':
      return action.adherent;
    default:
      throw new Error(`Unhandled action type`);
  }
};

interface Props {
  children: ReactNode;
}

export function AdherentProvider(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const [adherent, dispatch] = useReducer(adherentReducer, {} as Adherent);
  const [isLoading, setIsLoading] = useState(true);
  const { adherentId } = useParams();

  const fetchAdherent = useCallback(async () => {
    try {
      const response = await axiosPrivate.get(`/adherents/${adherentId}`);
      dispatch({ type: 'loaded', adherent: response.data });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [adherentId, axiosPrivate]);

  useEffect(() => {
    fetchAdherent();
  }, [fetchAdherent]);

  return (
    <AdherentStateContext.Provider value={{ adherent, isLoading }}>
      <AdherentDispatchContext.Provider value={{ dispatch, setIsLoading }}>
        {isLoading ? <Loader /> : props.children}
      </AdherentDispatchContext.Provider>
    </AdherentStateContext.Provider>
  );
}

export function useAdherent() {
  const context = React.useContext(AdherentStateContext);
  if (context === undefined) {
    throw new Error('useAdherent must be used within a AdherentContext');
  }
  return context.adherent;
}

export function useAdherentIsLoading() {
  const context = React.useContext(AdherentStateContext);
  if (context === undefined) {
    throw new Error('useIsLoading must be used within a AdherentContext');
  }
  return context.isLoading;
}

export function useAdherentDispatch() {
  const context = React.useContext(AdherentDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useAdherentDispatch must be used within a AdherentContext',
    );
  }
  return context.dispatch;
}
