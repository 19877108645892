import multipleOptions from '../../../assets/images/multipleOptions.svg';
import PricePostButton from '../buttons/PricePostButton';
import { usePriceStore } from '../usePriceStore';
import { Price } from '../types/Price';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { useCallback, useEffect } from 'react';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';
import Loader from '../../../components/Loader';
import PriceActionButton from '../buttons/PriceActionButton';
import Pagination from '../../../components/Pagination';
import RowSelector from '../../../components/RowSelector';
import PriceFilterForm from '../forms/PriceFilterForm';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';

interface Props {
  subsidiary: Subsidiary;
}

export default function PriceContainer(props: Props) {
  const prices = usePriceStore((state) => state.prices);
  const links = usePriceStore((state) => state.links);
  const setPrices = usePriceStore((state) => state.setPrices);
  const itemsPerPage = usePriceStore((state) => state.itemsPerPage);
  const setItemsPerPage = usePriceStore((state) => state.setItemsPerPage);
  const isLoading = usePriceStore((state) => state.isLoading);
  const totalItems = usePriceStore((state) => state.totalItems);
  const setTotalItems = usePriceStore((state) => state.setTotalItems);
  const setIsLoading = usePriceStore((state) => state.setIsLoading);
  const setLinks = usePriceStore((state) => state.setLinks);
  const year = usePriceStore((state) => state.year);
  const type = usePriceStore((state) => state.type);
  const { auth } = useAuth();
  const role = auth?.payload?.roles[0];
  const axiosPrivate = useAxiosPrivate();

  const getPrices = useCallback(
    (url?: string) => {
      if (!url) {
        url = `/prices?itemsPerPage=${itemsPerPage}&page=1&subsidiary=${props.subsidiary['@id']}`;
        if (year !== 0) {
          url += `&year=${year}`;
        }
        if (type !== '') {
          url += `&type=${type}`;
        }
      }
      setIsLoading(true);
      axiosPrivate
        .get(url)
        .then(
          (response) => {
            setPrices(response?.data['hydra:member']);
            setLinks(response?.data['hydra:view']);
            setTotalItems(response?.data['hydra:totalItems']);
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => {
          setIsLoading(false);
        });
    },
    [axiosPrivate, itemsPerPage, year, type],
  );

  useEffect(() => {
    getPrices();
  }, [getPrices]);

  return (
    <div className="flex gap-x-5">
      <div className="w-1/6 h-min p-5 bg-white flex flex-col gap-y-5">
        <PriceFilterForm />
      </div>
      <div className="bg-white p-5 flex flex-col h-min w-5/6">
        <div className="flex justify-between mb-5">
          <div className="flex gap-x-1">
            <h3 className="font-bold">Barèmes</h3>
            {isLoading ? <Loader /> : null}
          </div>
          <div className="flex gap-x-4">
            <PricePostButton />
          </div>
        </div>
        <table className="border text-xs lg:text-base">
          <thead>
            <tr className="bg-green-ufeLight">
              <td className="p-2 text-left">Type</td>
              <td className="p-2 text-left">Montant total</td>
              <td className="p-2 text-left">Montant siège</td>
              <td className="p-2 text-left">Montant local</td>
              <td className="p-2 text-left">Année</td>
              {role !== 'ROLE_SUBSIDIARY' && (
                <td className="p-2 flex justify-end">
                  <img alt="option-icon" src={multipleOptions} />
                </td>
              )}
            </tr>
          </thead>
          <tbody>
            {prices &&
              prices.map((price: Price, id) => {
                return (
                  <tr key={id} className="cursor-pointer hover:bg-gray-100">
                    <td className="p-2">
                      <h4>{price.type}</h4>
                    </td>
                    <td className="p-2">
                      <p>{price.siegeAmount + price.localAmount}€</p>
                    </td>
                    <td className="p-2">
                      <p>{price.siegeAmount}€</p>
                    </td>
                    <td className="p-2">
                      <p>{price.localAmount}€</p>
                    </td>
                    <td className="p-2">
                      <p>{price.year}</p>
                    </td>
                    {role !== 'ROLE_SUBSIDIARY' && (
                      <td className="p-2 text-right">
                        <PriceActionButton price={price} />
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="mt-5 flex justify-between">
          <Pagination links={links} fetchData={getPrices} />
          <RowSelector
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          />
        </div>
      </div>
    </div>
  );
}
