import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';

interface Props {
  chosenSubsidiary: Subsidiary | null;
}

const AdherentByYearChart = (props: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const { auth } = useAuth();
  const role = auth?.payload.roles[0];

  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get(
        '/charts/membershipsByAdherentsByYears' +
          (props.chosenSubsidiary && props.chosenSubsidiary['@id'] !== ''
            ? '?subsidiary=' + props.chosenSubsidiary['@id']
            : ''),
      )
      .then(
        (response) => {
          setData(response.data);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [axiosPrivate, props.chosenSubsidiary]);

  return (
    <div
      className={
        props.chosenSubsidiary !== null || role === 'ROLE_SUBSIDIARY'
          ? 'p-6 bg-white w-full'
          : 'p-6 bg-white w-1/2'
      }
    >
      <h3 className="mb-5">
        Nombre d'adhérents par année {isLoading && <Loader />}
      </h3>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 30,
            right: 15,
            bottom: -7,
          }}
        >
          <XAxis dataKey="name" tickLine={false} axisLine={false} />
          <YAxis tickLine={false} axisLine={false} allowDecimals={false} />
          <Tooltip />
          <Bar name="adhérents" dataKey="adherents" fill="#539CAD" barSize={30}>
            <LabelList
              name="adhérents"
              dataKey="adherents"
              fill="#808080"
              position={'top'}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AdherentByYearChart;
