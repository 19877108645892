import { Field, Form, Formik, FormikHelpers } from 'formik';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { useMembershipsDispatch } from '../contexts/MembershipCollectionContext';
import { Membership } from '../types/Membership';
import { ChangeEvent, useEffect, useState } from 'react';
import { Price } from '../../Price/types/Price';
import { toast } from 'react-toastify';
import {
  useAdherent,
  useAdherentDispatch,
} from '../../Adherent/contexts/AdherentContext';

interface Values {
  origin: string;
}

interface Props {
  hide: () => void;
  membership: Membership;
  closeParent: () => void;
}

export default function MembershipPutForm(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const membershipDispatch = useMembershipsDispatch();
  const [year, setYear] = useState(
    new Date(props.membership.endAt).getUTCFullYear().toString(),
  );
  const [selectedPrice, setSelectedPrice] = useState<Price>();
  const [prices, setPrices] = useState<Price[]>([]);
  const adherentDispatch = useAdherentDispatch();
  const adherent = useAdherent();

  useEffect(() => {
    if (year !== '') {
      axiosPrivate
        .get(
          '/prices?subsidiary=' +
            props.membership.subsidiary['@id'] +
            '&year=' +
            year,
        )
        .then((response) => {
          setPrices(response?.data['hydra:member']);
          if (
            year ===
            new Date(props.membership.endAt).getUTCFullYear().toString()
          ) {
            const tempPrices = response?.data['hydra:member'];
            tempPrices.forEach((price: Price) => {
              if (price.type === props.membership.priceType) {
                setSelectedPrice(price);
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [axiosPrivate, year]);

  const handlePriceSelect = (event: ChangeEvent<HTMLSelectElement>): void => {
    prices.forEach((price: Price) => {
      if (price.type === event.target.value) {
        setSelectedPrice(price);
      }
    });
  };

  return (
    <Formik
      initialValues={{
        origin: props.membership.origin,
      }}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        const data = {
          origin: values.origin,
          priceType: selectedPrice?.type,
          localAmount: selectedPrice?.localAmount,
          siegeAmount: selectedPrice?.siegeAmount,
          beginAt: year + '-01-01 00:00:00',
          endAt: year + '-12-31 23:59:59',
        };
        toast
          .promise(
            axiosPrivate.put(props.membership['@id'], data),
            {
              pending: 'Mise à jour en cours...',
              success: 'Cotisation mise à jour.',
              error: 'Une erreur est survenue.',
            },
            { position: toast.POSITION.BOTTOM_CENTER },
          )
          .then((response) => {
            membershipDispatch({
              type: 'changed',
              membership: response.data,
            });

            if (
              adherent.lastMembership &&
              adherent.lastMembership['@id'] === props.membership['@id']
            ) {
              const newAdherent = adherent;
              newAdherent.lastMembership = response.data;
              adherentDispatch({
                type: 'changed',
                adherent: newAdherent,
              });
            }
            props.closeParent();
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      <Form>
        <div className="flex flex-col mb-4">
          <label>Année</label>
          <select
            name="endAt"
            className="border px-2 flex items-center h-8 w-full"
            defaultValue={year}
            onChange={(e) => setYear(e.target.value)}
          >
            <option value="">---</option>
            <option value="2017">2017</option>
            <option value="2018">2018</option>
            <option value="2019">2019</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
        </div>
        <div className="flex flex-col mb-4">
          <label>Barème</label>
          <select
            className="h-8 px-2 border border-gray-300 rounded-sm"
            onChange={(e) => handlePriceSelect(e)}
            value={selectedPrice?.type}
          >
            <option value="">---</option>
            {prices &&
              prices.map((price: Price, key) => {
                return (
                  <option key={key} value={price.type}>
                    {price.type} - Siège: {price.siegeAmount} - Local:{' '}
                    {price.localAmount}
                  </option>
                );
              })}
          </select>
        </div>
        <div>
          <label>Origine du paiement :</label>
          <Field
            as="select"
            id="origin"
            name="origin"
            className="border px-2 flex items-center h-8 w-full"
          >
            <option value="" disabled={true}>
              ---
            </option>
            <option value="SIEGE">Siège</option>
            <option value="REPRESENTATION">Représentation</option>
            <option value="SITE INTERNET">Site Internet</option>
          </Field>
        </div>
        <button
          className="px-2 py-1 text-white bg-green-ufe w-full mt-4"
          type="submit"
        >
          Mettre à jour
        </button>
      </Form>
    </Formik>
  );
}
