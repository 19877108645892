import Modal from '../../../components/modals/Modal';
import useModal from '../../../components/modals/useModal';
import PricePostForm from '../forms/PricePostForm';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';

export default function PricePostButton() {
  const { isShowing, toggle } = useModal();
  const { auth } = useAuth();
  const role = auth?.payload?.roles[0];

  return (
    <>
      <button onClick={toggle} className="bg-white border p-2 text-sm">
        Créer un barème
      </button>
      {role === 'ROLE_SUBSIDIARY' ? (
        <Modal isShowing={isShowing} hide={toggle} title={'Nouveau barème'}>
          <div className="your-subidiary-div">
            Merci de contacter le siège à l'adresse suivante{' '}
            <a
              className="underline text-blue-700"
              href="mailto:coralie.desbas@ufe.org"
            >
              coralie.desbas@ufe.org
            </a>{' '}
            afin de créer un nouveau barème.
          </div>
        </Modal>
      ) : (
        <Modal isShowing={isShowing} hide={toggle} title={'Nouveau barème'}>
          <PricePostForm hide={toggle} />
        </Modal>
      )}
    </>
  );
}
