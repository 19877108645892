import { Membership } from '../types/Membership';
import useModal from '../../../components/modals/useModal';
import Modal from '../../../components/modals/Modal';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import {
  useMemberships,
  useMembershipsDispatch,
  useMembershipsFetch,
  useMembershipsLinks,
} from '../contexts/MembershipCollectionContext';
import {
  useAdherent,
  useAdherentDispatch,
} from '../../Adherent/contexts/AdherentContext';
import { toast } from 'react-toastify';

interface Props {
  membership: Membership;
  closeParent: () => void;
}

export default function MembershipDeleteButton(props: Props) {
  const { isShowing, toggle } = useModal();
  const axiosPrivate = useAxiosPrivate();
  const memberships = useMemberships();
  const links = useMembershipsLinks();
  const fetchMemberships = useMembershipsFetch();
  const dispatch = useMembershipsDispatch();
  const adherentDispatch = useAdherentDispatch();
  const adherent = useAdherent();

  function handleClick() {
    if (memberships.length === 1) {
      toast.error('Un adhérent doit toujours posséder au moins une adhésion.', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast
        .promise(
          axiosPrivate.delete(props.membership['@id']),
          {
            pending: 'Suppression en cours...',
            success: 'Supprimé avec succès.',
            error: 'Une erreur est survenue.',
          },
          { position: toast.POSITION.BOTTOM_CENTER },
        )
        .then(
          () => {
            if (
              links?.['@id'] === links?.['hydra:last'] &&
              memberships.length > 1
            ) {
              dispatch({
                type: 'deleted',
                membership: props.membership,
              });
            } else if (
              links?.['@id'] === links?.['hydra:last'] &&
              memberships.length === 1
            ) {
              fetchMemberships(links?.['hydra:previous']);
            } else {
              fetchMemberships(links?.['@id']);
            }
            if (
              adherent.lastMembership &&
              adherent.lastMembership['@id'] === props.membership['@id']
            ) {
              const newMemberships = memberships.filter(
                (membership) => membership['@id'] !== props.membership['@id'],
              );
              const newAdherent = adherent;
              newAdherent.lastMembership = newMemberships[0];
              adherentDispatch({
                type: 'changed',
                adherent: newAdherent,
              });
            }
            props.closeParent();
          },
          (error) => {
            console.log(error);
          },
        );
    }
  }

  return (
    <>
      <button onClick={toggle} className="bg-white border px-2 py-1 mt-5">
        Supprimer
      </button>
      <Modal title={'Supprimer'} isShowing={isShowing} hide={toggle}>
        <div className="flex justify-center">
          <button
            className="bg-red-700 w-60 py-1 text-white"
            onClick={handleClick}
          >
            Supprimer
          </button>
        </div>
      </Modal>
    </>
  );
}
