import React from 'react';

interface Props {
  membershipOrigin: string;
  setMembershipOrigin: (membershipOrigin: string) => void;
}

export default function MembershipOriginFilter(props: Props) {
  return (
    <div>
      <label className="text-sm">Origine de la cotisation</label>
      <select
        name="membershipOrigin"
        className="w-full border"
        value={props.membershipOrigin}
        onChange={(e) => {
          props.setMembershipOrigin(e.target.value);
        }}
      >
        <option value="">---</option>
        <option value="SITE INTERNET">Internet</option>
        <option value="SIEGE">Siège</option>
        <option value="REPRESENTATION">Représentation</option>
      </select>
    </div>
  );
}
