import React, { useState } from 'react';
import close from '../../../assets/images/close.svg';
import edit from '../../../assets/images/editIcon.svg';
import CardPutForm from '../forms/CardPutForm';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';

export default function CardItem() {
  const [isEditable, setEditable] = useState(false);
  const { auth } = useAuth();
  const role = auth?.payload?.roles[0];

  const toggle = () => {
    setEditable(!isEditable);
  };

  return (
    <div className="flex flex-col p-5 w-1/4 border">
      <div className="flex justify-between mb-5">
        <h3>Carte</h3>
        {role !== 'ROLE_SUBSIDIARY' && (
          <img
            src={isEditable ? close : edit}
            alt="edit-icon"
            onClick={toggle}
          />
        )}
      </div>
      <CardPutForm isEditable={isEditable} toggle={toggle} />
    </div>
  );
}
