import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Links } from '../../../types/Links';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { Adherent } from '../types/Adherent';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';
import { useAdherentFilterStore } from '../useAdherentFilterStore';

type Action =
  | { type: 'loaded'; adherents: Adherent[] }
  | { type: 'added'; adherent: Adherent }
  | { type: 'changed'; adherent: Adherent }
  | { type: 'deleted'; adherent: Adherent };

type Dispatch = (action: Action) => void;

type AdherentsStateContentProps = {
  adherents: Adherent[];
  links?: Links;
  totalItems: number;
  itemsPerPage: number;
  isLoading: boolean;
  selectedItems: Adherent[];
  subsidiary?: Subsidiary;
  orderId: string | undefined;
  orderLastName: string | undefined;
};

type AdherentsDispatchContentProps = {
  dispatch: Dispatch;
  setTotalItems: (totalItems: number) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
  fetchAdherents: (url?: string) => void;
  setSelectedItems: (selectedItems: Adherent[]) => void;
  setOrderId: (orderId: string | undefined) => void;
  setOrderLastName: (orderLastName: string | undefined) => void;
};

const AdherentsStateContext = createContext<
  AdherentsStateContentProps | undefined
>(undefined);

const AdherentsDispatchContext = createContext<
  AdherentsDispatchContentProps | undefined
>(undefined);

const adherentsReducer = (adherents: Adherent[], action: Action) => {
  switch (action.type) {
    case 'loaded':
      return action.adherents;
    case 'added':
      return [...adherents, action.adherent];
    case 'changed':
      return adherents.map((adherent) => {
        if (adherent['@id'] === action.adherent['@id']) {
          return action.adherent;
        }
        return adherent;
      });
    case 'deleted':
      return adherents.filter(
        (adherent) => adherent['@id'] !== action.adherent['@id'],
      );
    default:
      throw new Error('Unhandled action type');
  }
};

interface Props {
  subsidiary?: Subsidiary;
  children: ReactNode;
}

export default function AdherentCollectionContext(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const [adherents, dispatch] = useReducer(adherentsReducer, []);
  const [links, setLinks] = useState<Links>();
  const [totalItems, setTotalItems] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Adherent[]>([]);
  const [orderId, setOrderId] = useState<string | undefined>('desc');
  const [orderLastName, setOrderLastName] = useState<string | undefined>();
  const subsidiary = props.subsidiary ? props.subsidiary : undefined;

  const subsidiaryName = useAdherentFilterStore(
    (state) => state.subsidiaryName,
  );
  const adherentNumber = useAdherentFilterStore(
    (state) => state.adherentNumber,
  );
  const lastname = useAdherentFilterStore((state) => state.lastname);
  const firstname = useAdherentFilterStore((state) => state.firstname);
  const email = useAdherentFilterStore((state) => state.email);
  const cardStatus = useAdherentFilterStore((state) => state.cardStatus);
  const cardType = useAdherentFilterStore((state) => state.cardType);
  const membershipsBeforeYear = useAdherentFilterStore(
    (state) => state.membershipsBeforeYear,
  );
  const membershipsAfterYear = useAdherentFilterStore(
    (state) => state.membershipsAfterYear,
  );
  const lastMembershipBeforeYear = useAdherentFilterStore(
    (state) => state.lastMembershipBeforeYear,
  );
  const lastMembershipAfterYear = useAdherentFilterStore(
    (state) => state.lastMembershipAfterYear,
  );
  const priceType = useAdherentFilterStore((state) => state.priceType);
  const membershipOrigin = useAdherentFilterStore(
    (state) => state.membershipOrigin,
  );

  const fetchAdherents = useCallback(
    (url?: string) => {
      if (!url) {
        url = `/adherents?itemsPerPage=${itemsPerPage}&page=1`;
        if (orderLastName) {
          url += `&order[lastname]=${orderLastName}`;
        }
        if (orderId) {
          url += `&order[id]=${orderId}`;
        }
        if (
          subsidiaryName !== '' &&
          membershipsBeforeYear === '' &&
          membershipsAfterYear === ''
        ) {
          url += `&lastMembership.subsidiary.name=${subsidiaryName}`;
        }
        if (
          subsidiaryName !== '' &&
          membershipsBeforeYear !== '' &&
          membershipsAfterYear !== ''
        ) {
          url += `&memberships.subsidiary.name=${subsidiaryName}`;
        }
        if (
          subsidiary &&
          membershipsBeforeYear === '' &&
          membershipsAfterYear === ''
        ) {
          url += `&lastMembership.subsidiary=${subsidiary['@id']}`;
        }
        if (
          subsidiary &&
          membershipsBeforeYear !== '' &&
          membershipsAfterYear !== ''
        ) {
          url += `&memberships.subsidiary=${subsidiary['@id']}`;
        }
        if (adherentNumber !== '') {
          url += `&id=${adherentNumber}`;
        }
        if (firstname !== '') {
          url += `&firstname=${firstname}`;
        }
        if (lastname !== '') {
          url += `&lastname=${lastname}`;
        }
        if (email !== '') {
          url += `&email=${email}`;
        }
        if (cardStatus !== '') {
          url += `&lastMembership.card.status=${cardStatus}`;
        }
        if (cardType !== '') {
          url += `&lastMembership.card.type=${cardType}`;
        }
        if (membershipsBeforeYear !== '') {
          url += `&memberships.endAt[before]=${membershipsBeforeYear}`;
        }
        if (membershipsAfterYear !== '') {
          url += `&memberships.endAt[after]=${membershipsAfterYear}`;
        }
        if (lastMembershipBeforeYear !== '') {
          url += `&lastMembership.endAt[before]=${lastMembershipBeforeYear}`;
        }
        if (lastMembershipAfterYear !== '') {
          url += `&lastMembership.endAt[after]=${lastMembershipAfterYear}`;
        }
        if (priceType !== '') {
          url += `&memberships.priceType=${priceType}`;
        }
        if (membershipOrigin !== '') {
          url += `&lastMembership.origin=${membershipOrigin}`;
        }
      }
      setIsLoading(true);
      axiosPrivate
        .get(url)
        .then(
          (response) => {
            setTotalItems(response.data['hydra:totalItems']);
            setLinks(response?.data['hydra:view']);
            dispatch({
              type: 'loaded',
              adherents: response?.data['hydra:member'],
            });
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => {
          setIsLoading(false);
        });
    },
    [
      axiosPrivate,
      totalItems,
      itemsPerPage,
      email,
      firstname,
      lastname,
      subsidiaryName,
      adherentNumber,
      cardStatus,
      membershipsBeforeYear,
      membershipsAfterYear,
      lastMembershipBeforeYear,
      lastMembershipAfterYear,
      priceType,
      membershipOrigin,
      cardType,
      orderId,
      orderLastName,
    ],
  );

  useEffect(() => {
    fetchAdherents();
  }, [fetchAdherents]);

  return (
    <AdherentsStateContext.Provider
      value={{
        adherents,
        links,
        totalItems,
        itemsPerPage,
        isLoading,
        selectedItems,
        orderId,
        orderLastName,
      }}
    >
      <AdherentsDispatchContext.Provider
        value={{
          dispatch,
          setTotalItems,
          setItemsPerPage,
          setSelectedItems,
          fetchAdherents,
          setOrderId,
          setOrderLastName,
        }}
      >
        {props.children}
      </AdherentsDispatchContext.Provider>
    </AdherentsStateContext.Provider>
  );
}

export function useAdherents() {
  const context = useContext(AdherentsStateContext);
  if (context === undefined) {
    throw new Error('useAdherents must be used within a AdherentsProvider');
  }
  return context.adherents;
}

export function useAdherentsLinks() {
  const context = useContext(AdherentsStateContext);
  if (context === undefined) {
    throw new Error(
      'useAdherentsLinks must be used within a AdherentsProvider',
    );
  }
  return context.links;
}

export function useAdherentsSubsidiary() {
  const context = useContext(AdherentsStateContext);
  if (context === undefined) {
    throw new Error(
      'useAdherentsSubsidiary must be used within a AdherentsProvider',
    );
  }
  return context.subsidiary;
}

export function useAdherentsTotalItems() {
  const context = useContext(AdherentsStateContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiariesTotalItems must be used within a SubsidiariesProvider',
    );
  }
  return context.totalItems;
}

export function useAdherentsItemsPerPage() {
  const context = useContext(AdherentsStateContext);
  if (context === undefined) {
    throw new Error(
      'useAdherentsItemsPerPage must be used within a AdherentsProvider',
    );
  }
  return context.itemsPerPage;
}

export function useAdherentsIsLoading() {
  const context = useContext(AdherentsStateContext);
  if (context === undefined) {
    throw new Error(
      'useAdherentsIsLoading must be used within a AdherentsProvider',
    );
  }
  return context.isLoading;
}

export function useAdherentsSelectedItems() {
  const context = useContext(AdherentsStateContext);
  if (context === undefined) {
    throw new Error(
      'useAdherentsSelectedItems must be used within a AdherentsProvider',
    );
  }
  return context.selectedItems;
}

export function useAdherentsOrderLastName() {
  const context = useContext(AdherentsStateContext);
  if (context === undefined) {
    throw new Error(
      'useAdherentsOrderName must be used within a AdherentsProvider',
    );
  }
  return context.orderLastName;
}

export function useAdherentsOrderId() {
  const context = useContext(AdherentsStateContext);
  if (context === undefined) {
    throw new Error(
      'useAdherentsOrderName must be used within a AdherentsProvider',
    );
  }
  return context.orderId;
}

export function useAdherentsDispatch() {
  const context = useContext(AdherentsDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useAdherentsDispatch must be used within a AdherentsProvider',
    );
  }
  return context.dispatch;
}

export function useAdherentsSetItemsPerPage() {
  const context = useContext(AdherentsDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useAdherentsSetItemsPerPage must be used within a AdherentsProvider',
    );
  }
  return context.setItemsPerPage;
}

export function useFetchAdherents() {
  const context = useContext(AdherentsDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useFetchAdherents must be used within a AdherentsProvider',
    );
  }
  return context.fetchAdherents;
}

export function useAdherentsSetSelectedItems() {
  const context = useContext(AdherentsDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useAdherentsSetSelectedItems must be used within a AdherentsProvider',
    );
  }
  return context.setSelectedItems;
}

export function useAdherentsSetOrderId() {
  const context = useContext(AdherentsDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useAdherentsSetOrderId must be used within a AdherentsProvider',
    );
  }
  return context.setOrderId;
}

export function useAdherentsSetOrderLastName() {
  const context = useContext(AdherentsDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useAdherentsSetOrderLastName must be used within a AdherentsProvider',
    );
  }
  return context.setOrderLastName;
}
