import { create } from 'zustand';
import { Price } from './types/Price';
import { Links } from '../../types/Links';

type State = {
  prices: Price[];
  currentPrices: Price[];
  itemsPerPage: number;
  isLoading: boolean;
  links: Links;
  totalItems: number;
  type: string;
  year: number;
};

type Action = {
  setPrices: (prices: State['prices']) => void;
  setCurrentPrices: (currentPrices: State['currentPrices']) => void;
  setItemsPerPage: (itemsPerPage: State['itemsPerPage']) => void;
  setIsLoading: (isLoading: State['isLoading']) => void;
  setLinks: (links: State['links']) => void;
  addPrice: (price: Price) => void;
  updatePrice: (price: Price) => void;
  removePrice: (price: Price) => void;
  setTotalItems: (totalItems: State['totalItems']) => void;
  setType: (type: State['type']) => void;
  setYear: (year: State['year']) => void;
  resetFilters: () => void;
};

export const usePriceStore = create<State & Action>((set) => ({
  prices: [],
  setPrices: (prices: Price[]) => set({ prices }),
  currentPrices: [],
  setCurrentPrices: (currentPrices: Price[]) => set({ currentPrices }),
  itemsPerPage: 10,
  setItemsPerPage: (itemsPerPage: number) => set({ itemsPerPage }),
  isLoading: false,
  setIsLoading: (isLoading: boolean) => set({ isLoading }),
  links: {
    '@id': '',
    '@type': '',
    'hydra:first': '',
    'hydra:last': '',
    'hydra:previous': '',
    'hydra:next': '',
  },
  setLinks: (links: Links) => set({ links }),
  addPrice: (price: Price) =>
    set((state) => ({ prices: [...state.prices, price] })),
  updatePrice: (price: Price) =>
    set((state) => ({
      prices: state.prices.map((p) => (p['@id'] === price['@id'] ? price : p)),
    })),
  removePrice: (price: Price) =>
    set((state) => ({
      prices: state.prices.filter((p) => p['@id'] !== price['@id']),
    })),
  totalItems: 0,
  setTotalItems: (totalItems: number) => set({ totalItems }),
  type: '',
  setType: (type: string) => set({ type }),
  year: 0,
  setYear: (year: number) => set({ year }),
  resetFilters: () => set({ type: '', year: 0 }),
}));
