import ReactDOM from 'react-dom';

type Props = {
  isShowing: boolean;
  hide: () => void;
  children: any;
  style: any;
  title: string;
};

export default function OnclickPosModal(props: Props) {
  // useEffect(() => {
  //     const checkIfClickOutside = (event: any) => {
  //         if (ref.current && !ref.current.contains(event.target)) {
  //             props.hide();
  //         }
  //     };
  //     document.addEventListener("mousedown", checkIfClickOutside)
  //     return () => {
  //         document.removeEventListener("mousedown", checkIfClickOutside)
  //     }
  // });

  return props.isShowing
    ? ReactDOM.createPortal(
        <div className="fixed top-0 left-0 w-screen h-screen z-2 bg-black-ufe bg-opacity-10">
          <div className="bg-white absolute p-5 w-40" style={props.style}>
            <div className="flex justify-between items-center">
              <h4 className=" text-sm font-bold">{props.title}</h4>
              <button type="button" className="pointer" onClick={props.hide}>
                &times;
              </button>
            </div>
            <div className="pt-1">{props.children}</div>
          </div>
        </div>,
        document.body,
      )
    : null;
}
