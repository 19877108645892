import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CustomLabelCharts from '../labels/CustomLabelCharts';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';
import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';

interface Props {
  chosenSubsidiary: Subsidiary | null;
}

interface Data {
  name: string;
  cotisation: number;
}

export default function SiegeAmountByOriginChart(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState<Data[]>([]);
  // const data = [
  //   {
  //     name: 'Siège',
  //     cotisation: 0,
  //   },
  //   {
  //     name: 'Représentation',
  //     cotisation: 0,
  //   },
  //   {
  //     name: 'Internet',
  //     cotisation: 0,
  //   },
  // ];

  useEffect(() => {
    axiosPrivate
      .get(
        '/financiary/totalsByOrigins' +
          (props.chosenSubsidiary && props.chosenSubsidiary['@id'] !== ''
            ? '?subsidiary=' + props.chosenSubsidiary['@id']
            : ''),
      )
      .then(
        (response) => {
          setData(response?.data);
        },
        (error) => {
          console.log(error);
        },
      );
  }, [axiosPrivate, props.chosenSubsidiary]);

  return (
    <div className="p-6 bg-white w-full">
      <h3>Montant part siège {new Date().getFullYear()} par origine</h3>
      <div className="flex justify-end mt-4 mb-4 items-center">
        <label>Montant dû par le siège à la représentation</label>
        <span className="flex items-center ml-2">
          <input
            type="number"
            value={data.reduce((acc, objet) => acc + objet.cotisation, 0)}
            readOnly={true}
            disabled={true}
            className="border px-2 h-8 mr-1.5"
          />
          €
        </span>
      </div>
      <ResponsiveContainer width="100%" height={175}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 30,
            right: 15,
            bottom: -7,
          }}
        >
          <XAxis dataKey="name" tickLine={false} axisLine={false} />
          <YAxis tickLine={false} axisLine={false} />
          <Tooltip />
          <Bar dataKey="cotisation" fill="#539CAD" barSize={60}>
            <LabelList dataKey="cotisation" content={CustomLabelCharts} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
