import useModal from '../../../components/modals/useModal';
import Modal from '../../../components/modals/Modal';
import AdherentPutForm from '../forms/AdherentPutForm';
import { Adherent } from '../types/Adherent';

interface Props {
  adherent: Adherent;
}

export default function AdherentPutButton(props: Props) {
  const { isShowing, toggle } = useModal();

  return (
    <>
      <button className="bg-white border px-2 py-1" onClick={toggle}>
        Modifier
      </button>
      <Modal isShowing={isShowing} hide={toggle} title={'Mettre à jour'}>
        <AdherentPutForm hide={toggle} adherent={props.adherent} />
      </Modal>
    </>
  );
}
