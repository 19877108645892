import {
  useAdherents,
  useAdherentsIsLoading,
  useAdherentsLinks,
  useAdherentsSelectedItems,
  useAdherentsSetItemsPerPage,
  useAdherentsSetSelectedItems,
  useAdherentsTotalItems,
  useFetchAdherents,
} from '../contexts/AdherentCollectionContext';
import AdherentPostButton from '../buttons/AdherentPostButton';
import AdherentExportButton from '../buttons/AdherentExportButton';
import multipleOptions from '../../../assets/images/multipleOptions.svg';
import { Adherent } from '../types/Adherent';
import AdherentItem from '../items/AdherentItem';
import Pagination from '../../../components/Pagination';
import RowSelector from '../../../components/RowSelector';
import Loader from '../../../components/Loader';
import React from 'react';
import AdherentFilterForm from '../forms/AdherentFilterForm';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';

export default function AdherentCollectionContainer() {
  const adherents = useAdherents();
  const isLoading = useAdherentsIsLoading();
  const links = useAdherentsLinks();
  const fetchAdherents = useFetchAdherents();
  const setItemsPerPage = useAdherentsSetItemsPerPage();
  const selectedItems = useAdherentsSelectedItems();
  const setSelectedItems = useAdherentsSetSelectedItems();
  const totalItems = useAdherentsTotalItems();
  const { auth } = useAuth();
  const role = auth?.payload.roles[0];

  const selectAll = () => {
    if (selectedItems.length === adherents.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(adherents);
    }
  };

  return (
    <div className="flex gap-x-5">
      <div className="w-1/6 h-min p-5 bg-white flex flex-col gap-y-5">
        <AdherentFilterForm />
      </div>
      <div className="bg-white p-5 flex flex-col h-min w-5/6">
        <div className="flex justify-between mb-5">
          <div className="flex gap-x-1">
            <h3 className="font-bold">Adhérents</h3>
            {isLoading ? <Loader /> : null}
          </div>
          <div className="flex gap-x-4">
            <AdherentPostButton />
            <AdherentExportButton />
          </div>
        </div>
        <table className="border text-xs lg:text-base">
          <thead>
            <tr className="bg-green-ufeLight">
              <td className="py-2">
                <button
                  onClick={selectAll}
                  className={
                    selectedItems.length === adherents.length &&
                    adherents.length !== 0
                      ? 'ml-2 w-4 h-4 bg-green-ufe border pointer'
                      : 'ml-2 w-4 h-4 bg-white border pointer'
                  }
                />
              </td>
              <td className="p-2 text-left">Numéro d'adhérent</td>
              <td className="p-2 text-left">Nom</td>
              <td className="p-2 text-left">Prénom</td>
              <td className="p-2 text-left">Email</td>
              <td className="p-2 text-left">Type de barème</td>
              <td className="p-2 text-left">Origine cotisation</td>
              {role !== 'ROLE_SUBSIDIARY' && (
                <td className="p-2 text-left">Représentation</td>
              )}
              <td className="p-2 text-left">Status</td>
              {role !== 'ROLE_SUBSIDIARY' && (
                <td className="p-2 text-left">
                  <img alt="option-icon" src={multipleOptions} />
                </td>
              )}
            </tr>
          </thead>
          <tbody>
            {adherents &&
              adherents.map((element: Adherent, index: number) => {
                return <AdherentItem key={index} adherent={element} />;
              })}
          </tbody>
        </table>
        <div className="mt-5 flex justify-between">
          <Pagination links={links} fetchData={fetchAdherents} />
          <RowSelector
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          />
        </div>
      </div>
    </div>
  );
}
