import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';

const AdherentBySubsidiaryChart = () => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const limit = 4;

  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get(
        '/charts/totalAdherentsBySubsidiary' + (limit ? '?limit=' + limit : ''),
      )
      .then(
        (response) => {
          setData(response.data);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [axiosPrivate]);

  return (
    <div className="p-6 bg-white w-1/2">
      <h3 className="mb-5">
        Nombre d'adhérents par représentation {isLoading && <Loader />}
      </h3>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 30,
            right: 15,
            bottom: -7,
          }}
        >
          <XAxis
            dataKey="name"
            tickLine={false}
            tick={false}
            axisLine={false}
          />
          <YAxis tickLine={false} axisLine={false} allowDecimals={false} />
          <Tooltip />
          <Bar
            name="adhérents"
            dataKey="adherentsCount"
            fill="#539CAD"
            barSize={30}
          >
            <LabelList
              name="adhérents"
              dataKey="adherentsCount"
              fill="#808080"
              position={'top'}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AdherentBySubsidiaryChart;
