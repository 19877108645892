import React, { useState } from 'react';
import { Membership } from '../types/Membership';
import useModal from '../../../components/modals/useModal';
import option from '../../../assets/images/options.svg';
import OnclickPosModal from '../../../components/modals/OnclickPosModal';
import MembershipDeleteButton from './MembershipDeleteButton';
import MembershipPutButton from './MembershipPutButton';

interface Props {
  membership: Membership;
}

export default function MembershipActionButton(props: Props) {
  const { isShowing, toggle } = useModal();
  const [style, setStyle] = useState({});

  const openModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setStyle({ top: e.clientY, left: e.clientX - 167 });
    toggle();
  };

  return (
    <>
      <button
        className="p-2"
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          openModal(e)
        }
      >
        <img alt="option-icon" src={option} className="w-1" />
      </button>
      <OnclickPosModal
        isShowing={isShowing}
        hide={toggle}
        style={style}
        title={'Actions'}
      >
        <div className="flex flex-col">
          <MembershipPutButton
            membership={props.membership}
            closeParent={toggle}
          />
          <MembershipDeleteButton
            membership={props.membership}
            closeParent={toggle}
          />
        </div>
      </OnclickPosModal>
    </>
  );
}
