import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';

interface Props {
  chosenSubsidiary: Subsidiary | null;
}

const ContributionCountChart = (props: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);

  useEffect(() => {
    axiosPrivate
      .get(
        '/financiary/membershipsByOrigin' +
          (props.chosenSubsidiary && props.chosenSubsidiary['@id'] !== ''
            ? '?subsidiary=' + props.chosenSubsidiary['@id']
            : ''),
      )
      .then(
        (response) => {
          setData(response?.data);
        },
        (error) => {
          console.log(error);
        },
      );
  }, [axiosPrivate, props.chosenSubsidiary]);

  return (
    <div className="p-6 bg-white w-full">
      <h3>Nombre de cotisation {new Date().getFullYear()} par origine</h3>
      <ResponsiveContainer width="100%" height={175}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 30,
            right: 15,
            bottom: -7,
          }}
        >
          <XAxis dataKey="name" tickLine={false} axisLine={false} />
          <YAxis tickLine={false} axisLine={false} />
          <Tooltip />
          <Bar dataKey="cotisation" fill="#539CAD" barSize={60}>
            <LabelList dataKey="cotisation" fill="#808080" position={'top'} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ContributionCountChart;
