import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { Adherent } from '../types/Adherent';
import { useAdherentDispatch } from '../contexts/AdherentContext';

interface Values {
  comment: string;
}

interface Props {
  adherent: Adherent;
}

const ValidationSchema = Yup.object().shape({
  comment: Yup.string()
    .min(2, 'Le commentaire est trop court')
    .max(255, 'Le commentaire est trop long'),
});

const AdherentCommentForm = (props: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const adherentDispatch = useAdherentDispatch();
  return (
    <Formik
      initialValues={{
        comment: props.adherent.comment,
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        toast
          .promise(
            axiosPrivate.put(props.adherent['@id'], values),
            {
              pending: 'Modification du commentaire en cours...',
              success: 'Le commentaire a bien enregistré',
              error: 'Une erreur est survenue.',
            },
            { position: toast.POSITION.BOTTOM_CENTER },
          )
          .then(
            (response) => {
              adherentDispatch({
                type: 'changed',
                adherent: response.data,
              });
            },
            (error) => {
              console.log(error);
            },
          )
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched }) => (
        <Form className="w-full mx-2">
          <div>
            <Field
              as="textarea"
              id="comment"
              name="comment"
              rows={18}
              minLength={2}
              maxLength={255}
              placeholder="Veuillez saisir votre message..."
              wrap="hard"
              className="p-2 border w-full"
            />
            <p className="text-sm text-red-700 mt-1">
              {errors.comment && touched.comment ? errors.comment : null}
            </p>
          </div>
          <button type="submit" className="px-2 py-1 text-white bg-green-ufe">
            Enregistrer
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default AdherentCommentForm;
