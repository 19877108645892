import { useSubsidiariesSelectedItems } from '../contexts/SubsidiaryCollectionContext';
import { toast } from 'react-toastify';
import { Subsidiary } from '../types/Subsidiary';
import { utils, writeFile } from 'xlsx';

interface Props {
  hide: () => void;
}

export default function SubsidiaryExportSelectionButton(props: Props) {
  const subsidiaries = useSubsidiariesSelectedItems();

  const exportSelectedSubsidiaries = () => {
    if (subsidiaries.length !== 0) {
      multiExportFormat(subsidiaries);
    } else {
      toast.warning('Vous devez sélectionner au moins une représentation.', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    props.hide();
  };

  const multiExportFormat = (subsidiaries: Subsidiary[]) => {
    const actualDateTime = new Date();
    const formatActualDateTime =
      actualDateTime.getFullYear() +
      '-' +
      actualDateTime.getMonth() +
      '-' +
      actualDateTime.getDay() +
      '-' +
      actualDateTime.getHours() +
      ':' +
      actualDateTime.getMinutes();

    const exportFileData: (string | undefined)[][] = [];
    subsidiaries.forEach((subsidiary) => {
      let presidentName,
        presidentEmail,
        treasurerName,
        treasurerEmail = '';

      subsidiary.employees.map((employee) => {
        switch (employee.title) {
          case 'Président':
            presidentName =
              employee.adherent.firstname + ' ' + employee.adherent.lastname;
            presidentEmail = employee.adherent.email;
            break;
          case 'Trésorier':
            treasurerName =
              employee.adherent.firstname + ' ' + employee.adherent.lastname;
            treasurerEmail = employee.adherent.email;
            break;
        }
        return null;
      });
      const targetSubsidiary = [
        subsidiary.name,
        subsidiary.email,
        subsidiary.phone,
        presidentName,
        presidentEmail,
        treasurerName,
        treasurerEmail,
      ];
      exportFileData.push(targetSubsidiary);
    });

    const headerXLSX = [
      'Nom',
      'Email',
      'Téléphone',
      'Nom du président',
      'Email du président',
      'Nom du trésorier',
      'Email du trésorier',
    ];
    exportFileData.unshift(headerXLSX);

    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet(exportFileData);
    utils.book_append_sheet(wb, ws, 'Adhérents');
    writeFile(wb, 'representation-' + formatActualDateTime + '.xlsx', {
      bookType: 'xlsx',
      type: 'binary',
    });
  };

  return (
    <button
      className="bg-white border px-2 py-1"
      onClick={exportSelectedSubsidiaries}
    >
      Exporter la selection
    </button>
  );
}
