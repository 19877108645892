import React from 'react';
import { Field } from 'formik';

interface Props {
  errors: string | undefined;
  touched: boolean | undefined;
}

export default function LastnameFilter(props: Props) {
  return (
    <div>
      <label className="text-sm">Nom</label>
      <div className="flex items-center relative">
        <Field className="w-full border pl-1" name="lastname" type="text" />
      </div>
      {props.errors && props.touched ? (
        <div className="text-sm text-red-700 mt-1">{props.errors}</div>
      ) : null}
    </div>
  );
}
