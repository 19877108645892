import useModal from '../../../components/modals/useModal';
import SubsidiaryPutForm from '../forms/SubsidiaryPutForm';
import Modal from '../../../components/modals/Modal';

export default function SubsidiaryPutButton() {
  const { isShowing, toggle } = useModal();

  return (
    <>
      <button className="bg-white border px-2 py-1" onClick={toggle}>
        Modifier
      </button>
      <Modal isShowing={isShowing} hide={toggle} title={'Mettre à jour'}>
        <SubsidiaryPutForm hide={toggle} />
      </Modal>
    </>
  );
}
