import * as ReactDOM from 'react-dom';

interface Props {
  isShowing: boolean;
  hide: () => void;
  children: any;
  title: string;
}

export default function Modal(props: Props) {
  return props.isShowing
    ? ReactDOM.createPortal(
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black-ufe bg-opacity-50">
          <div className="bg-white relative">
            <div className="flex justify-between items-center border-b p-5">
              <h4 className="mr-2">{props.title ? props.title : 'Title'}</h4>
              <button
                className="pointer flex items-center justify-center"
                onClick={props.hide}
              >
                &times;
              </button>
            </div>
            <div className="p-5">{props.children}</div>
          </div>
        </div>,
        document.body,
      )
    : null;
}
