import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { utils, writeFile } from 'xlsx';
import { AdherentExport } from '../types/AdherentExport';
import { useUser } from '../../User/contexts/UserContextProvider';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAdherentFilterStore } from '../useAdherentFilterStore';

interface Props {
  hide: () => void;
}

export default function AdherentExportAllButton(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const user = useUser();
  const { auth } = useAuth();
  const role = auth?.payload?.roles[0];
  const params = useParams();

  const subsidiaryName = useAdherentFilterStore(
    (state) => state.subsidiaryName,
  );
  const adherentNumber = useAdherentFilterStore(
    (state) => state.adherentNumber,
  );
  const lastname = useAdherentFilterStore((state) => state.lastname);
  const firstname = useAdherentFilterStore((state) => state.firstname);
  const email = useAdherentFilterStore((state) => state.email);
  const cardStatus = useAdherentFilterStore((state) => state.cardStatus);
  const cardType = useAdherentFilterStore((state) => state.cardType);
  const membershipsBeforeYear = useAdherentFilterStore(
    (state) => state.membershipsBeforeYear,
  );
  const membershipsAfterYear = useAdherentFilterStore(
    (state) => state.membershipsAfterYear,
  );
  const lastMembershipBeforeYear = useAdherentFilterStore(
    (state) => state.lastMembershipBeforeYear,
  );
  const lastMembershipAfterYear = useAdherentFilterStore(
    (state) => state.lastMembershipAfterYear,
  );
  const membershipOrigin = useAdherentFilterStore(
    (state) => state.membershipOrigin,
  );

  let subsidiaryIri = '';
  if (params.subsidiaryId) {
    subsidiaryIri = '/subsidiaries/' + params.subsidiaryId;
  } else {
    subsidiaryIri =
      role === 'ROLE_SUBSIDIARY' && user?.subsidiary
        ? user.subsidiary['@id']
        : '';
  }

  const exportAllAdherents = () => {
    let url = '/export/adherents';
    let existingParams = false;
    if (subsidiaryIri !== '') {
      if (!existingParams) {
        url += '?';
      } else {
        url += '&';
      }
      url += 'subsidiaryIri=' + subsidiaryIri;
      existingParams = true;
    }
    if (subsidiaryName) {
      if (!existingParams) {
        url += '?';
      } else {
        url += '&';
      }
      url += `subsidiaryName=${subsidiaryName}`;
      existingParams = true;
    }
    if (adherentNumber) {
      if (!existingParams) {
        url += '?';
      } else {
        url += '&';
      }
      url += `adherentNumber=${adherentNumber}`;
      existingParams = true;
    }
    if (lastname) {
      if (!existingParams) {
        url += '?';
      } else {
        url += '&';
      }
      url += `lastname=${lastname}`;
      existingParams = true;
    }
    if (firstname) {
      if (!existingParams) {
        url += '?';
      } else {
        url += '&';
      }
      url += `firstname=${firstname}`;
      existingParams = true;
    }
    if (email) {
      if (!existingParams) {
        url += '?';
      } else {
        url += '&';
      }
      url += `email=${email}`;
      existingParams = true;
    }
    if (cardStatus) {
      if (!existingParams) {
        url += '?';
      } else {
        url += '&';
      }
      url += `cardStatus=${cardStatus}`;
      existingParams = true;
    }
    if (cardType) {
      if (!existingParams) {
        url += '?';
      } else {
        url += '&';
      }
      url += `cardType=${cardType}`;
      existingParams = true;
    }
    if (membershipsBeforeYear !== '' && membershipsAfterYear !== '') {
      if (!existingParams) {
        url += '?';
      } else {
        url += '&';
      }
      url += `membershipsBeforeYear=${membershipsBeforeYear}&membershipsAfterYear=${membershipsAfterYear}`;
      existingParams = true;
    }
    if (lastMembershipBeforeYear !== '' && lastMembershipAfterYear === '') {
      if (!existingParams) {
        url += '?';
      } else {
        url += '&';
      }
      url += `lastMembershipBeforeYear=${lastMembershipBeforeYear}`;
      existingParams = true;
    }
    if (lastMembershipBeforeYear !== '' && lastMembershipAfterYear !== '') {
      if (!existingParams) {
        url += '?';
      } else {
        url += '&';
      }
      url += `lastMembershipBeforeYear=${lastMembershipBeforeYear}&lastMembershipAfterYear=${lastMembershipAfterYear}`;
      existingParams = true;
    }
    if (membershipOrigin) {
      if (!existingParams) {
        url += '?';
      } else {
        url += '&';
      }
      url += `membershipOrigin=${membershipOrigin}`;
    }
    axiosPrivate.get(url).then(
      (response) => {
        exportXLSX(response?.data);
      },
      () => {
        toast.warning('Une erreur est survenue.', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      },
    );
    props.hide();
  };

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${day}-${month}-${year}`;
  }

  const exportXLSX = (adherents: AdherentExport[]) => {
    const exportFileData: (string | number)[][] = [];

    const headerXLSX = [
      'Numéro',
      'Prénom',
      'Nom',
      'Téléphone',
      'Email',
      'Représentation',
      'Date de création',
      "Numéro d'adresse",
      'Rue',
      "Complément d'adresse",
      'Ville',
      'État',
      'Code postal',
      'Pays',
      'Début du dernier abonnement',
      'Montant locale',
      'Montant Siège',
      'Montant total',
      'Origine cotisation',
      'Type de prix',
      'Type de carte',
      'Statut de la carte',
    ];
    if (role !== 'ROLE_SUBSIDIARY') {
      headerXLSX.push("Date de fin d'abonnement");
    } else {
      headerXLSX.push('Statut');
    }
    exportFileData.push(headerXLSX);

    adherents.forEach((adherent) => {
      const createdAt = new Date(adherent.createdAt?.date);
      const beginAt =
        adherent.beginAt && adherent.beginAt.date
          ? new Date(adherent.beginAt.date)
          : '';
      const endAt = new Date(adherent.endAt?.date);

      const targetAdherent = [
        adherent.id,
        adherent.firstname,
        adherent.lastname,
        adherent.phone,
        adherent.email,
        adherent.subsidiaryName,
        formatDate(createdAt),
        adherent.number,
        adherent.street,
        adherent.complement,
        adherent.city,
        adherent.state,
        adherent.zipCode,
        adherent.country,
        beginAt !== '' ? formatDate(beginAt) : '',
        adherent.localAmount,
        adherent.siegeAmount,
        adherent.localAmount + adherent.siegeAmount,
        adherent.origin,
        adherent.priceType,
        adherent.cardType,
        adherent.cardStatus,
      ];
      if (role !== 'ROLE_SUBSIDIARY') {
        targetAdherent.push(formatDate(endAt));
      } else {
        targetAdherent.push(
          endAt && new Date() < endAt ? 'À jour' : 'À renouveler',
        );
      }
      exportFileData.push(targetAdherent);
    });

    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet(exportFileData);
    utils.book_append_sheet(wb, ws, 'Adhérents');
    writeFile(wb, 'adherents-' + formatDate(new Date()) + '.xlsx', {
      bookType: 'xlsx',
      type: 'binary',
    });
  };

  return (
    <button
      className="bg-white border p-2 text-sm"
      onClick={exportAllAdherents}
    >
      Exporter tous les adhérents filtrés
    </button>
  );
}
