import { useState } from 'react';
import useModal from '../../../components/modals/useModal';
import Modal from '../../../components/modals/Modal';
import closeIcon from '../../../assets/images/close.svg';
import { Employee } from '../types/Employee';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { useEmployeesDispatch } from '../contexts/EmployeeCollectionContext';

interface Props {
  employee: Employee;
}

export default function EmployeeDeleteButton(props: Props) {
  const { isShowing, toggle } = useModal();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useEmployeesDispatch();

  const deleteEntity = () => {
    if (!isSubmitting) {
      toast
        .promise(
          axiosPrivate.delete(props.employee['@id']),
          {
            pending: 'Suppression en cours...',
            success: 'Supprimé avec succès.',
            error: 'Une erreur est survenue.',
          },
          { position: toast.POSITION.BOTTOM_CENTER },
        )
        .then(
          () => {
            dispatch({ type: 'deleted', employee: props.employee });
            toggle();
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <>
      <button onClick={toggle} className="close-btn">
        <img src={closeIcon} alt={closeIcon} />
      </button>
      <Modal
        title={'Êtes-vous sûr de vouloir supprimer cet employé(e) ?'}
        isShowing={isShowing}
        hide={toggle}
      >
        <div className="text-center">
          <button
            className="bg-red-700 w-60 py-1 text-white"
            onClick={deleteEntity}
          >
            Supprimer
          </button>
        </div>
      </Modal>
    </>
  );
}
