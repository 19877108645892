import { Field } from 'formik';
import React from 'react';

interface Props {
  errors: string | undefined;
  touched: boolean | undefined;
}

export default function EmailFilter(props: Props) {
  return (
    <div>
      <label className="text-sm">Email</label>
      <div className="flex items-center relative">
        <Field className="w-full border pl-1" name="email" type="text" />
      </div>
      {props.errors && props.touched ? (
        <div className="text-sm text-red-700 mt-1">{props.errors}</div>
      ) : null}
    </div>
  );
}
