import useModal from '../../../components/modals/useModal';
import Modal from '../../../components/modals/Modal';
import { useAuth } from '../contexts/AuthContextProvider';

export default function LogoutButton() {
  const { isShowing, toggle } = useModal();
  const { setAuth } = useAuth();

  const handleClick = () => {
    setAuth(null);
  };

  return (
    <>
      <button
        className="pointer text-sm"
        onClick={toggle}
        title="Se déconnecter"
      >
        Déconnexion
      </button>
      <Modal isShowing={isShowing} hide={toggle} title={'Êtes-vous sûr ?'}>
        <button
          className="px-2 py-1.5 text-white bg-red-700 w-72"
          onClick={handleClick}
          title="Se déconnecter"
        >
          Oui
        </button>
      </Modal>
    </>
  );
}
