import { Donation } from '../types/Donation';
import useModal from '../../../components/modals/useModal';
import Modal from '../../../components/modals/Modal';
import DonationPutForm from '../forms/DonationPutForm';

interface Props {
  donation: Donation;
  closeParent: () => void;
}

export default function DonationPutButton(props: Props) {
  const { isShowing, toggle } = useModal();

  return (
    <>
      <button onClick={toggle} className="bg-white border px-2 py-1 mt-4">
        Modifier
      </button>
      <Modal isShowing={isShowing} hide={toggle} title={'Modifier la donation'}>
        <DonationPutForm hide={toggle} donation={props.donation} />
      </Modal>
    </>
  );
}
