import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';

interface Props {
  chosenSubsidiary: Subsidiary | null;
}

interface Data {
  normalParticulierMemberships: number;
  normalConjointMemberships: number;
}

const NormalMembershipChart = (props: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState<Data>({
    normalParticulierMemberships: 0,
    normalConjointMemberships: 0,
  });

  useEffect(() => {
    axiosPrivate
      .get(
        '/financiary/normalMemberships' +
          (props.chosenSubsidiary && props.chosenSubsidiary['@id'] !== ''
            ? '?subsidiary=' + props.chosenSubsidiary['@id']
            : ''),
      )
      .then(
        (response) => {
          setData(response?.data);
        },
        (error) => {
          console.log(error);
        },
      );
  }, [axiosPrivate, props.chosenSubsidiary]);

  return (
    <div className="p-6 bg-white w-full">
      <h3>Nombre de cotisation normales {new Date().getFullYear()}</h3>
      <div className="mt-5">
        {data?.normalParticulierMemberships + data?.normalConjointMemberships}{' '}
        dont {data?.normalConjointMemberships} conjoints
      </div>
    </div>
  );
};

export default NormalMembershipChart;
