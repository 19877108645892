import { Links } from '../types/Links';

interface Props {
  links: Links | undefined;
  fetchData: (url: string | undefined) => void;
}

export default function Pagination(props: Props) {
  return (
    <div className="flex gap-x-1">
      {props.links?.['hydra:first'] &&
        props.links?.['hydra:first'] !== props.links?.['@id'] && (
          <button
            className="bg-gray-200 hover:bg-gray-300 text-gray-600 py-1 px-2"
            onClick={() => props.fetchData(props.links?.['hydra:first'])}
          >
            1
          </button>
        )}
      {props.links?.['hydra:previous'] &&
        props.links?.['hydra:previous'] !== props.links?.['hydra:first'] && (
          <button
            className="bg-gray-200 hover:bg-gray-300 text-gray-600 py-1 px-2"
            onClick={() => props.fetchData(props.links?.['hydra:previous'])}
          >
            {new URLSearchParams(props.links?.['hydra:previous']).get('page')}
          </button>
        )}
      {props.links?.['@id'] &&
        parseInt(
          new URLSearchParams(props.links?.['hydra:last']).get(
            'page',
          ) as string,
        ) > 1 && (
          <button
            className="bg-gray-400 text-gray-800 py-1 px-2"
            onClick={() => props.fetchData(props.links?.['@id'])}
          >
            {new URLSearchParams(props.links?.['@id']).get('page')}
          </button>
        )}
      {props.links?.['hydra:next'] &&
        props.links?.['hydra:next'] !== props.links?.['hydra:last'] && (
          <button
            className="bg-gray-200 hover:bg-gray-300 text-gray-600 py-1 px-2"
            onClick={() => props.fetchData(props.links?.['hydra:next'])}
          >
            {new URLSearchParams(props.links?.['hydra:next']).get('page')}
          </button>
        )}
      {props.links?.['hydra:last'] &&
        props.links?.['hydra:last'] !== props.links?.['@id'] && (
          <button
            className="bg-gray-200 hover:bg-gray-300 text-gray-600 py-1 px-2"
            onClick={() => props.fetchData(props.links?.['hydra:last'])}
          >
            {new URLSearchParams(props.links?.['hydra:last']).get('page')}
          </button>
        )}
    </div>
  );
}
