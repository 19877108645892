import { useState } from 'react';
import useModal from '../../../components/modals/useModal';
import Modal from '../../../components/modals/Modal';
import { Price } from '../types/Price';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { usePriceStore } from '../usePriceStore';

interface Props {
  price: Price;
  closeParent: () => void;
}

export default function PriceDeleteButton(props: Props) {
  const { isShowing, toggle } = useModal();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const removePrice = usePriceStore((state) => state.removePrice);

  const deleteEntity = () => {
    if (!isSubmitting) {
      toast
        .promise(
          axiosPrivate.delete(props.price['@id']),
          {
            pending: 'Suppression en cours...',
            success: 'Supprimé avec succès.',
            error: 'Une erreur est survenue.',
          },
          { position: toast.POSITION.BOTTOM_CENTER },
        )
        .then(
          () => {
            removePrice(props.price);
            props.closeParent();
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <>
      <button
        onClick={toggle}
        className="bg-white border px-2 py-1 mt-5 text-sm"
      >
        Supprimer
      </button>
      <Modal title={'Êtes-vous sûr ?'} isShowing={isShowing} hide={toggle}>
        <button
          className="bg-red-700 w-60 py-1 text-white"
          onClick={deleteEntity}
        >
          Supprimer
        </button>
      </Modal>
    </>
  );
}
