import useModal from '../../../components/modals/useModal';
import exportIcon from '../../../assets/images/export.svg';
import Modal from '../../../components/modals/Modal';
import AdherentExportSelectionButton from './AdherentExportSelectionButton';
import AdherentExportAllButton from './AdherentExportAllButton';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';

export default function AdherentExportButton() {
  const { isShowing, toggle } = useModal();
  const { auth } = useAuth();
  const role = auth?.payload.roles[0];

  return (
    <>
      <button
        className="bg-white border p-2 flex items-center text-sm"
        onClick={toggle}
      >
        <img alt="export-button-icon" src={exportIcon} className="mr-2" />
        Exporter les données
      </button>
      <Modal isShowing={isShowing} hide={toggle} title={'Exporter des données'}>
        <div className="flex flex-col gap-y-4 max-w-md">
          <p className="text-sm">
            Attention, l'export de données hors de l'intranet engage la
            responsabilité
            {role === 'ROLE_SIEGE'
              ? ' du siège.'
              : ' de la représentation locale liée a ce compte. '}
            Les conséquences possibles liées a l'export de données devront donc
            être assumées pleinement par la representation locale liée à ce
            compte (protection des données et respect RGPD).
          </p>
          <AdherentExportSelectionButton hide={toggle} />
          <AdherentExportAllButton hide={toggle} />
        </div>
      </Modal>
    </>
  );
}
