import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CustomLabelCharts from '../labels/CustomLabelCharts';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';

interface Props {
  chosenSubsidiary: Subsidiary | null;
  parentParentStayAmount: number | undefined;
  parentParentStayAmountSiege: number | undefined;
}

export default function ContributionAmountChart(props: Props) {
  // const axiosPrivate = useAxiosPrivate();
  // const [data, setData] = useState([]);
  const data = [
    {
      name: 'Montant restant à payer par le siège à la représentation',
      part: props.parentParentStayAmount || 0,
    },
    {
      name: 'Montant restant à payer par la représentation au siège',
      part: props.parentParentStayAmountSiege || 0,
    },
  ];

  // useEffect(() => {
  //   axiosPrivate
  //     .get(
  //       '/financiary/financialBalance' +
  //         (props.chosenSubsidiary && props.chosenSubsidiary['@id'] !== ''
  //           ? '?subsidiary=' + props.chosenSubsidiary['@id']
  //           : ''),
  //     )
  //     .then(
  //       (response) => {
  //         setData(response?.data);
  //       },
  //       (error) => {
  //         console.log(error);
  //       },
  //     );
  // }, [axiosPrivate, props.chosenSubsidiary]);

  return (
    <div className="p-6 bg-white w-full">
      <h3>Equilibre financier à jour</h3>
      <ResponsiveContainer width="100%" height={175}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 30,
            right: 15,
            bottom: -7,
          }}
        >
          <XAxis dataKey="name" tickLine={false} axisLine={false} />
          <YAxis tickLine={false} axisLine={false} />
          <Tooltip />
          <Bar dataKey="part" fill="#539CAD" barSize={60}>
            <LabelList dataKey="part" content={CustomLabelCharts} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
