import { Field, Form, Formik, FormikHelpers } from 'formik';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { toast } from 'react-toastify';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';
import {
  useAdherent,
  useAdherentDispatch,
} from '../../Adherent/contexts/AdherentContext';

interface Values {
  type: string;
  status: string;
  membership: string;
}

type Props = {
  isEditable: boolean;
  toggle: () => void;
};

export default function CardPutForm(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const role = auth?.payload?.roles[0];
  const adherent = useAdherent();
  const adherentDispatch = useAdherentDispatch();

  return (
    <Formik
      initialValues={{
        type: adherent.lastMembership?.card?.type
          ? adherent.lastMembership?.card?.type
          : '',
        status: adherent.lastMembership?.card?.status
          ? adherent.lastMembership?.card?.status
          : '',
        membership:
          adherent.lastMembership && adherent.lastMembership['@id']
            ? adherent.lastMembership['@id']
            : '',
      }}
      enableReinitialize={true}
      onSubmit={async (
        values: Values,
        { setSubmitting }: FormikHelpers<Values>,
      ) => {
        if (!adherent?.lastMembership?.card) {
          toast
            .promise(
              axiosPrivate.post('/cards', values),
              {
                pending: 'Modification...',
                success: 'Succès.',
                error: 'Une erreur est survenue.',
              },
              { position: toast.POSITION.BOTTOM_CENTER },
            )
            .then((response) => {
              if (adherent.lastMembership) {
                adherent.lastMembership.card = response.data;
              }
              adherentDispatch({
                type: 'changed',
                adherent: adherent,
              });
              props.toggle();
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }

        if (adherent.lastMembership && adherent?.lastMembership?.card) {
          toast
            .promise(
              axiosPrivate.put(adherent.lastMembership.card['@id'], values), // (pas de card id lorsque non renseigné)
              {
                pending: 'Modification...',
                success: 'Succès.',
                error: 'Une erreur est survenue.',
              },
              { position: toast.POSITION.BOTTOM_CENTER },
            )
            .then((response) => {
              if (adherent.lastMembership) {
                adherent.lastMembership.card = response.data;
              }
              adherentDispatch({
                type: 'changed',
                adherent: adherent,
              });
              props.toggle();
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }
      }}
    >
      <Form className="flex flex-col gap-y-5">
        <div className="flex flex-col">
          <label>N°Adhérent</label>
          <div className="border px-2 flex items-center h-8 bg-gray-200">
            {adherent?.id}
          </div>
        </div>
        <div className="flex flex-col">
          <label>Type de carte</label>
          <Field
            as="select"
            name="type"
            id="type"
            disabled={
              role === 'ROLE_SUBSIDIARY' ? 'disabled' : !props.isEditable
            }
            type="text"
            placeholder="Type de carte"
            className={
              role === 'ROLE_SUBSIDIARY'
                ? 'border px-1 flex items-center h-8 bg-gray-200'
                : 'border px-1 flex items-center h-8'
            }
          >
            {adherent?.lastMembership?.card ? (
              <>
                <option value="UFE">UFE</option>
                <option value="Ami de l'UFE">Amis de l’UFE</option>
                <option value="UFE Avenir">UFE Avenir</option>
                <option value="Carte d'Honneur">Carte d’honneur</option>
              </>
            ) : (
              <>
                <option value="">Aucune carte attachée</option>
                <option value="UFE">UFE</option>
                <option value="Ami de l'UFE">Amis de l’UFE</option>
                <option value="UFE Avenir">UFE Avenir</option>
                <option value="Carte d'Honneur">Carte d’honneur</option>
              </>
            )}
          </Field>
        </div>
        {adherent?.lastMembership?.subsidiary.name !== 'PORTUGAL.ALGARVE' ? (
          <div className="flex flex-col">
            <label>Statut</label>
            <Field
              as="select"
              name="status"
              id="status"
              disabled={
                role === 'ROLE_SUBSIDIARY' ? 'disabled' : !props.isEditable
              }
              type="text"
              placeholder="Statut"
              className={
                role === 'ROLE_SUBSIDIARY'
                  ? 'border px-1 flex items-center h-8 bg-gray-200'
                  : 'border px-1 flex items-center h-8'
              }
            >
              {adherent?.lastMembership?.card ? (
                <>
                  <option value="CREATED">À IMPRIMER</option>
                  <option value="SENT">IMPRIMÉE</option>
                </>
              ) : (
                <>
                  <option value="">Aucune carte attachée</option>
                  <option value="CREATED">À IMPRIMER</option>
                  <option value="SENT">IMPRIMÉE</option>
                </>
              )}
            </Field>
          </div>
        ) : null}
        {props.isEditable && (
          <button className="px-2 py-1 text-white bg-green-ufe" type="submit">
            Mettre à jour
          </button>
        )}
      </Form>
    </Formik>
  );
}
