import * as Yup from 'yup';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { Adherent } from '../types/Adherent';
import { useAdherentDispatch } from '../contexts/AdherentContext';

interface Values {
  phone: string;
  firstname: string;
  lastname: string;
  email: string;
}

interface Props {
  adherent: Adherent;
  hide: () => void;
}

export default function AdherentPutForm(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const adherentDispatch = useAdherentDispatch();

  const ValidationSchema = Yup.object().shape({
    phone: Yup.string()
      .min(2, 'Votre téléphone doit avoir un minimum de 2 caractères.')
      .max(50, 'Votre téléphone doit avoir un maximum de 50 caractères.'),
    firstname: Yup.string()
      .min(2, 'Votre prénom doit avoir un minimum de 2 caractère.')
      .max(50, 'Votre prénom doit avoir un maximum de 50 caractère.'),
    lastname: Yup.string()
      .min(2, 'Votre nom doit avoir un minimum de 2 caractère.')
      .max(50, 'Votre nom doit avoir un maximum de 50 caractère.'),
    email: Yup.string().email("Le format de votre email n'est pas valide."),
  });

  return (
    <Formik
      initialValues={{
        phone: props.adherent.phone,
        firstname: props.adherent.firstname,
        lastname: props.adherent.lastname,
        email: props.adherent.email,
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        toast
          .promise(
            axiosPrivate.put(props.adherent['@id'], values),
            {
              pending: 'Modification en cours...',
              success: "Modification de l'adhérent réussie.",
              error: 'Une erreur est survenue.',
            },
            { position: toast.POSITION.BOTTOM_CENTER },
          )
          .then(
            (response) => {
              adherentDispatch({
                type: 'changed',
                adherent: response.data,
              });
              props.hide();
            },
            (error) => {
              console.log(error);
            },
          )
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched }) => (
        <Form className="flex flex-col gap-y-5 w-80">
          <div className="flex flex-col">
            <label>Nom</label>
            <Field
              name="lastname"
              placeholder="Nom"
              className="border px-2 flex items-center h-8"
            />
            {errors?.lastname && touched?.lastname ? (
              <p className="text-sm text-red-700 mt-1">{errors?.lastname}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label>Prénom</label>
            <Field
              name="firstname"
              placeholder="Prénom"
              className="border px-2 flex items-center h-8"
            />
            {errors?.firstname && touched?.firstname ? (
              <p className="text-sm text-red-700 mt-1">{errors?.firstname}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label>Email</label>
            <Field
              name="email"
              placeholder="Email"
              className="border px-2 flex items-center h-8"
            />
            {errors?.email && touched?.email ? (
              <p className="text-sm text-red-700 mt-1">{errors?.email}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label>Téléphone</label>
            <Field
              name="phone"
              placeholder="Téléphone"
              className="border px-2 flex items-center h-8"
            />
            {errors.phone && touched.phone ? (
              <p className="text-sm text-red-700 mt-1">{errors.phone}</p>
            ) : null}
          </div>
          <button
            type="submit"
            className="px-2 py-1 text-white bg-green-ufe w-full"
          >
            Modifier
          </button>
        </Form>
      )}
    </Formik>
  );
}
