import React, { SyntheticEvent, useState } from 'react';
import AdherentAddressPutForm from '../../modules/AdherentAddress/forms/AdherentAddressPutForm';
import { useAuth } from '../../modules/Auth/contexts/AuthContextProvider';
import {
  useAdherent,
  useAdherentIsLoading,
} from '../../modules/Adherent/contexts/AdherentContext';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import AdherentPutButton from '../../modules/Adherent/buttons/AdherentPutButton';
import chevron from '../../assets/images/chevron-left.svg';
import emailImg from '../../assets/images/email.svg';
import phoneImg from '../../assets/images/phone.svg';
import AdherentCommentForm from '../../modules/Adherent/forms/AdherentCommentForm';
import DonationProvider from '../../modules/Donation/contexts/DonationProvider';
import DonationContainer from '../../modules/Donation/containers/DonationContainer';
import AdherentDetailsCard from '../../modules/Adherent/containers/AdherentDetailsCard';
import CardItem from '../../modules/Card/items/CardItem';
import MembershipCollectionContext from '../../modules/Membership/contexts/MembershipCollectionContext';
import MembershipsContainer from '../../modules/Membership/containers/MembershipsContainer';
import { Partner } from '../../modules/Adherent/types/Partner';

export default function AdherentPage() {
  const adherent = useAdherent();
  const isLoading = useAdherentIsLoading();
  const { auth } = useAuth();
  const role = auth?.payload.roles[0];
  const [tab, setTab] = useState(1);
  const [warningMessage, setWarningMessage] = useState(true);
  const navigate = useNavigate();

  // TODO: Vérifier l'actualisation du conjoint sur les autres onglet de l'adhérent avant de basculer l'initialisation de l'état partner sur un enfant.
  const [partner, setPartner] = useState<Partner | null>(() => {
    if (adherent.partner) {
      return {
        number: adherent.partner.id,
        firstname: adherent.partner.firstname,
        lastname: adherent.partner.lastname,
      };
    }
    return null;
  });

  const handleTabSelect = (e: SyntheticEvent): void => {
    setTab(parseInt((e.target as HTMLInputElement).value));
  };

  function handleDateFormat(date: Date | undefined) {
    if (!date) return 'Non renseignée';
    const isoDate = new Date(date).toISOString();
    return isoDate.slice(0, 10).split('-').reverse().join('/');
  }

  return (
    <div className="flex gap-x-5 p-5">
      <div className="bg-white p-5 h-min w-1/6">
        <h3 className="font-bold mb-5">Adresse</h3>
        <AdherentAddressPutForm adherent={adherent} />
      </div>
      <div className="w-5/6">
        <div className="h-20 flex items-center justify-between bg-white px-5">
          <div className="flex gap-x-5 items-center">
            <div
              onClick={() => navigate(-1)}
              className="w-8 h-8 bg-green-ufeLight flex justify-center items-center pointer"
            >
              <img src={chevron} alt="back" />
            </div>
            <h2 className="font-bold">
              {adherent && adherent?.firstname + ' ' + adherent?.lastname}
            </h2>
            {isLoading && <Loader />}
          </div>
          <div className="flex items-center gap-x-5">
            {adherent && (
              <>
                <div className="flex gap-x-2">
                  <img src={emailImg} alt="email" className="w-5" />
                  <h4>{adherent && adherent.email}</h4>
                </div>
                <div className="flex gap-x-2">
                  <img src={phoneImg} alt="phone" className="w-5" />
                  <h4>{adherent && adherent.phone}</h4>
                </div>
                <AdherentPutButton adherent={adherent} />
              </>
            )}
          </div>
        </div>

        <div className="my-5">
          <button
            value="1"
            onClick={handleTabSelect}
            className={
              tab === 1 ? 'bg-green-ufe p-4 text-white' : 'bg-white p-4'
            }
          >
            INFORMATIONS
          </button>
          <button
            value="2"
            onClick={handleTabSelect}
            className={
              tab === 2
                ? 'bg-green-ufe p-4 text-white ml-5'
                : 'bg-white p-4 ml-5'
            }
          >
            ADHÉSIONS
          </button>
          <button
            value="3"
            onClick={handleTabSelect}
            className={
              tab === 3
                ? 'bg-green-ufe p-4 text-white ml-5'
                : 'bg-white p-4 ml-5'
            }
          >
            DONATIONS
          </button>
          {role !== 'ROLE_SUBSIDIARY' && (
            <button
              value="4"
              onClick={handleTabSelect}
              className={
                tab === 4
                  ? 'bg-green-ufe p-4 text-white ml-5'
                  : 'bg-white p-4 ml-5'
              }
            >
              COMMENTAIRE
            </button>
          )}
        </div>
        {tab === 1 && adherent && (
          <div className="bg-white p-5 flex justify-around">
            <AdherentDetailsCard
              adherent={adherent}
              warningMessage={warningMessage}
              setWarningMessage={setWarningMessage}
              partner={partner}
              setPartner={setPartner}
            />
            <div className="flex flex-col p-5 w-1/4 border">
              <div className="flex justify-between mb-5">
                <h3>Dernière adhésion</h3>
              </div>
              <div className="flex flex-col gap-y-5">
                <div className="flex flex-col">
                  <label>Représentation</label>
                  <div className="border px-2 flex items-center h-8 bg-gray-200">
                    {adherent.lastMembership?.subsidiary.name}
                  </div>
                </div>
                <div className="flex flex-col">
                  <label>Barème</label>
                  <div className="border px-2 flex items-center h-8 bg-gray-200">
                    {adherent.lastMembership?.priceType}
                  </div>
                </div>
                <div className="flex flex-col">
                  <label>Date de début</label>
                  <div className="border px-2 flex items-center h-8 bg-gray-200">
                    {handleDateFormat(adherent.lastMembership?.beginAt)}
                  </div>
                </div>
                <div className="flex flex-col">
                  <label>Adhésion valide jusqu'au</label>
                  <div className="border px-2 flex items-center h-8 bg-gray-200">
                    {handleDateFormat(adherent.lastMembership?.endAt)}
                  </div>
                </div>
                <div className="flex flex-col">
                  <label>Part siège</label>
                  <div className="border px-2 flex items-center h-8 bg-gray-200">
                    {adherent.lastMembership?.siegeAmount}
                  </div>
                </div>
                <div className="flex flex-col">
                  <label>Part locale</label>
                  <div className="border px-2 flex items-center h-8 bg-gray-200">
                    {adherent.lastMembership?.localAmount}
                  </div>
                </div>
                <div className="flex flex-col">
                  <label>Montant</label>
                  <div className="border px-2 flex items-center h-8 bg-gray-200">
                    {adherent.lastMembership &&
                      adherent.lastMembership?.localAmount +
                        adherent.lastMembership?.siegeAmount}
                  </div>
                </div>
                <div className="flex flex-col">
                  <label>Origine</label>
                  <div className="border px-2 flex items-center h-8 bg-gray-200">
                    {adherent.lastMembership?.origin}
                  </div>
                </div>
              </div>
            </div>
            <CardItem />
          </div>
        )}
        {tab === 2 && adherent && (
          <MembershipCollectionContext adherent={adherent}>
            <MembershipsContainer adherent={adherent} />
          </MembershipCollectionContext>
        )}
        {tab === 3 && adherent && (
          <DonationProvider adherent={adherent}>
            <DonationContainer adherent={adherent} />
          </DonationProvider>
        )}
        {tab === 4 && adherent && (
          <div className="bg-white p-5">
            <div className="flex items-center gap-x-5">
              <h3>Commentaire</h3>
            </div>
            <div className="mt-5 flex gap-x-5">
              <AdherentCommentForm adherent={adherent} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
