import React from 'react';

interface Props {
  cardStatus: string;
  setCardStatus: (cardStatus: string) => void;
}

export default function CardStatusFilter(props: Props) {
  return (
    <div>
      <label className="text-sm">Statut de la carte</label>
      <select
        name="cardStatus"
        className="w-full border"
        value={props.cardStatus}
        onChange={(e) => {
          props.setCardStatus(e.target.value);
        }}
      >
        <option value="">---</option>
        <option value="CREATED">À imprimer</option>
        <option value="SENT">Envoyée</option>
      </select>
    </div>
  );
}
