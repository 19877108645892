import EmployeeDeleteButton from '../buttons/EmployeeDeleteButton';
import { Employee } from '../types/Employee';

type Props = {
  employee: Employee;
};

export default function EmployeeItem(props: Props) {
  return (
    <div className="border p-5">
      <div className="flex justify-between">
        <p className="font-bold">{props.employee?.title}</p>
        <EmployeeDeleteButton employee={props.employee} />
      </div>
      <p>
        {props.employee.adherent?.lastname} {props.employee.adherent?.firstname}
      </p>
      <p>{props.employee.adherent?.email}</p>
      <p>{props.employee.adherent?.phone}</p>
    </div>
  );
}
