import React from 'react';

interface Props {
  text: string;
  onClick?: () => void;
}

export default function Button(props: Props) {
  return (
    <button
      className="w-full bg-green-ufe text-white py-1"
      onClick={() => props.onClick}
    >
      {props.text}
    </button>
  );
}
