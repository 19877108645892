import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useUser } from '../../User/contexts/UserContextProvider';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { useSubsidiaryNotesDispatch } from '../contexts/SubsidiaryNoteCollectionContext';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';

interface Props {
  hide: () => void;
}

interface Values {
  author: string | null;
  message: string;
  createdAt: Date;
  updatedAt: Date;
  subsidiary: string | null;
}

export default function SubsidiaryNotePostForm(props: Props) {
  const user = useUser();
  const { subsidiaryId } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useSubsidiaryNotesDispatch();
  const { auth } = useAuth();
  const role = auth?.payload?.roles[0];

  const ValidationSchema = Yup.object().shape({
    message: Yup.string()
      .min(2, 'La note saisie est trop courte')
      .max(255, 'La note saisie est trop longue'),
  });

  return (
    <Formik
      initialValues={{
        author: role !== 'ROLE_ADMIN' && user ? user['@id'] : null,
        message: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        subsidiary: subsidiaryId ? '/subsidiaries/' + subsidiaryId : null,
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        toast
          .promise(
            axiosPrivate.post('/subsidiary_notes', values),
            {
              pending: 'Création de la note en cours...',
              success: 'Votre note a bien été créée',
              error: 'Une erreur est survenue.',
            },
            { position: toast.POSITION.BOTTOM_CENTER },
          )
          .then(
            (response) => {
              dispatch({ type: 'added', subsidiaryNote: response.data });
              props.hide();
            },
            (error) => {
              console.log(error);
            },
          )
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched }) => (
        <Form className="mx-2">
          <div>
            <Field
              as="textarea"
              id="message"
              name="message"
              rows={20}
              minLength={2}
              maxLength={255}
              placeholder="Veuillez saisir votre message..."
              wrap="hard"
              className="p-2 border w-80"
            />
            <p className="text-sm text-red-700 mt-1">
              {errors.message && touched.message ? errors.message : null}
            </p>
          </div>
          <button type="submit" className="px-2 py-1 text-white bg-green-ufe">
            Créer
          </button>
        </Form>
      )}
    </Formik>
  );
}
