import React, { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import Button from '../../../components/Button';
import { usePriceStore } from '../usePriceStore';

interface Values {
  year: number;
  type: string;
}

export default function PriceFilterForm() {
  const [isRotating, setIsRotating] = useState(false);
  const type = usePriceStore((state) => state.type);
  const setType = usePriceStore((state) => state.setType);
  const year = usePriceStore((state) => state.year);
  const setYear = usePriceStore((state) => state.setYear);
  const resetFilters = usePriceStore((state) => state.resetFilters);

  const handleResetClick = () => {
    setIsRotating(true);
    resetFilters();
    setTimeout(() => {
      setIsRotating(false);
    }, 1000);
  };

  return (
    <Formik
      initialValues={{
        year: 0,
        type: '',
      }}
      enableReinitialize={true}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        console.log(values);
        setSubmitting(false);
      }}
    >
      {({ errors, touched }) => (
        <Form className="flex flex-col space-y-2">
          <div className="flex justify-between">
            <h3 className="font-bold">Filtres</h3>
            <ArrowPathIcon
              className={`h-6 w-6 fill-green-ufe cursor-pointer ${
                isRotating ? 'animate-spin' : ''
              }`}
              onClick={handleResetClick}
            />
          </div>
          <Button text={'Lancer la recherche'} />
          <div>
            <label className="text-sm">Type de Barème</label>
            <select
              className="w-full border"
              value={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              <option value="">---</option>
              <option value="NORMAL PARTICULIER">NORMAL PARTICULIER</option>
              <option value="NORMAL CONJOINT">NORMAL CONJOINT</option>
              <option value="JUNIOR PARTICULIER">JUNIOR PARTICULIER</option>
              <option value="JUNIOR CONJOINT">JUNIOR CONJOINT</option>
              <option value="SENIOR PARTICULIER">SENIOR PARTICULIER</option>
              <option value="SENIOR CONJOINT">SENIOR CONJOINT</option>
              <option value="CUSTOM PARTICULIER">CUSTOM PARTICULIER</option>
              <option value="CUSTOM CONJOINT">CUSTOM CONJOINT</option>
            </select>
          </div>
          <div>
            <label className="text-sm">Année</label>
            <select
              className="w-full border"
              value={year}
              onChange={(e) => {
                setYear(parseInt(e.target.value));
              }}
            >
              <option value="">---</option>
              <option value="2017">2017</option>
              <option value="2018">2018</option>
              <option value="2019">2019</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </select>
          </div>
        </Form>
      )}
    </Formik>
  );
}
