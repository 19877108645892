import React, { useState } from 'react';
import Modal from '../../../components/modals/Modal';
import useModal from '../../../components/modals/useModal';
import { SubsidiaryNote } from '../types/SubsidiaryNote';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import {
  useSetSelectedSubsidiaryNote,
  useSubsidiaryNotesDispatch,
} from '../contexts/SubsidiaryNoteCollectionContext';

interface Props {
  subsidiaryNote: SubsidiaryNote;
}

export default function SubsidiaryNoteDeleteButton(props: Props) {
  const { isShowing, toggle } = useModal();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useSubsidiaryNotesDispatch();
  const setSelectedSubsidiaryNote = useSetSelectedSubsidiaryNote();

  return (
    <>
      <button onClick={toggle} className="bg-white border px-2 py-1">
        Supprimer
      </button>
      <Modal title={'Supprimer'} isShowing={isShowing} hide={toggle}>
        <div className="flex justify-center">
          <button
            className="bg-red-700 w-60 py-1 text-white"
            onClick={() => {
              if (!isSubmitting) {
                toast
                  .promise(
                    axiosPrivate.delete(props.subsidiaryNote['@id']),
                    {
                      pending: 'Suppression en cours...',
                      success: 'Supprimé avec succès.',
                      error: 'Une erreur est survenue.',
                    },
                    { position: toast.POSITION.BOTTOM_CENTER },
                  )
                  .then(
                    () => {
                      dispatch({
                        type: 'deleted',
                        subsidiaryNote: props.subsidiaryNote,
                      });
                      setSelectedSubsidiaryNote(null);
                      toggle();
                    },
                    (error) => {
                      console.log(error);
                    },
                  )
                  .finally(() => {
                    setIsSubmitting(false);
                  });
              }
            }}
          >
            Supprimer
          </button>
        </div>
      </Modal>
    </>
  );
}
