import React from 'react';
import { Adherent } from '../types/Adherent';
import useModal from '../../../components/modals/useModal';
import Modal from '../../../components/modals/Modal';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import {
  useAdherents,
  useAdherentsDispatch,
  useAdherentsLinks,
  useFetchAdherents,
} from '../contexts/AdherentCollectionContext';

interface Props {
  adherent: Adherent;
  closeParent: () => void;
}

export default function AdherentDeleteButton(props: Props) {
  const { isShowing, toggle } = useModal();
  const axiosPrivate = useAxiosPrivate();
  const adherents = useAdherents();
  const links = useAdherentsLinks();
  const fetchAdherents = useFetchAdherents();
  const dispatch = useAdherentsDispatch();

  return (
    <>
      <button onClick={toggle} className="bg-white border px-2 py-1 mt-5">
        Supprimer
      </button>
      <Modal title={'Delete'} isShowing={isShowing} hide={toggle}>
        <div className="flex justify-center">
          <button
            className="bg-red-700 w-60 py-1 text-white"
            onClick={() =>
              toast
                .promise(
                  axiosPrivate.delete(props.adherent['@id']),
                  {
                    pending: 'Suppression en cours...',
                    success: 'Supprimé avec succès.',
                    error: 'Une erreur est survenue.',
                  },
                  { position: toast.POSITION.BOTTOM_CENTER },
                )
                .then(
                  () => {
                    if (
                      links?.['@id'] === links?.['hydra:last'] &&
                      adherents.length > 1
                    ) {
                      dispatch({ type: 'deleted', adherent: props.adherent });
                    } else if (
                      links?.['@id'] === links?.['hydra:last'] &&
                      adherents.length === 1
                    ) {
                      fetchAdherents(links?.['hydra:previous']);
                    } else {
                      fetchAdherents(links?.['@id']);
                    }
                    props.closeParent();
                  },
                  (error) => {
                    console.log(error);
                  },
                )
            }
          >
            Supprimer
          </button>
        </div>
      </Modal>
    </>
  );
}
