import Modal from '../../../components/modals/Modal';
import useModal from '../../../components/modals/useModal';
import { Subsidiary } from '../types/Subsidiary';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import {
  useGetSubsidiaryCollection,
  useSubsidiaries,
  useSubsidiariesDispatch,
  useSubsidiariesLinks,
} from '../contexts/SubsidiaryCollectionContext';

interface Props {
  subsidiary: Subsidiary;
  closeParent: () => void;
}

export default function SubsidiaryDeleteButton(props: Props) {
  const { isShowing, toggle } = useModal();
  const axiosPrivate = useAxiosPrivate();
  const subsidiaries = useSubsidiaries();
  const links = useSubsidiariesLinks();
  const getSubsidiaryCollection = useGetSubsidiaryCollection();
  const dispatchSubsidiaries = useSubsidiariesDispatch();

  return (
    <>
      <button onClick={toggle} className="bg-white border px-2 py-1 mt-5">
        Supprimer
      </button>
      <Modal title={'Supprimer'} isShowing={isShowing} hide={toggle}>
        <div className="flex justify-center">
          <button
            className="bg-red-700 w-60 py-1 text-white"
            onClick={() =>
              toast
                .promise(
                  axiosPrivate.delete(props.subsidiary['@id']),
                  {
                    pending: 'Suppression en cours...',
                    success: 'Supprimé avec succès.',
                    error: 'Une erreur est survenue.',
                  },
                  { position: toast.POSITION.BOTTOM_CENTER },
                )
                .then(
                  () => {
                    if (
                      links?.['@id'] === links?.['hydra:last'] &&
                      subsidiaries.length > 1
                    ) {
                      dispatchSubsidiaries({
                        type: 'deleted',
                        subsidiary: props.subsidiary,
                      });
                    } else if (
                      links?.['@id'] === links?.['hydra:last'] &&
                      subsidiaries.length === 1
                    ) {
                      getSubsidiaryCollection(links?.['hydra:previous']);
                    } else {
                      getSubsidiaryCollection(links?.['@id']);
                    }
                    props.closeParent();
                  },
                  (error) => {
                    console.log(error);
                  },
                )
            }
          >
            Supprimer
          </button>
        </div>
      </Modal>
    </>
  );
}
