import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import Loader from '../../../components/Loader';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';

interface Props {
  chosenSubsidiary: Subsidiary | null;
}

const RenewAdherentStat = (props: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get(
        '/charts/inactiveAdherents' +
          (props.chosenSubsidiary && props.chosenSubsidiary['@id'] !== ''
            ? '?subsidiary=' + props.chosenSubsidiary['@id']
            : ''),
      )
      .then(
        (r) => {
          setData(r?.data);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [axiosPrivate, props.chosenSubsidiary]);

  return (
    <div className="p-6 bg-white w-full">
      <h3>Nombre d'adhérents à renouveler {isLoading && <Loader />}</h3>
      {!isLoading && <div className="mt-5">{data}</div>}
    </div>
  );
};

export default RenewAdherentStat;
