import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import Loader from '../../../components/Loader';

const TotalSubsidiaryStat = () => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get('/charts/subsidiaryCount')
      .then(
        (r) => {
          setData(r.data);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [axiosPrivate]);

  return (
    <div className="p-6 bg-white w-full">
      <h3>Nombre de représentations {isLoading && <Loader />}</h3>
      {!isLoading && <div className="mt-5">{data}</div>}
    </div>
  );
};

export default TotalSubsidiaryStat;
