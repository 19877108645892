interface Props {
  priceType: string;
  setPriceType: (priceType: string) => void;
}

export default function PriceTypeFilter(props: Props) {
  return (
    <div>
      <label className="text-sm">Type de Barème</label>
      <select
        name="priceType"
        className="w-full border"
        value={props.priceType}
        onChange={(e) => {
          props.setPriceType(e.target.value);
        }}
      >
        <option value="">---</option>
        <option value="NORMAL PARTICULIER">NORMAL PARTICULIER</option>
        <option value="NORMAL CONJOINT">NORMAL CONJOINT</option>
        <option value="JUNIOR PARTICULIER">JUNIOR PARTICULIER</option>
        <option value="JUNIOR CONJOINT">JUNIOR CONJOINT</option>
        <option value="SENIOR PARTICULIER">SENIOR PARTICULIER</option>
        <option value="SENIOR CONJOINT">SENIOR CONJOINT</option>
        <option value="CUSTOM PARTICULIER">CUSTOM PARTICULIER</option>
        <option value="CUSTOM CONJOINT">CUSTOM CONJOINT</option>
      </select>
    </div>
  );
}
