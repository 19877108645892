import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';
import { Price } from '../../Price/types/Price';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import {
  useAdherents,
  useAdherentsDispatch,
  useAdherentsItemsPerPage,
  useAdherentsLinks,
  useAdherentsSubsidiary,
  useFetchAdherents,
} from '../contexts/AdherentCollectionContext';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';
import { useUser } from '../../User/contexts/UserContextProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSubsidiary } from '../../Subsidiary/contexts/SubsidiaryContext';
import { ErrorHydra } from '../../../types/ErrorHydra';
import { toast } from 'react-toastify';

interface Values {
  gender: string;
  firstname: string;
  lastname: string;
  birthdate: Date;
  nationality: string;
  phone: string;
  email: string;
  memberships: [
    {
      subsidiary: string;
      localAmount: number;
      siegeAmount: number;
      priceType: string;
      origin: string;
      card: {
        type: string;
        status: string;
      };
    },
  ];
}

interface Props {
  hide: () => void;
}

export default function AdherentPostForm(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const adherents = useAdherents();
  const links = useAdherentsLinks();
  const fetchAdherents = useFetchAdherents();
  const dispatch = useAdherentsDispatch();
  const itemsPerPage = useAdherentsItemsPerPage();
  const [keyword, setKeyword] = useState('');
  const [subsidiaries, setSubsidiaries] = useState([]);
  const sub = useAdherentsSubsidiary();
  const [subsidiary, setSubsidiary] = useState<Subsidiary | null>(
    sub ? sub : null,
  );
  const [prices, setPrices] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState<Price | null>();
  const [startDate, setStartDate] = useState(new Date());
  const [isHidden, setIsHidden] = useState(true);
  const [customEmail, setCustomEmail] = useState('');
  const [copiedEmail, setCopiedEmail] = useState(false);

  const { auth } = useAuth();
  const role = auth?.payload.roles[0];
  const user = useUser();
  const navigate = useNavigate();
  registerLocale('fr', fr);
  const location = useLocation();
  const actualSubsidiary =
    location.pathname !== '/adherents' ? useSubsidiary() : null;

  useEffect(() => {
    if (role === 'ROLE_SUBSIDIARY' && user?.subsidiary) {
      setSubsidiary(user.subsidiary);
      axiosPrivate.get('/prices?subsidiary=' + user?.subsidiary['@id']).then(
        (response) => {
          setPrices(response.data['hydra:member']);
        },
        (error) => {
          console.log(error);
        },
      );
    } else if (subsidiary) {
      axiosPrivate.get('/prices?subsidiary=' + subsidiary['@id']).then(
        (response) => {
          setPrices(response.data['hydra:member']);
        },
        (error) => {
          console.log(error);
        },
      );
    } else if (actualSubsidiary) {
      axiosPrivate.get('/prices?subsidiary=' + actualSubsidiary['@id']).then(
        (response) => {
          setPrices(response.data['hydra:member']);
        },
        (error) => {
          console.log(error);
        },
      );
    }
  }, [role, user?.subsidiary, axiosPrivate]);

  const chooseSubsidiary = (subsidiaryChoice: Subsidiary) => {
    setSubsidiaries([]);
    setSubsidiary(subsidiaryChoice);
    axiosPrivate.get('/prices?subsidiary=' + subsidiaryChoice['@id']).then(
      (response) => {
        const prices = response.data['hydra:member'];
        if (new Date().getUTCMonth() >= 9) {
          const tempPrices = prices.filter((price: Price) =>
            price.year === new Date().getUTCFullYear() + 1 ? price : null,
          );
          setPrices(tempPrices);
        } else {
          const tempPrices = prices.filter((price: Price) =>
            price.year === new Date().getUTCFullYear() ? price : null,
          );
          setPrices(tempPrices);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  const searchSubsidiary = () => {
    axiosPrivate
      .get('/subsidiaries?itemsPerPage=10&page=1&name=' + keyword)
      .then(
        (response) => {
          setSubsidiaries(response?.data['hydra:member']);
        },
        (error) => {
          console.log(error);
        },
      );
  };

  const choosePrice = (priceChoice: Price) => {
    setPrices([]);
    setSelectedPrice(priceChoice);
  };

  const changeSubsidiary = () => {
    setSubsidiary(null);
    setSelectedPrice(null);
  };

  const changePrice = () => {
    setSelectedPrice(null);
    if (subsidiary) {
      axiosPrivate.get('/prices?subsidiary=' + subsidiary['@id']).then(
        (response) => {
          setPrices(response.data['hydra:member']);
        },
        (error) => {
          console.error(error);
        },
      );
    }
  };

  const handleCopyEmail = () => {
    navigator.clipboard
      .writeText('coralie.desbas@ufe.org')
      .then(() => {
        setCopiedEmail(true);
        setTimeout(() => {
          setCopiedEmail(false);
        }, 1000);
      })
      .catch((err) => {
        console.error("Erreur lors de la copie de l'adresse e-mail :", err);
      });
  };

  const checkEmailExist = (email: string) => {
    axiosPrivate.get('/public/adherents/email?email=' + email).then(
      (response) => {
        console.log(response.data);
        setCustomEmail(transformEmail(email));
        setIsHidden(!response.data);
      },
      (error) => {
        console.error(error);
      },
    );
  };

  function transformEmail(email: string): string {
    const [username, domain] = email.split('@');
    return username + '+1' + '@' + domain;
  }

  const searchPressKeyboard = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      searchSubsidiary();
    }
  };

  const ValidationSchema = Yup.object().shape({
    gender: Yup.string().required('Le champ civilité est obligatoire.'),
    phone: Yup.string()
      .min(2, 'Votre téléphone doit avoir un minimum de 2 caractères.')
      .max(50, 'Votre téléphone doit avoir un maximum de 50 caractères.'),
    nationality: Yup.string()
      .min(2, 'Votre nationalité doit avoir un minimum de 2 caractère.')
      .max(50, 'Votre nationalité doit avoir un maximum de 50 caractères.'),
    firstname: Yup.string()
      .min(2, 'Votre prénom doit avoir un minimum de 2 caractère.')
      .max(50, 'Votre prénom doit avoir un maximum de 50 caractères.')
      .required('Le champ prénom est obligatoire.'),
    lastname: Yup.string()
      .min(2, 'Votre nom doit avoir un minimum de 2 caractère.')
      .max(50, 'Votre nom doit avoir un maximum de 50 caractères.')
      .required('Le champ nom est obligatoire.'),
    email: Yup.string().email('Le format de email est invalide.'),
  });

  return (
    <Formik
      initialValues={{
        gender: 'Monsieur',
        birthdate: new Date(),
        nationality: 'FRANCAISE',
        phone: '',
        firstname: '',
        lastname: '',
        email: '',
        memberships: [
          {
            subsidiary: '',
            localAmount: 0,
            siegeAmount: 0,
            priceType: '',
            origin: '',
            card: {
              type: 'UFE',
              status: 'CREATED',
            },
          },
        ],
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        if (subsidiary) {
          values.memberships[0].subsidiary = subsidiary['@id'];
        }
        if (actualSubsidiary) {
          values.memberships[0].subsidiary = actualSubsidiary['@id'];
        }
        if (selectedPrice) {
          values.memberships[0].localAmount = selectedPrice.localAmount;
          values.memberships[0].siegeAmount = selectedPrice.siegeAmount;
          values.memberships[0].priceType = selectedPrice.type;
        }

        const origin = document.getElementById('origin') as HTMLInputElement;
        if (role === 'ROLE_SUBSIDIARY') {
          values.memberships[0].origin = 'REPRESENTATION';
        } else {
          values.memberships[0].origin = origin.value;
        }

        values.birthdate = startDate;

        toast
          .promise(
            axiosPrivate.post('/adherents', values),
            {
              pending: 'Création en cours...',
              success: 'Adhérent créée.',
              error: {
                render(data: any) {
                  const value = data.data?.response?.data as ErrorHydra;
                  return value['hydra:description']
                    ? value['hydra:description']
                    : 'Une erreur est survenue.';
                },
              },
            },
            { position: toast.POSITION.BOTTOM_CENTER },
          )
          .then(
            (response) => {
              if (adherents.length < itemsPerPage) {
                dispatch({ type: 'added', adherent: response.data });
              } else {
                fetchAdherents(links?.['@id']);
              }
              props.hide();
              navigate('/adherent/' + response.data.id);
            },
            (error) => {
              console.log(error);
            },
          )
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <>
            {!subsidiary && !actualSubsidiary ? (
              <div className="flex flex-col">
                <label>Rechercher une représentation</label>
                <input
                  type="text"
                  placeholder="Nom de la représentation"
                  className="border px-2 flex items-center h-8 mt-1"
                  value={keyword}
                  onKeyUp={(event) => {
                    searchPressKeyboard(event);
                  }}
                  onChange={(event) => {
                    setKeyword(event.target.value);
                  }}
                />
                <button
                  type="button"
                  className="px-2 py-1 text-white bg-green-ufe mt-4"
                  onClick={searchSubsidiary}
                >
                  Recherche
                </button>
              </div>
            ) : (
              <>
                {selectedPrice ? (
                  <>
                    <h4>Informations personnelles</h4>
                    <div className="flex flex-row">
                      <div className="flex flex-col mt-4 mr-5">
                        <div className="flex flex-col mb-4">
                          <label>Civilité</label>
                          <Field
                            as="select"
                            name="gender"
                            placeholder="Civilité"
                            className="border px-1 flex items-center h-8 w-full"
                          >
                            <option value="Monsieur">Monsieur</option>
                            <option value="Madame">Madame</option>
                          </Field>
                          {errors.gender && touched.gender ? (
                            <p className="text-sm text-red-700 mt-1">
                              {' '}
                              {errors.gender}{' '}
                            </p>
                          ) : null}
                          {errors.nationality && touched.nationality ? (
                            <p className="text-sm text-red-700 mt-1"></p>
                          ) : null}
                        </div>
                        <div className="flex flex-col mb-4">
                          <label>Prénom</label>
                          <span className="text-xs italic text-white">*</span>
                          <Field
                            name="firstname"
                            placeholder="Prénom"
                            type="text"
                            className="border px-2 flex items-center h-8"
                          />
                          {errors?.firstname && touched?.firstname ? (
                            <p className="text-sm text-red-700 mt-1">
                              {' '}
                              {errors?.firstname}{' '}
                            </p>
                          ) : null}
                        </div>
                        <div className="flex flex-col mb-4">
                          <label>Nom</label>
                          <Field
                            name="lastname"
                            placeholder="Nom"
                            type="text"
                            className="border px-2 flex items-center h-8"
                          />
                          {errors?.lastname && touched?.lastname ? (
                            <p className="text-sm text-red-700 mt-1">
                              {' '}
                              {errors?.lastname}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="flex flex-col mt-4">
                        <div className="flex flex-col mb-4">
                          <label>Nationalité</label>
                          <Field
                            as="select"
                            id="nationality"
                            name="nationality"
                            placeholder="Veuillez sélectionner votre nationalité"
                            className="border px-1 flex items-center h-8 w-full"
                          >
                            <option value="FRANCAISE">Française</option>
                            <option value="AUTRE">Autre</option>
                          </Field>
                          {errors.nationality && touched.nationality ? (
                            <p className="text-sm text-red-700 mt-1">
                              {' '}
                              {errors.nationality}{' '}
                            </p>
                          ) : null}
                        </div>
                        <div className="flex flex-col mb-4">
                          <label>Email</label>
                          <span className="text-xs italic">
                            Si vous ne disposez pas d'adresse e-mail, veuillez
                            laisser le champ vide.
                          </span>
                          <Field
                            name="email"
                            placeholder="Email"
                            type="email"
                            className="border px-2 flex items-center h-8"
                            onBlur={(e: { target: { value: string } }) => {
                              checkEmailExist(e.target.value);
                            }}
                          />
                          {errors?.email && touched?.email ? (
                            <p className="text-sm text-red-700 mt-1">
                              {' '}
                              {errors?.email}{' '}
                            </p>
                          ) : null}
                        </div>
                        <div className="flex flex-col mb-4">
                          <label>Téléphone</label>
                          <Field
                            name="phone"
                            placeholder="Téléphone"
                            type="tel"
                            className="border px-2 flex items-center h-8"
                          />
                          {errors.phone && touched.phone ? (
                            <p className="text-sm text-red-700 mt-1">
                              {' '}
                              {errors.phone}{' '}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row max-w-xl mx-auto my-2">
                      <div className={isHidden ? 'hidden' : 'text-justify'}>
                        <p
                          className="border border-yellow-500 text-yellow-500 rounded p-2"
                          onClick={handleCopyEmail}
                        >
                          Un adhérent avec l'adresse e-mail fournie est déjà
                          enregistré dans le système. Vous avez la possibilité
                          de créer un nouveau compte avec une adresse différente
                          ({customEmail}) ou de contacter le siège afin de
                          l'intégrer à votre représentation. Veuillez utiliser
                          l'adresse e-mail suivante :
                          <span
                            className={`cursor-pointer underline transition ${
                              copiedEmail
                                ? 'border-green-ufe text-green-ufe animate-pulse'
                                : 'border-yellow-600 text-yellow-600'
                            }`}
                          >
                            {' '}
                            {copiedEmail
                              ? 'Adresse e-mail copiée !'
                              : 'coralie.desbas@ufe.org'}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col space-y-2">
                      <div className="flex-col">
                        <label>Date de naissance</label>
                        <DatePicker
                          selected={startDate}
                          onChange={(date: Date) => setStartDate(date)}
                          locale="fr"
                          dateFormat="dd-MM-yyyy"
                          className="border px-2 flex items-center h-8 w-full"
                        />
                      </div>
                      <div className="flex-col">
                        <label>Type de carte</label>
                        <Field
                          as="select"
                          id="card"
                          name="card"
                          placeholder="Veuillez sélectionner un type de carte"
                          className="border px-1 flex items-center h-8 w-full"
                        >
                          <option value="UFE">UFE</option>
                          <option value="AMIS DE L'UFE">AMIS DE L'UFE</option>
                          <option value="UFE AVENIR">UFE AVENIR</option>
                          <option value="HONNEUR">HONNEUR</option>
                        </Field>
                      </div>
                      {role !== 'ROLE_SUBSIDIARY' && (
                        <div className="flex-col">
                          <label>Origine du paiement</label>
                          <Field
                            as="select"
                            id="origin"
                            name="origin"
                            className="border px-1 flex items-center h-8 w-full"
                          >
                            <option value="">---</option>
                            <option value="SIEGE">Siège</option>
                            <option value="REPRESENTATION">UFE locale</option>
                            <option value="SITE INTERNET">Site Internet</option>
                          </Field>
                        </div>
                      )}
                      <div className="flex justify-between items-center w-full">
                        <div className="border px-2 flex items-center h-8 bg-gray-300 w-72">
                          {actualSubsidiary
                            ? actualSubsidiary?.name
                            : subsidiary?.name}
                        </div>
                        {subsidiary && role !== 'ROLE_SUBSIDIARY' ? (
                          <button
                            type="button"
                            className="py-1 text-white bg-green-ufe px-5"
                            onClick={changeSubsidiary}
                          >
                            Modifier
                          </button>
                        ) : null}
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <div className="border px-2 flex items-center h-8 bg-gray-300 w-72">
                          {selectedPrice?.type}
                        </div>
                        <button
                          type="button"
                          className="py-1 text-white bg-green-ufe px-5"
                          onClick={changePrice}
                        >
                          Modifier
                        </button>
                      </div>
                      <button
                        className="px-2 py-1 text-white bg-green-ufe w-full"
                        type="submit"
                      >
                        Créer
                      </button>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>

          {subsidiaries.length !== 0 && !actualSubsidiary ? (
            <div className="overflow-y-auto">
              {subsidiaries &&
                subsidiaries.map((subsidiary: Subsidiary, key: number) => {
                  return (
                    <div
                      className="flex justify-between items-center mt-3"
                      key={key}
                    >
                      <p>{subsidiary.name}</p>
                      <button
                        type="button"
                        className="px-2 py-1 text-white bg-green-ufe ml-2"
                        onClick={() => chooseSubsidiary(subsidiary)}
                      >
                        Sélectionner
                      </button>
                    </div>
                  );
                })}
            </div>
          ) : null}

          {prices.length !== 0 ? (
            <div className="overflow-y-auto">
              <h4>
                Veuillez sélectionner un barème{' '}
                {subsidiary && !actualSubsidiary
                  ? 'de la représentation ' + subsidiary.name
                  : null}
                {!subsidiary && actualSubsidiary
                  ? 'de la représentation ' + actualSubsidiary.name
                  : null}
              </h4>
              {prices
                .filter((price: Price) => price.type.includes('NORMAL'))
                .map((price: Price, key) => {
                  return (
                    <div
                      className="flex justify-between items-center mt-3"
                      key={key}
                    >
                      <p className="mr-2">{price.year}</p>
                      <p className="mr-2">{price.type}</p>
                      <p className="mr-2">Montant siège: {price.siegeAmount}</p>
                      <p>Montant local: {price.localAmount}</p>
                      <button
                        type="button"
                        className="px-2 py-1 text-white bg-green-ufe ml-2"
                        onClick={() => choosePrice(price)}
                      >
                        Sélectionner
                      </button>
                    </div>
                  );
                })}
              {prices
                .filter((price: Price) => price.type.includes('JUNIOR'))
                .map((price: Price, key) => {
                  return (
                    <div
                      className="flex justify-between items-center mt-3"
                      key={key}
                    >
                      <p className="mr-2">{price.year}</p>
                      <p className="mr-2">{price.type}</p>
                      <p className="mr-2">Montant siège: {price.siegeAmount}</p>
                      <p>Montant local: {price.localAmount}</p>
                      <button
                        type="button"
                        className="px-2 py-1 text-white bg-green-ufe ml-2"
                        onClick={() => choosePrice(price)}
                      >
                        Sélectionner
                      </button>
                    </div>
                  );
                })}
              {prices
                .filter((price: Price) => price.type.includes('SENIOR'))
                .map((price: Price, key) => {
                  return (
                    <div
                      className="flex justify-between items-center mt-3"
                      key={key}
                    >
                      <p className="mr-2">{price.year}</p>
                      <p className="mr-2">{price.type}</p>
                      <p className="mr-2">Montant siège: {price.siegeAmount}</p>
                      <p>Montant local: {price.localAmount}</p>
                      <button
                        type="button"
                        className="px-2 py-1 text-white bg-green-ufe ml-2"
                        onClick={() => choosePrice(price)}
                      >
                        Sélectionner
                      </button>
                    </div>
                  );
                })}
            </div>
          ) : null}
        </Form>
      )}
    </Formik>
  );
}
