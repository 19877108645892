import useModal from '../../../components/modals/useModal';
import Modal from '../../../components/modals/Modal';
import UserPostForm from '../forms/UserPostForm';

export default function UserPostButton() {
  const { isShowing, toggle } = useModal();

  return (
    <>
      <button onClick={toggle} className="bg-white border px-2 py-1">
        Nouvel utilisateur
      </button>
      <Modal
        isShowing={isShowing}
        hide={toggle}
        title={'Ajouter un utilisateur'}
      >
        <UserPostForm hide={toggle} />
      </Modal>
    </>
  );
}
