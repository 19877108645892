import { useEffect, useState } from 'react';
import AdherentDetailsPutForm from '../forms/AdherentDetailsPutForm';
import close from '../../../assets/images/close.svg';
import edit from '../../../assets/images/editIcon.svg';
import { Adherent } from '../types/Adherent';
import Modal from '../../../components/modals/Modal';
import useModal from '../../../components/modals/useModal';
import { Partner } from '../types/Partner';

interface Props {
  adherent: Adherent;
  warningMessage: boolean;
  setWarningMessage: (display: boolean) => void;
  partner: Partner | null;
  setPartner: (partner: Partner | null) => void;
}

export default function AdherentDetailsCard(props: Props) {
  const [isEditable, setEditable] = useState(false);
  const { isShowing, toggle } = useModal();

  const toggleEdit = () => {
    setEditable(!isEditable);
  };

  useEffect(() => {
    if (!props.adherent.lastMembership?.subsidiary && props.warningMessage) {
      toggle();
      props.setWarningMessage(false);
    }
  }, []);

  return (
    <div className="flex flex-col p-5 w-1/4 border">
      <div className="flex justify-between mb-5">
        <h3>Détails</h3>
        <img
          src={isEditable ? close : edit}
          alt="edit-icon"
          onClick={toggleEdit}
        />
      </div>
      <AdherentDetailsPutForm
        adherent={props.adherent}
        isEditable={isEditable}
        partner={props.partner}
        setPartner={props.setPartner}
      />
      <Modal
        isShowing={isShowing}
        hide={toggle}
        title={
          props.adherent.firstname +
          ' ' +
          props.adherent.lastname +
          " ne fait partie d'aucune représentation actuellement."
        }
      >
        <div className="flex flex-col gap-y-5">
          <p>
            Veuillez lui attribuer une nouvelle adhésion afin de l'inclure dans
            une représentation.
          </p>
          <button className="bg-yellow-500 text-white" onClick={toggle}>
            OK
          </button>
        </div>
      </Modal>
    </div>
  );
}
