import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { SubsidiaryNote } from '../types/SubsidiaryNote';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import {
  useSetSelectedSubsidiaryNote,
  useSubsidiaryNotesDispatch,
} from '../contexts/SubsidiaryNoteCollectionContext';

interface Props {
  subsidiaryNote: SubsidiaryNote;
}

interface Values {
  message: string;
  updatedAt: Date;
}

export default function SubsidiaryNotePutForm(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useSubsidiaryNotesDispatch();
  const setSelectedSubsidiaryNote = useSetSelectedSubsidiaryNote();

  const ValidationSchema = Yup.object().shape({
    message: Yup.string()
      .min(2, 'La note est trop courte')
      .max(255, 'La note est trop longue'),
  });

  return (
    <Formik
      initialValues={{
        message: props.subsidiaryNote?.message,
        updatedAt: new Date(),
      }}
      validationSchema={ValidationSchema}
      enableReinitialize={true}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        toast
          .promise(
            axiosPrivate.put(props.subsidiaryNote['@id'], values),
            {
              pending: 'Modification de la note en cours...',
              success: 'Votre note a bien enregistré',
              error: 'Une erreur est survenue.',
            },
            { position: toast.POSITION.BOTTOM_CENTER },
          )
          .then(
            (response) => {
              dispatch({ type: 'changed', subsidiaryNote: response.data });
              setSelectedSubsidiaryNote(response.data);
            },
            (error) => {
              console.log(error);
            },
          )
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched }) => (
        <Form className="w-full">
          <Field
            as="textarea"
            id="message"
            name="message"
            type="text"
            placeholder="Veuillez saisir votre message..."
            wrap="hard"
            className="p-2 border w-full"
          />
          <p className="text-sm text-red-700 mt-1">
            {errors.message && touched.message ? errors.message : null}
          </p>
          <div className="flex justify-between items-center gap-x-5 mt-5">
            <button type="submit" className="bg-white border px-2 py-1">
              Mettre à jour
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
