import { create } from 'zustand';

type State = {
  name: string;
  email: string;
};

type Action = {
  setName: (subsidiaryName: State['name']) => void;
  setEmail: (email: State['email']) => void;
  reset: () => void;
};

const initialState = {
  name: '',
  email: '',
};

export const useSubsidiaryFilterStore = create<State & Action>((set) => ({
  ...initialState,
  reset: () => set(initialState),
  setName: (value: string) => set({ name: value }),
  setEmail: (value: string) => set({ email: value }),
}));
