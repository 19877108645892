import Modal from '../../../components/modals/Modal';
import useModal from '../../../components/modals/useModal';
import SubsidiaryPostForm from '../forms/SubsidiaryPostForm';

export default function SubsidiaryPostButton() {
  const { isShowing, toggle } = useModal();

  return (
    <>
      <button onClick={toggle} className="bg-white border p-2 text-sm">
        Nouvelle représentation
      </button>
      <Modal
        isShowing={isShowing}
        hide={toggle}
        title={'Ajouter une représentation'}
      >
        <SubsidiaryPostForm hide={toggle} />
      </Modal>
    </>
  );
}
