import Modal from '../../../components/modals/Modal';
import { Price } from '../types/Price';
import useModal from '../../../components/modals/useModal';
import PricePutForm from '../forms/PricePutForm';

interface Props {
  price: Price;
  closeParent: () => void;
}

export default function PricePutButton(props: Props) {
  const { isShowing, toggle } = useModal();

  return (
    <>
      <button
        onClick={toggle}
        className="bg-white border px-2 py-1 mt-5 text-sm"
      >
        Modifier
      </button>
      <Modal title={'Modifier le barème'} isShowing={isShowing} hide={toggle}>
        <PricePutForm price={props.price} closeParent={props.closeParent} />
      </Modal>
    </>
  );
}
