import React, { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { useAdherentFilterStore } from '../useAdherentFilterStore';
import Button from '../../../components/Button';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { useAuth } from '../../Auth/contexts/AuthContextProvider';
import AdherentNumberFilter from '../../../components/filters/AdherentNumberFilter';
import LastnameFilter from '../../../components/filters/LastnameFilter';
import FirstnameFilter from '../../../components/filters/FirstnameFilter';
import EmailFilter from '../../../components/filters/EmailFilter';
import CardStatusFilter from '../../../components/filters/CardStatusFilter';
import CardTypeFilter from '../../../components/filters/CardTypeFilter';
import YearFilter from '../../../components/filters/YearFilter';
import MembershipStatusFilter from '../../../components/filters/MembershipStatusFilter';
import MembershipOriginFilter from '../../../components/filters/MembershipOriginFilter';
import PriceTypeFilter from '../../../components/filters/PriceTypeFilter';
import SubsidiaryNameFilter from '../../../components/filters/SubsidiaryNameFilter';

registerLocale('fr', fr);

interface Values {
  subsidiaryName: string;
  adherentNumber: string;
  lastname: string;
  firstname: string;
  email: string;
  cardStatus: string;
  cardType: string;
  yearDate: string;
  membershipStatus: string;
  priceType: string;
  membershipOrigin: string;
}

const ValidationSchema = Yup.object().shape({
  subsidiaryName: Yup.string().min(
    2,
    'Vous devez fournir au minimum 2 caractère.',
  ),
  adherentNumber: Yup.string().min(
    1,
    'Vous devez fournir au minimum 1 caractère.',
  ),
  lastname: Yup.string().min(2, 'Vous devez fournir au minimum 2 caractère.'),
  firstname: Yup.string().min(2, 'Vous devez fournir au minimum 2 caractère.'),
  email: Yup.string().min(2, 'Vous devez fournir au minimum 2 caractère.'),
});

export default function AdherentFilterForm() {
  const subsidiaryName = useAdherentFilterStore(
    (state) => state.subsidiaryName,
  );
  const setSubsidiaryName = useAdherentFilterStore(
    (state) => state.setSubsidiaryName,
  );
  const adherentNumber = useAdherentFilterStore(
    (state) => state.adherentNumber,
  );
  const setAdherentNumber = useAdherentFilterStore(
    (state) => state.setAdherentNumber,
  );
  const lastname = useAdherentFilterStore((state) => state.lastname);
  const setLastname = useAdherentFilterStore((state) => state.setLastname);
  const firstname = useAdherentFilterStore((state) => state.firstname);
  const setFirstname = useAdherentFilterStore((state) => state.setFirstname);
  const email = useAdherentFilterStore((state) => state.email);
  const setEmail = useAdherentFilterStore((state) => state.setEmail);
  const cardStatus = useAdherentFilterStore((state) => state.cardStatus);
  const setCardStatus = useAdherentFilterStore((state) => state.setCardStatus);
  const cardType = useAdherentFilterStore((state) => state.cardType);
  const setCardType = useAdherentFilterStore((state) => state.setCardType);
  const setMembershipsBeforeYear = useAdherentFilterStore(
    (state) => state.setMembershipsBeforeYear,
  );
  const membershipsAfterYear = useAdherentFilterStore(
    (state) => state.membershipsAfterYear,
  );
  const setMembershipsAfterYear = useAdherentFilterStore(
    (state) => state.setMembershipsAfterYear,
  );
  const membershipStatus = useAdherentFilterStore(
    (state) => state.membershipStatus,
  );
  const setLastMembershipBeforeYear = useAdherentFilterStore(
    (state) => state.setLastMembershipBeforeYear,
  );
  const setLastMembershipAfterYear = useAdherentFilterStore(
    (state) => state.setLastMembershipAfterYear,
  );
  const setMembershipStatus = useAdherentFilterStore(
    (state) => state.setMembershipStatus,
  );
  const priceType = useAdherentFilterStore((state) => state.priceType);
  const setPriceType = useAdherentFilterStore((state) => state.setPriceType);
  const membershipOrigin = useAdherentFilterStore(
    (state) => state.membershipOrigin,
  );
  const setMembershipOrigin = useAdherentFilterStore(
    (state) => state.setMembershipOrigin,
  );

  const [isRotating, setIsRotating] = useState(false);
  const resetFilters = useAdherentFilterStore((state) => state.reset);
  const { auth } = useAuth();
  const role = auth?.payload?.roles[0];

  const handleResetClick = () => {
    setIsRotating(true);
    resetFilters();
    setTimeout(() => {
      setIsRotating(false);
    }, 1000);
  };

  return (
    <Formik
      initialValues={{
        subsidiaryName: subsidiaryName,
        adherentNumber: adherentNumber,
        lastname: lastname,
        firstname: firstname,
        email: email,
        cardStatus: cardStatus,
        cardType: cardType,
        yearDate: membershipsAfterYear.split('-')[0],
        membershipStatus: membershipStatus,
        priceType: priceType,
        membershipOrigin: membershipOrigin,
      }}
      validationSchema={ValidationSchema}
      enableReinitialize={true}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        setSubsidiaryName(values.subsidiaryName);
        setAdherentNumber(values.adherentNumber);
        setLastname(values.lastname);
        setFirstname(values.firstname);
        setEmail(values.email);
        setCardStatus(values.cardStatus);
        setCardType(values.cardType);

        // YEAR FILTER
        const currentYearString = new Date().getFullYear().toString();
        if (values.yearDate === '') {
          setMembershipsBeforeYear('');
          setMembershipsAfterYear('');
        } else if (
          values.yearDate === currentYearString &&
          membershipStatus === 'expired'
        ) {
          const beforeYear = parseInt(values.yearDate) + 1 + '-01-01';
          const afterYear = parseInt(values.yearDate) + '-01-01';
          setMembershipsBeforeYear(beforeYear);
          setMembershipsAfterYear(afterYear);
          const beforeYear1 = parseInt(values.yearDate) + 1 + '-01-01';
          const afterYear2 = parseInt(values.yearDate) + '-01-01';
          setLastMembershipBeforeYear(beforeYear1);
          setLastMembershipAfterYear(afterYear2);
        } else {
          const beforeYear = parseInt(values.yearDate) + 1 + '-01-01';
          const afterYear = parseInt(values.yearDate) + '-01-01';
          setMembershipsBeforeYear(beforeYear);
          setMembershipsAfterYear(afterYear);
        }

        // MEMBERSHIP STATUS FILTER
        const currentYearNumber = new Date().getFullYear();
        const targetMembershipsYear = parseInt(
          membershipsAfterYear.split('-')[0],
        );
        if (values.membershipStatus === 'active') {
          // TODO: Prise en compte de l'année N+1 (beforeYear) pour les adhésions à jours.
          const beforeYear = currentYearNumber + 2 + '-01-01';
          const afterYear = currentYearNumber + '-01-01';
          setLastMembershipBeforeYear(beforeYear);
          setLastMembershipAfterYear(afterYear);
        } else if (
          values.membershipStatus === 'expired' &&
          targetMembershipsYear !== currentYearNumber
        ) {
          const beforeYear = currentYearNumber + '-01-01';
          setLastMembershipBeforeYear(beforeYear);
          setLastMembershipAfterYear('');
        } else if (
          values.membershipStatus === 'expired' &&
          targetMembershipsYear === currentYearNumber
        ) {
          const beforeYear = currentYearNumber + 1 + '-01-01';
          const afterYear = currentYearNumber + '-01-01';
          setLastMembershipBeforeYear(beforeYear);
          setLastMembershipAfterYear(afterYear);
        } else {
          setLastMembershipBeforeYear('');
          setLastMembershipAfterYear('');
        }
        setMembershipStatus(values.membershipStatus);

        // PRICE TYPE FILTER
        setPriceType(values.priceType);
        setMembershipOrigin(values.membershipOrigin);

        setSubmitting(false);
      }}
    >
      {({ errors, touched }) => (
        <Form className="flex flex-col space-y-2">
          <div className="flex justify-between">
            <h3 className="font-bold">Filtres</h3>
            <ArrowPathIcon
              className={`h-6 w-6 fill-green-ufe cursor-pointer ${
                isRotating ? 'animate-spin' : ''
              }`}
              onClick={handleResetClick}
            />
          </div>
          <Button text={'Lancer la recherche'} />
          {role !== 'ROLE_SUBSIDIARY' && (
            <SubsidiaryNameFilter
              errors={errors.subsidiaryName}
              touched={touched.subsidiaryName}
            />
          )}
          <AdherentNumberFilter
            errors={errors.adherentNumber}
            touched={touched.adherentNumber}
          />
          <LastnameFilter errors={errors.lastname} touched={touched.lastname} />
          <FirstnameFilter
            errors={errors.firstname}
            touched={touched.firstname}
          />
          <EmailFilter errors={errors.email} touched={touched.email} />
          <CardStatusFilter
            cardStatus={cardStatus}
            setCardStatus={setCardStatus}
          />
          <CardTypeFilter cardType={cardType} setCardType={setCardType} />
          <YearFilter
            membershipStatus={membershipStatus}
            membershipsAfterYear={membershipsAfterYear}
            setMembershipsBeforeYear={setMembershipsBeforeYear}
            setMembershipsAfterYear={setMembershipsAfterYear}
            setLastMembershipBeforeYear={setLastMembershipBeforeYear}
            setLastMembershipAfterYear={setLastMembershipAfterYear}
          />
          <MembershipStatusFilter
            membershipStatus={membershipStatus}
            setMembershipStatus={setMembershipStatus}
            membershipsAfterYear={membershipsAfterYear}
            setLastMembershipBeforeYear={setLastMembershipBeforeYear}
            setLastMembershipAfterYear={setLastMembershipAfterYear}
          />
          <PriceTypeFilter priceType={priceType} setPriceType={setPriceType} />
          {role !== 'ROLE_SUBSIDIARY' && (
            <MembershipOriginFilter
              membershipOrigin={membershipOrigin}
              setMembershipOrigin={setMembershipOrigin}
            />
          )}
        </Form>
      )}
    </Formik>
  );
}
