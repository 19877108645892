import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Price } from '../types/Price';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { useSubsidiary } from '../../Subsidiary/contexts/SubsidiaryContext';
import { usePriceStore } from '../usePriceStore';
import { toast } from 'react-toastify';

interface Values {
  year: number;
  type: string;
  siegeAmount: number;
  localAmount: number;
  subsidiary: string;
}

interface Props {
  price: Price;
  closeParent: () => void;
}

export default function PricePutForm(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const subsidiary = useSubsidiary();
  const updatePrice = usePriceStore((state) => state.updatePrice);

  const PriceSchema = Yup.object().shape({
    siegeAmount: Yup.number().required('Veuillez remplir le champ'),
    localAmount: Yup.number().required('Veuillez remplir le champ'),
  });

  return (
    <Formik
      initialValues={{
        year: props.price.year,
        type: props.price.type,
        siegeAmount: props.price.siegeAmount,
        localAmount: props.price.localAmount,
        subsidiary: subsidiary['@id'],
      }}
      validationSchema={PriceSchema}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        values.year = parseInt(values.year.toString());
        toast
          .promise(
            axiosPrivate.put(props.price['@id'], values),
            {
              pending: 'Mise à jour...',
              success: 'Barème mis à jour.',
              error: 'Une erreur est survenue.',
            },
            { position: toast.POSITION.BOTTOM_CENTER },
          )
          .then(
            (response) => {
              updatePrice(response.data);
              props.closeParent();
            },
            (error) => {
              console.log(error);
            },
          )
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched }) => (
        <Form className="flex flex-col space-y-3 text-sm">
          <div className="flex flex-col">
            <label>Année</label>
            <Field
              as="select"
              id="year"
              name="year"
              placeholder="Année"
              className="border px-2 flex items-center h-8 w-full"
            >
              <option value="">---</option>
              <option value="2017">2017</option>
              <option value="2018">2018</option>
              <option value="2019">2019</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </Field>
          </div>
          <div className="flex flex-col">
            <label>Type de barème</label>
            <Field
              as="select"
              id="type"
              name="type"
              placeholder="Type de barème"
              className="border w-full"
            >
              <option value="NORMAL CONJOINT">NORMAL CONJOINT</option>
              <option value="NORMAL PARTICULIER">NORMAL PARTICULIER</option>
              <option value="JUNIOR CONJOINT">JUNIOR CONJOINT</option>
              <option value="JUNIOR PARTICULIER">JUNIOR PARTICULIER</option>
              <option value="SENIOR PARTICULIER">SENIOR PARTICULIER</option>
              <option value="SENIOR CONJOINT">SENIOR CONJOINT</option>
              <option value="AUTRE PARTICULIER">AUTRE PARTICULIER</option>
              <option value="AUTRE CONJOINT">AUTRE CONJOINT</option>
              <option value="AXA PARTICULIER">AXA PARTICULIER</option>
              <option value="AXA CONJOINT">AXA CONJOINT</option>
            </Field>
          </div>
          <div className="flex flex-col">
            <label>Montant part siège</label>
            <Field
              id="siegeAmount"
              name="siegeAmount"
              type="number"
              className="border pl-1"
            />
          </div>
          {errors.siegeAmount && touched.siegeAmount && (
            <p className="modal-error">{errors.siegeAmount}</p>
          )}
          <div className="flex flex-col">
            <label>Montant part locale</label>
            <Field
              id="localAmount"
              name="localAmount"
              type="number"
              className="border pl-1"
            />
          </div>
          {errors.localAmount && touched.localAmount ? (
            <p className="modal-error">{errors.localAmount}</p>
          ) : null}

          <button className="px-2 py-1 text-white bg-green-ufe" type="submit">
            Mettre à jour
          </button>
        </Form>
      )}
    </Formik>
  );
}
