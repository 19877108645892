import useModal from '../../../components/modals/useModal';
import Modal from '../../../components/modals/Modal';
import { Adherent } from '../../Adherent/types/Adherent';
import DonationPostForm from '../forms/DonationPostForm';

interface Props {
  adherent: Adherent;
}

export default function DonationPostButton(props: Props) {
  const { isShowing, toggle } = useModal();

  return (
    <>
      <button onClick={toggle} className="bg-white border px-2 py-1">
        Ajouter une donation
      </button>
      <Modal isShowing={isShowing} hide={toggle} title={'Ajouter une donation'}>
        <DonationPostForm hide={toggle} adherent={props.adherent} />
      </Modal>
    </>
  );
}
