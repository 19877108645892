import DonationItem from '../items/DonationItem';
import { Adherent } from '../../Adherent/types/Adherent';
import { Donation } from '../types/Donation';
import { useDonations } from '../contexts/DonationProvider';
import multipleOptions from '../../../assets/images/multipleOptions.svg';

type Props = {
  adherent: Adherent;
};

export default function DonationTable(props: Props) {
  const donations = useDonations();

  return (
    <table className="border">
      <thead>
        <tr className="bg-green-ufeLight">
          <td className="p-2 text-left">Montant</td>
          <td className="p-2 text-left">Date</td>
          <td className="p-2 text-left">
            <img alt="option-icon" src={multipleOptions} />
          </td>
        </tr>
      </thead>
      <tbody>
        {donations &&
          donations.map((element: Donation, id) => {
            return (
              <DonationItem
                adherent={props.adherent}
                donation={element}
                key={id}
              />
            );
          })}
      </tbody>
    </table>
  );
}
