import SubsidiaryNotePostButton from '../buttons/SubsidiaryNotePostButton';
import {
  useSelectedSubsidiaryNote,
  useSubsidiaryNotes,
} from '../contexts/SubsidiaryNoteCollectionContext';
import { SubsidiaryNote } from '../types/SubsidiaryNote';
import SubsidiaryNoteItem from '../items/SubsidiaryNoteItem';
import SelectedSubsidiaryNoteItem from '../items/SelectedSubsidiaryNoteItem';

export default function SubsidiaryNoteCollectionContainer() {
  const subsidiaryNotes = useSubsidiaryNotes();
  const selectedSubsidiaryNote = useSelectedSubsidiaryNote();

  return (
    <div className="flex gap-x-5">
      <div className="flex flex-col p-5 bg-white w-1/2">
        <div className="flex justify-between mb-5">
          <div className="flex gap-x-1">
            <h3 className="font-bold">Notes</h3>
          </div>
          <div className="flex gap-x-4">
            <SubsidiaryNotePostButton />
          </div>
        </div>
        <table className="border">
          <thead>
            <tr className="bg-green-ufeLight">
              <td className="p-2 text-left">Auteur</td>
              <td className="p-2 text-left">Message</td>
              <td className="p-2 text-left">Créée le</td>
              <td className="p-2 text-left">Mise à jour le</td>
            </tr>
          </thead>
          <tbody>
            {subsidiaryNotes &&
              subsidiaryNotes.map(
                (subsidiaryNote: SubsidiaryNote, index: number) => {
                  return (
                    <SubsidiaryNoteItem
                      key={index}
                      subsidiaryNote={subsidiaryNote}
                    />
                  );
                },
              )}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col p-5 bg-white w-1/2">
        {selectedSubsidiaryNote && <SelectedSubsidiaryNoteItem />}
      </div>
    </div>
  );
}
