import options from '../../assets/images/multipleOptions.svg';
import Loader from '../../components/Loader';
import {
  useGetSubsidiaryCollection,
  useSubsidiaries,
  useSubsidiariesIsLoading,
  useSubsidiariesLinks,
  useSubsidiariesOrderName,
  useSubsidiariesSelectedItems,
  useSubsidiariesSetItemsPerPage,
  useSubsidiariesSetOrderName,
  useSubsidiariesSetSelectedItems,
  useSubsidiariesTotalAdherents,
  useSubsidiariesTotalItems,
} from '../../modules/Subsidiary/contexts/SubsidiaryCollectionContext';
import SubsidiaryItem from '../../modules/Subsidiary/items/SubsidiaryItem';
import { SubsidiaryTotalAdherents } from '../../modules/Subsidiary/types/SubsidiaryTotalAdherents';
import SubsidiaryPostButton from '../../modules/Subsidiary/buttons/SubsidiaryPostButton';
import SubsidiaryExportButton from '../../modules/Subsidiary/buttons/SubsidiaryExportButton';
import Pagination from '../../components/Pagination';
import RowSelector from '../../components/RowSelector';
import SubsidiaryFilterForm from '../../modules/Subsidiary/forms/SubsidiaryFilterForm';
import OrderFilter from '../../components/filters/OrderFilter';

export default function SubsidiaryCollectionPage() {
  const subsidiaries = useSubsidiaries();
  const links = useSubsidiariesLinks();
  const isLoading = useSubsidiariesIsLoading();
  const setItemsPerPage = useSubsidiariesSetItemsPerPage();
  const getSubsidiaryCollection = useGetSubsidiaryCollection();
  const subsidiariesTotalAdherents = useSubsidiariesTotalAdherents();
  const selectedItems = useSubsidiariesSelectedItems();
  const setSelectedItems = useSubsidiariesSetSelectedItems();
  const totalItems = useSubsidiariesTotalItems();
  const orderName = useSubsidiariesOrderName();
  const setOrderName = useSubsidiariesSetOrderName();

  const selectAll = () => {
    if (selectedItems.length === subsidiaries.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(subsidiaries);
    }
  };

  return (
    <div className="flex gap-x-5 p-5">
      <div className="bg-white p-5 flex flex-col gap-y-5 h-min w-1/6">
        <SubsidiaryFilterForm />
      </div>
      <div className="bg-white p-5 flex flex-col h-min w-5/6">
        <div className="flex justify-between mb-5">
          <div className="flex gap-x-1">
            <h3 className="font-bold">Représentations</h3>
            {isLoading ? <Loader /> : null}
          </div>
          <div className="flex gap-x-4">
            <SubsidiaryPostButton />
            <SubsidiaryExportButton />
          </div>
        </div>
        <table className="border">
          <thead>
            <tr className="bg-green-ufeLight">
              <td className="py-2">
                <button
                  onClick={selectAll}
                  className={
                    selectedItems.length === subsidiaries.length &&
                    subsidiaries.length !== 0
                      ? 'ml-2 w-4 h-4 bg-green-ufe border pointer'
                      : 'ml-2 w-4 h-4 bg-white border pointer'
                  }
                />
              </td>
              <td className="p-2 text-left">
                <div className="flex items-center space-x-1">
                  <p>Nom</p>
                  <OrderFilter order={orderName} setOrder={setOrderName} />
                </div>
              </td>
              <td className="p-2 text-left">Email de la représentation</td>
              <td className="p-2 text-left">Téléphone</td>
              <td className="p-2 text-left">Président</td>
              <td className="p-2 text-left">Email du président</td>
              <td className="p-2 text-left">Nombre d'adhérents</td>
              <td className="p-2 text-left">
                <img alt="options-icon" src={options} />
              </td>
            </tr>
          </thead>
          <tbody>
            {subsidiaries &&
              subsidiaries.map((subsidiary, index) => {
                let subsidiaryTotalAdherents = 0;
                subsidiariesTotalAdherents &&
                  subsidiariesTotalAdherents.map(
                    (element: SubsidiaryTotalAdherents) => {
                      if (element.name === subsidiary.name) {
                        subsidiaryTotalAdherents = element.adherentsCount;
                      }
                      return subsidiaryTotalAdherents;
                    },
                  );

                return (
                  <SubsidiaryItem
                    key={index}
                    subsidiary={subsidiary}
                    subsidiaryTotalAdherents={subsidiaryTotalAdherents}
                  />
                );
              })}
          </tbody>
        </table>
        <div className="mt-5 flex justify-between">
          <Pagination links={links} fetchData={getSubsidiaryCollection} />
          <RowSelector
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          />
        </div>
      </div>
    </div>
  );
}
