import ReactDOM from 'react-dom/client';
import { AuthContextProvider } from './modules/Auth/contexts/AuthContextProvider';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import './assets/styles/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import { UserContextProvider } from './modules/User/contexts/UserContextProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <AuthContextProvider>
    <BrowserRouter>
      <UserContextProvider>
        <App />
      </UserContextProvider>
    </BrowserRouter>
  </AuthContextProvider>,
);
