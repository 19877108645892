import useModal from '../../../components/modals/useModal';
import { MouseEvent, useState } from 'react';
import option from '../../../assets/images/options.svg';
import OnclickPosModal from '../../../components/modals/OnclickPosModal';
import { Price } from '../types/Price';
import PriceDeleteButton from './PriceDeleteButton';
import PricePutButton from './PricePutButton';

interface Props {
  price: Price;
}

export default function PriceActionButton(props: Props) {
  const { isShowing, toggle } = useModal();
  const [style, setStyle] = useState({});

  const openModal = (e: MouseEvent<HTMLButtonElement>) => {
    setStyle({ top: e.clientY, left: e.clientX - 167 });
    toggle();
  };

  return (
    <>
      <button
        className="p-2"
        onClick={(e: MouseEvent<HTMLButtonElement>) => openModal(e)}
      >
        <img alt="option-icon" src={option} className="w-1" />
      </button>
      <OnclickPosModal
        isShowing={isShowing}
        hide={toggle}
        style={style}
        title={'Actions'}
      >
        <div className="flex flex-col">
          <PricePutButton price={props.price} closeParent={toggle} />
          <PriceDeleteButton price={props.price} closeParent={toggle} />
        </div>
      </OnclickPosModal>
    </>
  );
}
