import React, { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import Button from '../../../components/Button';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import LastnameFilter from '../../../components/filters/LastnameFilter';
import FirstnameFilter from '../../../components/filters/FirstnameFilter';
import EmailFilter from '../../../components/filters/EmailFilter';
import { useUserFilterStore } from '../useUserFilterStore';

registerLocale('fr', fr);

interface Values {
  lastname: string;
  firstname: string;
  email: string;
}

const ValidationSchema = Yup.object().shape({
  lastname: Yup.string().min(2, 'Vous devez fournir au minimum 2 caractère.'),
  firstname: Yup.string().min(2, 'Vous devez fournir au minimum 2 caractère.'),
  email: Yup.string().min(2, 'Vous devez fournir au minimum 2 caractère.'),
});

export default function UserFilterForm() {
  const lastname = useUserFilterStore((state) => state.lastname);
  const setLastname = useUserFilterStore((state) => state.setLastname);
  const firstname = useUserFilterStore((state) => state.firstname);
  const setFirstname = useUserFilterStore((state) => state.setFirstname);
  const email = useUserFilterStore((state) => state.email);
  const setEmail = useUserFilterStore((state) => state.setEmail);

  const [isRotating, setIsRotating] = useState(false);
  const resetFilters = useUserFilterStore((state) => state.reset);

  const handleResetClick = () => {
    setIsRotating(true);
    resetFilters();
    setTimeout(() => {
      setIsRotating(false);
    }, 1000);
  };

  return (
    <Formik
      initialValues={{
        lastname: lastname,
        firstname: firstname,
        email: email,
      }}
      validationSchema={ValidationSchema}
      enableReinitialize={true}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        setLastname(values.lastname);
        setFirstname(values.firstname);
        setEmail(values.email);
        setSubmitting(false);
      }}
    >
      {({ errors, touched }) => (
        <Form className="flex flex-col space-y-2">
          <div className="flex justify-between">
            <h3 className="font-bold">Filtres</h3>
            <ArrowPathIcon
              className={`h-6 w-6 fill-green-ufe cursor-pointer ${
                isRotating ? 'animate-spin' : ''
              }`}
              onClick={handleResetClick}
            />
          </div>
          <Button text={'Lancer la recherche'} />
          <LastnameFilter errors={errors.lastname} touched={touched.lastname} />
          <FirstnameFilter
            errors={errors.firstname}
            touched={touched.firstname}
          />
          <EmailFilter errors={errors.email} touched={touched.email} />
        </Form>
      )}
    </Formik>
  );
}
