import React from 'react';
import { Adherent } from '../../Adherent/types/Adherent';
import DonationTable from '../tables/DonationTable';
import DonationPostButton from '../buttons/DonationPostButton';

interface Props {
  adherent: Adherent;
}

export default function DonationContainer(props: Props) {
  return (
    <div className="h-min p-5 bg-white flex flex-col w-full">
      <div className="flex justify-between mb-5">
        <h3 className="font-bold">Donations</h3>
        <DonationPostButton adherent={props.adherent} />
      </div>
      <DonationTable adherent={props.adherent} />
    </div>
  );
}
