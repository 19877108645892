import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Links } from '../../../types/Links';
import { Membership } from '../types/Membership';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { Adherent } from '../../Adherent/types/Adherent';

type Action =
  | { type: 'loaded'; memberships: Membership[] }
  | { type: 'added'; membership: Membership }
  | { type: 'changed'; membership: Membership }
  | { type: 'deleted'; membership: Membership };

type Dispatch = (action: Action) => void;

type MembershipsStateContentProps = {
  memberships: Membership[];
  links?: Links;
  itemsPerPage: number;
  isLoading: boolean;
};

type MembershipsDisptachContentProps = {
  dispatch: Dispatch;
  setItemsPerPage: (itemsPerPage: number) => void;
  fetchMemberships: (url?: string) => void;
};

const MembershipsStateContext = createContext<
  MembershipsStateContentProps | undefined
>(undefined);

const MembershipsDisptachContext = createContext<
  MembershipsDisptachContentProps | undefined
>(undefined);

const membershipsReducer = (memberships: Membership[], action: Action) => {
  switch (action.type) {
    case 'loaded':
      return action.memberships;
    case 'added':
      const updatedMemberships = [...memberships, action.membership];
      return updatedMemberships.sort(
        (a, b) => new Date(b.endAt).getTime() - new Date(a.endAt).getTime(),
      );
    case 'changed':
      return memberships.map((user) => {
        if (user['@id'] === action.membership['@id']) {
          return action.membership;
        }
        return user;
      });
    case 'deleted':
      return memberships.filter(
        (membership) => membership['@id'] !== action.membership['@id'],
      );
    default:
      throw new Error('Unhandled action type');
  }
};

interface Props {
  children: ReactNode;
  adherent: Adherent;
}

export default function MembershipCollectionContext(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const [memberships, dispatch] = useReducer(membershipsReducer, []);
  const [links, setLinks] = useState<Links>();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMemberships = useCallback(
    (url?: string) => {
      if (!url) {
        url = `/memberships?itemsPerPage=${itemsPerPage}&page=1&adherent=${props.adherent['@id']}}`;
      }
      setIsLoading(true);
      axiosPrivate
        .get(url)
        .then(
          (response) => {
            setLinks(response?.data['hydra:view']);
            dispatch({
              type: 'loaded',
              memberships: response?.data['hydra:member'],
            });
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => {
          setIsLoading(false);
        });
    },
    [axiosPrivate, itemsPerPage],
  );

  useEffect(() => {
    fetchMemberships();
  }, [fetchMemberships]);

  return (
    <MembershipsStateContext.Provider
      value={{ memberships, links, itemsPerPage, isLoading }}
    >
      <MembershipsDisptachContext.Provider
        value={{
          dispatch,
          setItemsPerPage,
          fetchMemberships,
        }}
      >
        {props.children}
      </MembershipsDisptachContext.Provider>
    </MembershipsStateContext.Provider>
  );
}

export function useMemberships() {
  const context = React.useContext(MembershipsStateContext);
  if (context === undefined) {
    throw new Error('useMemberships must be used within a MembershipsProvider');
  }
  return context.memberships;
}

export function useMembershipsLinks() {
  const context = React.useContext(MembershipsStateContext);
  if (context === undefined) {
    throw new Error(
      'useMembershipsLinks must be used within a MembershipsProvider',
    );
  }
  return context.links;
}

export function useMembershipsItemsPerPage() {
  const context = React.useContext(MembershipsStateContext);
  if (context === undefined) {
    throw new Error(
      'useMembershipsItemsPerPage must be used within a MembershipsProvider',
    );
  }
  return context.itemsPerPage;
}

export function useMembershipsIsLoading() {
  const context = React.useContext(MembershipsStateContext);
  if (context === undefined) {
    throw new Error(
      'useMembershipsIsLoading must be used within a MembershipsProvider',
    );
  }
  return context.isLoading;
}

export function useMembershipsDispatch() {
  const context = React.useContext(MembershipsDisptachContext);
  if (context === undefined) {
    throw new Error(
      'useMembershipsDispatch must be used within a MembershipsProvider',
    );
  }
  return context.dispatch;
}

export function useMembershipsSetItemsPerPage() {
  const context = React.useContext(MembershipsDisptachContext);
  if (context === undefined) {
    throw new Error(
      'useMembershipsSetItemsPerPage must be used within a MembershipsProvider',
    );
  }
  return context.setItemsPerPage;
}

export function useMembershipsFetch() {
  const context = React.useContext(MembershipsDisptachContext);
  if (context === undefined) {
    throw new Error(
      'useMembershipsFetchMemberships must be used within a MembershipsProvider',
    );
  }
  return context.fetchMemberships;
}
