import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Subsidiary } from '../types/Subsidiary';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { Links } from '../../../types/Links';
import { SubsidiaryTotalAdherents } from '../types/SubsidiaryTotalAdherents';
import { useSubsidiaryFilterStore } from '../useSubsidiaryFilterStore';

type Action =
  | { type: 'loaded'; subsidiaries: Subsidiary[] }
  | { type: 'added'; subsidiary: Subsidiary }
  | { type: 'changed'; subsidiary: Subsidiary }
  | { type: 'deleted'; subsidiary: Subsidiary };

type Dispatch = (action: Action) => void;

type SubsidiariesStateContentProps = {
  subsidiaries: Subsidiary[];
  links?: Links;
  isLoading: boolean;
  subsidiariesTotalAdherents: SubsidiaryTotalAdherents[];
  totalItems: number;
  itemsPerPage: number;
  selectedItems: Subsidiary[];
  orderName: string | undefined;
};

type SubsidiariesDisptachContentProps = {
  dispatch: Dispatch;
  setTotalItems: (totalItems: number) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
  setSelectedItems: (selectedItems: Subsidiary[]) => void;
  getSubsidiaryCollection: (links?: string) => void;
  setOrderName: (orderName: string | undefined) => void;
};

const subsidiariesReducer = (subsidiaries: Subsidiary[], action: Action) => {
  switch (action.type) {
    case 'loaded':
      return action.subsidiaries;
    case 'added':
      return [...subsidiaries, action.subsidiary];
    case 'changed':
      return subsidiaries.map((subsidiary) => {
        if (subsidiary['@id'] === action.subsidiary['@id']) {
          return action.subsidiary;
        }
        return subsidiary;
      });
    case 'deleted':
      return subsidiaries.filter(
        (subsidiary) => subsidiary['@id'] !== action.subsidiary['@id'],
      );
    default:
      throw new Error('Unhandled action type');
  }
};

const SubsidiariesStateContext = createContext<
  SubsidiariesStateContentProps | undefined
>(undefined);

const SubsidiariesDisptachContext = createContext<
  SubsidiariesDisptachContentProps | undefined
>(undefined);

interface Props {
  children: ReactNode;
}

export default function SubsidiaryCollectionContext(props: Props) {
  const axiosPrivate = useAxiosPrivate();
  const [subsidiaries, dispatch] = useReducer(subsidiariesReducer, []);
  const [links, setLinks] = useState<Links>();
  const [totalItems, setTotalItems] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [isLoading, setIsLoading] = useState(false);
  const [subsidiariesTotalAdherents, setSubsidiariesTotalAdherents] = useState<
    SubsidiaryTotalAdherents[]
  >([]);
  const [selectedItems, setSelectedItems] = useState<Subsidiary[]>([]);
  const [orderName, setOrderName] = useState<string | undefined>('asc');

  const name = useSubsidiaryFilterStore((state) => state.name);
  const email = useSubsidiaryFilterStore((state) => state.email);

  const getSubsidiaryCollection = useCallback(
    (url?: string) => {
      if (!url) {
        url = `/subsidiaries?itemsPerPage=${itemsPerPage}&page=1&order[name]=${orderName}`;
        if (name !== '') {
          url += `&name=${name}`;
        }
        if (email !== '') {
          url += `&email=${email}`;
        }
      }
      setIsLoading(true);
      axiosPrivate
        .get(url)
        .then((response) => {
          setTotalItems(response.data['hydra:totalItems']);
          setLinks(response.data['hydra:view']);
          dispatch({
            type: 'loaded',
            subsidiaries: response.data['hydra:member'],
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [axiosPrivate, totalItems, itemsPerPage, email, name, orderName],
  );

  useEffect(() => {
    getSubsidiaryCollection();
    axiosPrivate.get('/charts/totalAdherentsBySubsidiary').then(
      (response) => {
        setSubsidiariesTotalAdherents(response?.data);
      },
      (error) => {
        console.log(error);
      },
    );
  }, [axiosPrivate, getSubsidiaryCollection]);

  return (
    <SubsidiariesStateContext.Provider
      value={{
        subsidiaries,
        links,
        isLoading,
        subsidiariesTotalAdherents,
        totalItems,
        itemsPerPage,
        selectedItems,
        orderName,
      }}
    >
      <SubsidiariesDisptachContext.Provider
        value={{
          dispatch,
          setTotalItems,
          setItemsPerPage,
          setSelectedItems,
          getSubsidiaryCollection,
          setOrderName,
        }}
      >
        {props.children}
      </SubsidiariesDisptachContext.Provider>
    </SubsidiariesStateContext.Provider>
  );
}

export function useSubsidiaries() {
  const context = useContext(SubsidiariesStateContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiaries must be used within a SubsidiariesProvider',
    );
  }
  return context.subsidiaries;
}

export function useSubsidiariesLinks() {
  const context = useContext(SubsidiariesStateContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiariesLinks must be used within a SubsidiariesProvider',
    );
  }
  return context.links;
}

export function useSubsidiariesIsLoading() {
  const context = useContext(SubsidiariesStateContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiariesIsLoading must be used within a SubsidiariesProvider',
    );
  }
  return context.isLoading;
}

export function useSubsidiariesTotalAdherents() {
  const context = useContext(SubsidiariesStateContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiariesTotalAdherents must be used within a SubsidiariesProvider',
    );
  }
  return context.subsidiariesTotalAdherents;
}

export function useSubsidiariesTotalItems() {
  const context = useContext(SubsidiariesStateContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiariesTotalItems must be used within a SubsidiariesProvider',
    );
  }
  return context.totalItems;
}

export function useSubsidiariesItemsPerPage() {
  const context = useContext(SubsidiariesStateContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiariesItemsPerPage must be used within a SubsidiariesProvider',
    );
  }
  return context.itemsPerPage;
}

export function useSubsidiariesSelectedItems() {
  const context = useContext(SubsidiariesStateContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiariesSelectedItems must be used within a SubsidiariesProvider',
    );
  }
  return context.selectedItems;
}

export function useSubsidiariesOrderName() {
  const context = useContext(SubsidiariesStateContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiariesOrderName must be used within a SubsidiariesProvider',
    );
  }
  return context.orderName;
}

export function useSubsidiariesDispatch() {
  const context = useContext(SubsidiariesDisptachContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiariesDispatch must be used within a SubsidiariesProvider',
    );
  }
  return context.dispatch;
}

export function useSubsidiariesSetItemsPerPage() {
  const context = useContext(SubsidiariesDisptachContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiariesSetItemsPerPage must be used within a SubsidiariesProvider',
    );
  }
  return context.setItemsPerPage;
}

export function useGetSubsidiaryCollection() {
  const context = useContext(SubsidiariesDisptachContext);
  if (context === undefined) {
    throw new Error(
      'useGetSubsidiaryCollection must be used within a SubsidiariesProvider',
    );
  }
  return context.getSubsidiaryCollection;
}

export function useSubsidiariesSetSelectedItems() {
  const context = useContext(SubsidiariesDisptachContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiariesSetSelectedItems must be used within a SubsidiariesProvider',
    );
  }
  return context.setSelectedItems;
}

export function useSubsidiariesSetOrderName() {
  const context = useContext(SubsidiariesDisptachContext);
  if (context === undefined) {
    throw new Error(
      'useSubsidiariesSetOrderName must be used within a SubsidiariesProvider',
    );
  }
  return context.setOrderName;
}
