import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../Axios/useAxiosPrivate';
import { Subsidiary } from '../../Subsidiary/types/Subsidiary';

interface Props {
  chosenSubsidiary: Subsidiary | null;
}

interface Data {
  seniorParticulierMemberships: number;
  seniorConjointMemberships: number;
}

const SeniorMembershipChart = (props: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState<Data>({
    seniorParticulierMemberships: 0,
    seniorConjointMemberships: 0,
  });

  useEffect(() => {
    axiosPrivate
      .get(
        '/financiary/seniorMemberships' +
          (props.chosenSubsidiary && props.chosenSubsidiary['@id'] !== ''
            ? '?subsidiary=' + props.chosenSubsidiary['@id']
            : ''),
      )
      .then(
        (response) => {
          setData(response?.data);
        },
        (error) => {
          console.log(error);
        },
      );
  }, [axiosPrivate, props.chosenSubsidiary]);

  return (
    <div className="p-6 bg-white w-full">
      <h3>Nombre de cotisation senior {new Date().getFullYear()}</h3>
      <div className="mt-5">
        {data?.seniorParticulierMemberships + data?.seniorConjointMemberships}{' '}
        dont {data?.seniorConjointMemberships} conjoints
      </div>
    </div>
  );
};

export default SeniorMembershipChart;
