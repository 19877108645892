import { axiosPrivate } from './axios';
import { useEffect } from 'react';
import { useAuth } from '../Auth/contexts/AuthContextProvider';

const useAxiosPrivate = () => {
  const { auth, setAuth } = useAuth();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (request) => {
        request.headers = {
          Authorization: 'Bearer ' + auth?.token,
          'Content-Type': 'application/ld+json',
        };
        return request;
      },
      (error) => Promise.reject(error),
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (
          error?.response?.status === 401 &&
          error.response.data.message === 'Expired JWT Token'
        ) {
          setAuth(null);
        } else {
          await Promise.reject(error);
        }
      },
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  });

  return axiosPrivate;
};

export default useAxiosPrivate;
